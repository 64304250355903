import { COOKIE_CONSENT_NAME } from '../utils/cookieConsent';
import { countryCheck, languageSwitcher } from './common';

const account = {
	title: 'Nastavenie používateľského účtu',
	subtitle: 'Ak sa niektorý z\u00a0uvedených údajov zmenil, môžete ho aktualizovať priamo na\u00a0tejto '
		+ 'stránke. V\u00a0prípade zmeny mena od Vás budeme potrebovať nový aktuálny doklad s\u00a0novým menom. '
		+ 'Pokiaľ si prajete aktualizovať e-mail (napr. kvôli zmene mena), napíšte nám prosím na '
		+ '<0>info@fondee.sk</0>.',
	tradingDay: 'Najbližší obchodný deň',
	faqLink: 'Ako a\u00a0kedy prebieha investovanie',
	bankAccountsControl: {
		title: 'Bankové účty',
		withdrawal: 'Účet na výbery',
		withdrawalTooltip: 'Na vybraný účet budeme odosielať prostriedky zadané k\u00a0výberu.',
		unconfirmedTooltip: 'Účet čaká na overenie. Aby sme mohli overiť, že bankový účet patrí naozaj vám, '
			+ 'musíte z\u00a0neho najprv poslať vklad, kľudne len 1\u00a0EUR. Otvorte si okno \u201eChcem vložiť peniaze\u201c a\u00a0zobrazia '
			+ 'sa vám platobné pokyny.',
		confirmChange: {
			title: 'Určite chcete zmeniť účet na výbery?',
			text: 'Pokiaľ už je spracovaný skôr zadaný výber, zmena sa prejaví až pri ďalšom výbere.',
			submit: 'Áno, zmeniť',
			cancel: 'Nie, ponechať',
		},
		addAccount: {
			button: 'Pridať ďalší účet',
			buttonTooltip: [
				'Môžete pridať ďalší bankový účet, z\u00a0ktorého budete posielať vklady a\u00a0na ktorý môžete '
					+ 'prijímať prostriedky. Účet musí byť vedený na Vaše meno. Pokiaľ si prajete pridať zahraničný '
					+ 'účet, je nutné ho vložiť cez IBAN.',
				'$t(account.bankAccountsControl.addAccount.text.1)',
			],
			title: 'Pridať ďalší účet',
			text: [
				'Vyplňte predčíslie účtu (ak ho účet má), číslo účtu a\u00a0kód banky. Účet musí byť vedený na Vaše '
					+ 'meno. Pokiaľ si prajete pridať zahraničný účet, je nutné ho vložiť cez IBAN.',
				'Účet nie je automaticky aktívny (napr. pre zadanie výberu), je potrebné ho overiť cez overovaciu '
					+ 'platbu. Môžete poslať celý vklad alebo iba pár euro. Pro zadaní platby si otvoríte okno '
					+ '\u201eChcem vložiť peniaze\u201c a\u00a0zobrazia sa Vám platobné pokyny.',
			],
			submit: 'Pridať účet',
			cancel: 'Zrušiť',
		},
	},
	cashControl: {
		title: 'Pomer hotovosti v\u00a0portfóliu',
		titleSet: 'Pomer hotovosti nastavený na {{ amount }}.',
		titlePlanned: 'Pomer hotovosti je aktuálne nastavený na {{ current }}. Pri najbližšom rebalansingu '
			+ 'bude portfólio nastavené tak, aby bol pomer hotovosti {{ planned }}.',
		titleTooltip: 'Štandardne zostávajú minimálne 2\u00a0% z\u00a0hodnoty Vášho portfólia v\u00a0hotovosti '
			+ '(pri\u00a0menších portfóliach môže byť tento pomer vyšší). Tu nám môžete zadať príkaz, aby sme pri každom '
			+ 'rebalansingu ponechali väčšiu časť Vášho portfólia v\u00a0hotovosti (minimum sú\u00a02\u00a0%). Tento '
			+ 'pomer sa bude udržiavať pri každom rebalansingu, dokým nám nedáte príkaz ho zmeniť. Hotovosť sa drží '
			+ 'v\u00a0EUR a\u00a0rovnako ako Vaše portfólio, je vystavená menovému riziku. Mesačný poplatok je vypočítavaný '
			+ 'z\u00a0celkovej hodnoty portfólia, vrátanej držanej hotovosti.',
		button: 'Upraviť pomer hotovosti',
		buttonTooltip: 'Pomer hotovosti bude upravený na nasledujúci obchodný\u00a0deň.',
		closedTooltip: 'V\u00a0túto chvíľu nie je možné zmeniť pomer hotovosti, lebo práve prebieha úprava rozloženia portfólia.',
		modal: {
			title: 'Nastaviť nový pomer',
			text: 'Štandardne zostávajú minimálne 2\u00a0% z\u00a0hodnoty Vášho portfólia v\u00a0hotovosti (pri\u00a0menších '
				+ 'portfóliach môže byť tento pomer vyšší). Tu nám môžete zadať príkaz, aby sme pri každom rebalansingu ponechali '
				+ 'väčšiu časť Vášho portfólia v\u00a0hotovosti (minimum sú 2\u00a0%). Tento pomer sa bude udržiavať pri každom '
				+ 'rebalansingu, dokým nám nedáte príkaz ho zmeniť. Hotovosť je držaná v\u00a0EUR a\u00a0rovnako ako Vaše portfólio '
				+ 'je vystavená menovému riziku. Mesačný poplatok je vypočítavaný z\u00a0celkovej hodnoty portfólia, vrátanej držanej hotovosti.',
			maxTooltip: 'V tomto prípade nebudete mať žiadne ETF a\u00a0všetko zostane v\u00a0hotovosti.',
			submit: 'Nastaviť nový pomer',
			cancel: 'Nezmeniť',
		},
	},
	currencySelect: {
		title: 'Preferované zobrazenie meny pre\u00a0celý účet',
		subtitle: 'Zobrazenie v\u00a0Prehľade',
	},
	depositDefaultPortfolio: {
		title: 'Primárne portfólio pre\u00a0vklady',
		subtitle: 'Vyberte portfólio, do\u00a0ktorého máme pripísať vklad, ak vklad nemá správnu poznámku príjemcu.',
		label: 'Zvolené portfólio',
		noOptionsMessage: 'Žiadne portfólia',
	},
	deviceTokens: {
		button: 'Prihlásené mobilné zariadenia',
		modal: {
			title: 'Prihlásené mobilné zariadenia',
			text: 'Mobilné zariadenia, ktoré majú umožnené biometrické prihlásenie. Platnosť biometrického prihlásenia '
				+ 'je 3 mesiace. Odpojením zariadenia vynútite pri ďalšom prihlásení zadanie hesla.',
			disconnect: 'Odpojiť',
			table: {
				device: 'Názov zariadenia',
				active: 'Naposledy aktívny',
			},
		},
	},
	investorProfileControl: {
		title: 'Aktuálny investičný profil:',
		titlePlanned: 'Plánovaný investičný profil:',
		titleTooltip: 'Investičný profil, podľa ktorého je aktuálne rozložené Vaše portfólio. Pokiaľ si prajete rozložiť '
			+ 'portfólio podľa iného investičného profilu, kliknite na tlačidlo Zmeniť.',
		titlePlannedTooltip: 'Podľa tohto profilu bude Vaše portfólio novo rozložené pri najbližšom rebalansingu. '
			+ 'Pokiaľ si prajete zmeniť výber nového profilu, kliknite na tlačidlo Zmeniť.',
		button: 'Zmeniť',
		closedTooltip: 'V\u00a0túto chvíľu nie je možné zmeniť pomer hotovosti, lebo práve prebieha úprava rozloženia portfólia.',
		modal: {
			title: 'Zmeniť zvolený profil',
			submit: 'Zmeniť profil',
			cancel: 'Ponechať existujúci profil',
			cancelAllProfilesCheck: 'Ponechať odporúčaný profil',
		},
		changeWarning: {
			title: 'Zmena investičného profilu',
			subtitle: 'Zmena investičného profilu môže viesť k\u00a0daňovej povinnosti. Naozaj si prajete zmenu previesť?',
		},
	},
	passwordChange: {
		button: 'Chcem zmeniť heslo k\u00a0účtu',
		title: 'Zmena hesla',
		success: 'Heslo bolo úspešne zmenené!',
	},
	personalData: {
		button: 'Uložiť zmeny',
		modalInfo: {
			title: 'Kontrola osobných údajov',
			text: '<0>Evidujeme u\u00a0Vás nasledujúce osobné údaje:</0>'
				+ '<1><0>{{ firstName }} {{ lastName }}</0>'
				+ '<0>{{ streetName }} {{ streetNumber }}, {{ city }} {{ postalCode }}, {{ country }}</0>'
				+ '<0>{{ phonePrefix }} {{ phoneNumber }}</0></1>'
				+ '<0>Sú tieto údaje správne?</0>',
			confirm: 'Áno, sú správne',
			edit: 'Nie, chcem ich aktualizovať',
		},
		modalNameChange: {
			title: 'Zmena mena',
			text: 'Nakoľko ste požiadali o\u00a0zmenu mena, musíme Vás poprosiť o\u00a0kópiu aktuálneho osobného dokladu.',
			button: 'Nahrať doklad',
		},
		phoneChange: {
			title: 'Overenie nového čísla',
			codeSendError: 'Overovací kód sme odoslali. Ak potrebujete nový, počkajte prosím minutu.',
			codeSendSuccess: 'Overovací kód bol odoslaný znovu.',
			confirmText: 'Na Vami zadané telefónne číslo <0>{{ phoneNumber }}</0> sme odoslali potvrdzovací kód. '
				+ 'Pre pokračovanie opíšte kód z\u00a0SMS a\u00a0potvrďte tlačidlom nižšie.',
			cancelText: 'Ak ste zadali nesprávne telefónne číslo, môžete jeho zmenu <0>zrušit</0>. '
				+ 'Nový kód je možné odoslať po uplynutí 1\u00a0minúty.',
			confirm: 'Potvrdiť',
			sendAgain: 'Odoslať kód znovu',
		},
		dataChange: {
			title: 'Potvrdenie osobných údajov',
			text: 'Zmenili ste nasledujúce údaje:',
			subText: 'Zmeny môžete buď potvrdiť alebo sa vrátiť späť.',
			confirm: 'Potvrdiť',
			cancel: 'Naspäť',
			fields: {
				firstName: 'Meno',
				lastName: 'Priezvisko',
				phonePrefix: 'Predvoľba',
				phoneNumber: 'Priezvisko',
				streetName: 'Ulica',
				streetNumber: 'Číslo popisné',
				city: 'Mesto',
				postalCode: 'PSČ',
				addressCountryId: 'Krajina',
				contactAddressStreetName: 'Kontaktná ulica',
				contactAddressStreetNumber: 'Kontaktné číslo popisné',
				contactAddressCity: 'Kontaktné mesto',
				contactAddressPostalCode: 'Kontaktné PSČ',
				contactAddressCountryId: 'Kontaktná krajina',
			},
		},
	},
	profilePreference: {
		title: 'Nastavenie správ a\u00a0dotazníkov',
		subtitle: 'E-mailové výpisy si prajete zasielať:',
	},
	investingQuestionaire: {
		title: 'Investičný dotazník',
		lastFill: 'Dátum posledného dokončenia: ',
		possibleFill: 'Ďalší možný priechod: ',
		tooltip: 'Investičný dotazník je možné vyplniť maximálne raz za 6\u00a0mesiacov. '
			+ 'Ak sa Vaše okolnosti zmenili a\u00a0prajete si vyplniť dotazník skôr, '
			+ 'kontaktujte nás prosím na\u00a0info@fondee.sk.',
		now: 'IHNEĎ',
		fill: 'Vyplniť investičný dotazník',
		modal: {
			title: 'Vyplniť investičný dotazník',
			text: 'Výsledok investičného dotazníka má vplyv na možnosti nastavenia Vašich portfólií. '
			+ 'Na základe Vašich odpovedí a vyhodnotenia investičného dotazníka Vám môžeme ponúknuť aj rizikovejšie profily, než máte teraz, '
			+ 'prípadne sa môže Váš profil znížiť alebo zostať rovnaký.',
			confirm: 'Rozumiem, vyplniť dotazník',
		},
	},
	twoFactorAuthenticationControl: {
		title: 'Dvojstupňové overenie',
		active: 'Momentálne máte aktívne dvojstupňové overenie.',
		disable: 'Deaktivovať',
		disabled: 'Úspešne deaktivované',
		info: {
			title: 'Postup:',
			line1: 'Stiahnite si generátor OTP, napríklad Google Authenticator (<0>App Store</0>, <1>Google Play</1>)',
			line2: 'Naskenujte QR\u00a0kód',
			line3: 'Zadajte jednorázový kód z\u00a0aplikácie',
			line4: 'Kliknite na tlačidlo "Aktivovať"',
		},
		code: {
			label: 'Jednorázový kód',
			error: 'Chybný jednorázový kód',
		},
		activate: 'Aktivovať',
		activated: 'Úspešne aktivované',
	},
	withdrawals: {
		closureTitle: 'Zrušenie účtu',
		activeTitle: 'Zadané príkazy',
		withdrawals: {
			title: 'Výber hotovosti z\u00a0investičného účtu',
			disabledMessage: 'Môže ho zadať len majiteľ zadaného bankového účtu.',
			part: {
				title: 'Výber hotovosti',
				text: 'Výber peňazí znamená, že zadáme brokerovi príkaz predať časť Vášho portfólia, peniaze prevedieme '
					+ 'na\u00a0požadovanú menu a\u00a0odošleme na\u00a0Váš bankový účet označený ako hlavný. ETFka budú '
					+ 'predané podľa ich pomerného zastúpenia v\u00a0portfóliu tak, aby bolo Vaše portfólio po predaji opäť '
					+ 'optimálne rozložené. Pokiaľ zadaná čiastka presiahne aktuálnu hodnotu ETFiek, bude '
					+ 'predané celé portfólio a\u00a0hotovosť (po odpočítaní mesačných poplatkov) bude odoslaná na\u00a0Váš\u00a0účet.',
				overError: 'Zadaná suma je vyššia ako aktuálna hodnota portfólia.',
				activeText: 'Máte zadaný príkaz na výber {{ sum }}.',
				activeTextTransfer: 'Máte zadaný prevodný príkaz {{ sumTransfer }} do\u00a0portfólia {{ portfolio }}.',
				activeTextTransferMultiple: 'Zadali ste prevodné príkazy z\u00a0tohto portfólia:',
				activeTextTransferMultiplePart: '{{ sumTransfer }} do\u00a0portfólia {{ portfolio }}',
			},
			all: {
				title: 'Vybrať všetko',
				text: 'Výber všetkých dostupných prostriedkov znamená, že v\u00a0najbližší obchodný deň zadáme brokerovi príkaz '
					+ 'na\u00a0predaj všetkých vami držaných ETF (pokiaľ nejaké máte). Potom všetky voľné prostriedky odošleme na Váš bankový účet. Pri výberu tejto možnosti vyberiete zo svojho účtu dostupné '
					+ 'prostriedky, ale samotný účet vo\u00a0Fondee nebude zrušený.',
				activeText: 'Zadali ste príkaz na odpredaj všetkých ETF a\u00a0stiahnutie všetkých prostriedkov z\u00a0portfólia.',
			},
			confirm: 'Potvrdiť',
			success: 'Výber úspešne zadaný.',
			cancel: 'Zrušiť príkaz',
			cancelOnlyParent: 'Niektoré príkazy môže zrušiť iba zákonný zástupca.',
			childPortfolioWarning: 'Keď detské portfólio predčasne zrušíte, nedodržíte tak podmienky na získanie zvýhodneného '
				+ 'poplatku. Z\u00a0vyplatenej čiastky preto strhneme rozdiel medzi poplatkom, ktorý ste doteraz zaplatili, '
				+ 'a\u00a0našim bežným poplatkom.',
			confirmPopup: {
				title: 'Potvrdenie výberu',
				text: 'Potvrďte prosím, že chcete naozaj uskutočniť zadaný výber.',
				button: 'Áno, vybrať peniaze',
				twoFactorAuthentication: 'Zadajte Váš jednorazový kód',
			},
			allPopup: {
				title: 'Výber hotovosti z\u00a0investičného účtu',
				text: 'Zadaná čiastka je veľmi blízka celkovej hodnote Vášho portfólia. Prajete si vybrať všetky Vaše prostriedky?',
				button: 'Áno, vybrať všetko',
				secondaryButton: 'Nie, vybrať iba požadovanú čiastku',
			},
			error: {
				title: 'Upozornenie',
				text: 'Výber nie je možné zadať, pretože už máte zadaný príkaz na\u00a0výber všetkých prostriedkov, zrušenie účtu '
					+ 'alebo presun časti portfólia. Aby bolo možné príkaz na\u00a0výber zadať, musíte najprv zrušiť predchádzajúce príkazy',
				button: 'Zavrieť',
			},
			childWarning: '<0>Môžete vybrať maximálne 10% ročne z\u00a0celkovej hodnoty všetkých detských portfólií.</0>',
			childRatioError: 'Môžete vybrať maximálne 10% ročne z\u00a0celkovej hodnoty všetkých detských portfólií.',
			pensionWarningTitle: 'Penzijné portfólio',
			pensionWarning: '<0>Neboli splnené podmienky na výber prostriedkov z\u00a0dôchodkového účtu. Na zadanie výberu je '
				+ 'nutné dosiahnuť aspoň 60\u00a0rokov veku a\u00a0zároveň musí uplynúť minimálne 10\u00a0rokov (120\u00a0mesiacov) od '
				+ 'založenia dôchodkového portfólia.</0>'
				+ '<0>Jedinou možnosťou je zrušenie portfólia, ale upozorňujeme, že v\u00a0takom prípade dôjde k\u00a0dorovnaniu '
				+ 'poplatkov do štandardnej výšky.</0>',
		},
		cancel: {
			portfolio: {
				title: 'Zrušenie portfólia',
				disabledMessage: 'Portfólio môže zrušiť len právny zástupca',
				text: 'Ak chcete zrušiť toto portfólio, zadáme príkaz makléra, aby sme predali všetky ETFKA z\u00a0tohto portfólia. '
					+ 'Hotovosť potom (po odpočítaní mesačných poplatkov) nad rámec meny, ktorú si vyberiete, keď zadáte výber '
					+ 'a\u00a0odoslaný na\u00a0bankový účet, ktorý ste označili ako výberový účet. ETF sa predá v\u00a0nasledujúci obchodný '
					+ 'deň po zadaní objednávky. Potom Vám pošleme peniaze na Váš účet. Celý proces môže trvať až týždeň.',
				subtext: 'Aj v\u00a0prípade, že je Vaše portfólio prázdne, k\u00a0zrušeniu dôjde až najbližší obchodný deň.',
				note: '* Odporúčame, aby ste namiesto úplného výberu portfólia vybrali možnosť "Vybrať všetky prostriedky". Získate '
					+ 'tak plnú hodnotu portfólia, ale ak budete chcieť v\u00a0budúcnosti opäť investovať so\u00a0spoločnosťou Fondee, '
					+ 'nemusíte vytvárať nové portfólio.',
				confirm: 'Zrušiť portfólio',
				success: 'Príkaz na\u00a0zrušenie portfólia bol úspešne zadaný.',
				activeText: 'Zadali ste pokyn vybrať všetky finančné prostriedky a\u00a0zrušiť portfólio.',
				account: {
					title: 'Zadané zrušenie účtu',
					closure: 'Zadali ste príkaz na predaj ETF zo všetkých portfólií, stiahnutie všetkých prostriedkov a\u00a0zrušenie účtu. '
						+ 'Príkaz môžete zrušiť na stránke <0>Môj účet</0>.',
					child: {
						closure: 'Máte zadaný príkaz k\u00a0odpredaju ETF zo všetkých portfólií, výberu všetkých prostriedkov a\u00a0zrušeniu účtu. '
							+ 'Príkaz môže zrušiť iba rodič na svojej stránke <0>Môj účet</0>.',
					},
				},
				cancel: 'Zrušiť príkaz',
				popup: {
					title: 'Zrušenie portfólia',
					text: 'Potvrďte prosím, že si naozaj prajete zrušiť Vaše portfólia u\u00a0Fondee.',
					button: 'Áno, vybrať peniaze a\u00a0zrušiť účet',
					twoFactorAuthentication: 'Zadajte Váš jednorazový kód',
				},
				error: {
					title: 'Upozornenie',
					text: 'Zrušenie portfólia nie je možné zadať, pretože už máte zadaný príkaz na\u00a0výber všetkých prostriedkov, zrušenie účtu '
						+ 'alebo presun celého portfólia. Aby bolo možné príkaz na\u00a0výber zadať, musíte najprv zrušiť predchádzajúce príkazy',
					button: 'Zavrieť',
				},
				transferWarning: {
					title: 'Upozornenie',
					text: 'Máte zadaný príkaz na\u00a0presun hotovosti alebo ETF na\u00a0toto portfólio. Buď zrušte tento príkaz, '
						+ 'alebo zadajte príkaz na\u00a0zrušenie portfólia až po vykonaní presunu.',
					button: 'Rozumiem',
				},
				defaultPortfolioSelect: {
					title: 'Upozornenie',
					text: 'Zadali ste zrušenie portfólia, ktoré máte nastavené ako primárne portfólio pre vklady. '
						+ 'Vyberte si prosím nové portfólio pre alokáciu vkladov.',
					textChild: 'Zadali ste zrušenie portfólia, ktoré máte u vášho dieťaťa nastavené ako primárne portfólio pre vklady. '
						+ 'Vyberte si prosím nové portfólio na alokáciu vkladov. Vyberať môžete z\u00a0aktívnych portfólií daného užívateľa.',
					textBoth: 'Zadali ste zrušenie portfólia, ktoré máte vy aj vaše dieťa nastavené ako primárne portfólio '
						+ 'pre vklady. Vyberte si prosím nové portfólia pre každého užívateľa.',
					button: 'Nastaviť',
					label: 'Vyberte si prosím svoje nové defaultné portfólio na\u00a0vklady',
					labelChild: 'Vyberte si prosím nové defaultné portfólio pre dieťa',
				},
			},
			title: 'Výber všetkých portfólií a\u00a0zrušenie účtu',
			text: 'Pokiaľ chcete ukončiť investovanie, zadáme brokerovi príkaz predať všetky ETFká vo Vašich portfóliách. Ak '
				+ 'máte aktívne aj detské portfóliá, aj tieto portfóliá budú zrušené. Hotovosť bude následne, po odpočítaní mesačných '
				+ 'poplatkov, odoslaná na Váš primárny účet pre výbery. Celý proces môže trvať až týždeň. '
				+ 'Pokyn k\u00a0predaju môžete dať kedykoľvek, investície predáme najbližší obchodný deň.',
			textChild: 'Zrušenie investičného účtu môže zadať iba rodič. Pri dosiahnutí plnoletosti prechádzajú všetky práva '
				+ 'na Vás, takže budete mať nad účtom plnú kontrolu, vrátane jeho zrušenia.',
			note: 'Odporúčame namiesto úplného zrušenia účtu vybrať z\u00a0portfólia (alebo portfólií) všetky peniaze. Príkaz '
				+ 'jednoducho zadáte v\u00a0detaile konkrétneho <0>portfólia</0>. Tým získate celú hodnotu portfólia, ale pokiaľ '
				+ 'niekedy v\u00a0budúcnosti budete chcieť znovu investovať s\u00a0Fondee, nemusíte si znovu zakladať účet.',
			confirm: 'Potvrdiť',
			success: 'Príkaz na\u00a0zrušenie účtu bol úspešne zadaný.',
			activeTitle: 'Zadané zrušenie účtu',
			activeText: 'Zadali ste príkaz na predaj ETF zo všetkých portfólií, stiahnutie všetkých prostriedkov a\u00a0zrušenie účtu.',
			activeTextChild: 'Máte zadaný príkaz k\u00a0odpredaju ETF zo všetkých portfólií, výberu všetkých prostriedkov '
				+ 'a\u00a0zrušeniu účtu. Príkaz môže zrušiť iba rodič na svojej stránke Môj účet.',
			cancel: 'Zrušiť príkaz',
			childClosure: 'Keď máte zadaný príkaz na zrušenie detského účtu, nemôžete zadať zrušenie svojho účtu.',
			popup: {
				title: 'Zrušenie účtu',
				text: 'Potvrďte prosím, že si naozaj prajete zrušiť Váš investičný účet u\u00a0Fondee.',
				button: 'Áno, vybrať peniaze a\u00a0zrušiť účet',
				twoFactorAuthentication: 'Zadajte Váš jednorazový kód',
			},
		},
		transfer: {
			title: 'Presun medzi portfóliami',
			portfolioLabel: 'Presun do\u00a0portfólia:',
			direct: {
				title: 'Odpredaj a\u00a0presun hotovosti',
				text: 'Tu môžete zadať sumu eur, ktorú chcete presunúť do\u00a0iného portfólia. To znamená, že v\u00a0nasledujúci '
					+ 'obchodný deň zašleme maklérovi príkaz na\u00a0predaj príslušnej časti ETF a\u00a0prevedieme uvedenú sumu '
					+ 'v\u00a0eurách do\u00a0iného portfólia podľa vášho výberu. Fondy ETF sa predajú podľa ich pomerného zastúpenia '
					+ 'v\u00a0portfóliu, aby bolo vaše portfólio po predaji opäť optimálne rozložené. Prevedieme sumu v\u00a0eurách, aby '
					+ 'sme sa vyhli zbytočnej výmene z\u00a0a\u00a0do\u00a0českých korún. Ak zadaná suma presiahne aktuálnu hodnotu ETF, '
					+ 'celé portfólio sa predá a\u00a0hotovosť sa prevedie do\u00a0vybraného portfólia.',
				transferWarning: 'Máte zadaný príkaz na\u00a0odpredaj a\u00a0presun hotovosti. Nedá sa už zadať príkaz na\u00a0presun '
					+ 'časti portfólia bez predaja ETF. Pre zadanie presunu bez predaja ETF zrušte existujúci príkaz.',
			},
			all: {
				title: 'Predaj všetkého a\u00a0presun hotovosti',
				text: 'Predaj všetkých a\u00a0presun hotovosti znamená, že v\u00a0nasledujúci obchodný deň dáme maklérovi príkaz '
					+ 'na\u00a0predaj všetkých ETF v\u00a0tomto portfóliu a\u00a0presunieme hotovosť v\u00a0eurách do\u00a0iného portfólia '
					+ 'podľa vášho výberu. Prevedieme sumu v\u00a0eurách, aby sme sa vyhli zbytočnej výmene z\u00a0a\u00a0do\u00a0českých korún.',
				allPopup: {
					title: 'Presun hotovosti medzi portfóliami',
					text: 'Zadaná suma je veľmi blízka celkovej hodnote vášho portfólia. Chcete presunúť všetky svoje prostriedky?',
					button: 'Áno, presunúť všetky',
					secondaryButton: 'Nie, presunúť len požadovanú sumu',
				},
				activeText: 'Zadali ste pokyn zbaviť sa všetkých ETF v\u00a0portfóliu a\u00a0presunúť hotovosť do portfólia {{ portfolio }}.',
			},
			part: {
				title: 'Prevod časti portfólia bez predaja ETF',
				text: 'Presun časti portfólia bez predaja ETF znamená, že v\u00a0nasledujúci obchodný deň vypočítame počet kusov '
					+ 'zodpovedajúci zadanému pomeru (zaokrúhlené dole) pre každý ETF, ktorý máte v\u00a0portfóliu. Potom vypočítame '
					+ 'pomer hotovosti v\u00a0portfóliu (pre každú menu) zodpovedajúci zadanému pomeru (zaokrúhlený na\u00a0stotiny). '
					+ 'Presunúť vypočítané pomery ETF a\u00a0hotovosti do\u00a0vybraného portfólia. K\u00a0predaju ETF teda nedôjde.',
				activeText: 'Zadali ste príkaz na\u00a0presun {{ sum }} ETF a\u00a0hotovosti z\u00a0tohto portfólia '
					+ 'do\u00a0portfólia {{ portfolio }}.',
				error: {
					title: 'Upozornenie',
					text: 'Uvedený pomer portfólia je príliš vysoký. Zadajte nižší pomer alebo zrušte predtým zadané príkazy '
						+ 'na\u00a0presun časti portfólia.',
					button: 'Zavrieť',
				},
				transferWarning: 'Máte zadaný príkaz na\u00a0presun časti portfólia bez predaja ETF. Nie je možné už zadať príkaz '
					+ 'na\u00a0odpredaj a\u00a0presun hotovosti. Pre zadanie presunu hotovosti zrušte existujúci príkaz.',
			},
			activeTitle: 'Presun prostriedkov do\u00a0iného portfólia',
			cancel: 'Zrušiť príkaz',
			confirm: 'Potvrdiť',
			childWarning: 'Prostriedky môžete presúvať iba medzi portfóliami dieťaťa. Peniaze z\u00a0portfólia vášho dieťaťa '
				+ 'nemôžete vybrať na svoj osobný účet.',
			popup: {
				title: 'Upozornenie',
				text: 'Potvrďte, že chcete skutočne vykonať uvedený prevod.',
				textChild: 'Potvrďte prosím, že chcete skutočne uskutočniť zadaný presun. Upozorňujeme, že prostriedky presunuté '
					+ 'na detské portfólio nemožno odoslať späť na portfólio rodičov. Z\u00a0detských portfólií je možné zadávať '
					+ 'výbery na bankový účet do 10% hodnoty portfólia za rok. Pokiaľ si prajete vybrať celú čiastku, je nutné '
					+ 'detské portfólio zrušiť.',
				button: 'Áno, potvrdiť presun',
				twoFactorAuthentication: 'Zadajte Váš jednorazový kód',
			},
			error: {
				title: 'Upozornenie',
				text: 'Nemôžete zadať prevod, pretože ste už zadali príkaz na\u00a0výber všetkých prostriedkov, zrušenie účtu alebo '
					+ 'presun celého portfólia. Ak chcete zadať príkaz na\u00a0presun, musíte najprv zrušiť predchádzajúce príkazy.',
				button: 'Zavrieť',
			},
		},
	},
	childList: {
		title: 'Moje deti',
		text: 'Tu môžete spravovať svoje detské účty, dokončiť rozpracovanú registráciu alebo ju prípadne zrušiť.',
		finishOnboarding: 'Dokončiť registráciu',
		accountClosure: 'Máte zadané zrušenie účtu, nemožno stornovať zrušenie účtu dieťaťa.',
		personalInformation: {
			title: 'Kontaktné údaje dieťaťa',
			confirm: 'Uložiť zmeny',
			cancelAccount: 'Zrušenie detského účtu',
		},
	},
	childClosure: {
		cancel: {
			title: 'Zrušenie účtu',
			text: '<0>Prosím potvrďte, či si naozaj želáte zrušiť detský investičný účet vo Fondee. Pri predčasnom zrušení '
				+ 'nedodržíte podmienky pre zvýhodnený poplatok. Z\u00a0čiastky pred vyplatením preto podľa zmluvných podmienok '
				+ 'strhneme rozdiel medzi poplatkom, ktorý ste doposiaľ zaplatili a\u00a0naším bežným poplatkom.</0>'
				+ '<0>Po odpredaní Vašich ETF bude následne hotovosť odoslaná na Váš primárny účet pre výbery. Celý proces '
				+ 'môže trvať až týždeň. Pokyn na zrušenie účtu môžete dať kedykoľvek, investície  predáme najbližší obchodný deň. '
				+ 'Odporúčame namiesto úplného zrušenia účtu vybrať z\u00a0portfólia (alebo portfólií) všetky peniaze. Príkaz '
				+ 'jednoducho zadáte v\u00a0detaile konkrétneho portfólia. Tým získate celú hodnotu portfólia, ale pokiaľ niekedy '
				+ 'v\u00a0budúcnosti budete chcieť znovu investovať s\u00a0Fondee, nemusíte si znovu zakladať účet.</0>',
			confirm: 'Rozumiem, zrušiť detský účet',
			warning: 'Bolo požiadané o\u00a0zrušenie účtu',
		},
	},
	onboardingClosure: {
		title: 'Zrušiť registráciu',
		text: 'Prosím potvrďte, či si naozaj želáte zrušiť registráciu dieťaťa',
		cancel: 'Zrušiť účet dieťaťa',
		confirm: 'Rozumiem, zrušiť registráciu',
	},
};

const ageCheckBlock = {
	title: 'Upozornenie',
	text: 'Je nám to ľúto, ale nespĺňate vekovú hranicu pre investovanie s\u00a0Fondee. Radi Vás opäť privítame, '
		+ 'až dosiahnete osemnásť\u00a0rokov. Pokiaľ si zatiaľ prajete registráciu zrušiť, môžete tak urobiť cez odkaz\u00a0nižšie.',
	button: 'Zrušiť účet',
	aborted: 'Váš učet bol zrušený.',
};

const allPortfoliosCheck = {
	legend: {
		negative: 'Aktuálny profil',
		positive: 'Plánovaná zmena pri najbližšom obchodovaní',
	},
	table: {
		title: 'Názov portfólia',
	},
	recommend: 'Profily v\u00a0<0>zelenom</0> intervale sme na základe vami vyplneného '
			+ 'investičného dotazníka vyhodnotili ako vhodné pre Vás.',
	recommendWarning: 'Profily v\u00a0šedom intervale pre Vás nie sú podľa vyhodnotenia investičného '
			+ 'dotazníka vhodné',
	confirm: 'Potvrdiť',
	reset: 'Prajem si skontrolovať odpovede v\u00a0investičnom dotazníku',
};

const anchors = {
	about: 'co-je-to-fondee',
	accountClosure: 'zrusenie-uctu',
	chart: 'graf',
	comparison: 'porovnanie',
	faq: 'najcastejsie-otazky',
	contactForm: 'kontaktujte-nas',
	ourApproachTeam: 'koho-by-ste-vybrali',
	portfolio: 'portfolio',
	portfolioSettings: 'nastavenie-portfolia',
	portfolioWithdrawals: 'zadane-prikazy',
	products: 'produkty',
	rebalancing: 'rebalansovanie',
	studentConfirmation: 'potvrdenie-studenta',
};

const appBanner = {
	title: 'Fondee',
	author: 'Fondee a.s.',
	downloadGoogle: 'ZADARMO - na\u00a0Google Play',
	downloadIOS: 'ZADARMO - v\u00a0App Store',
	visit: 'Zobraziť',
};

const blog = {
	meta: {
		title: 'Fondee blog',
		description: 'Všetko, čo ste kedy chceli vedieť o\u00a0investovaní, ale báli ste sa opýtať.',
	},
	back: '\u2190\u00a0Späť',
	comingSoon: 'Čoskoro sa sem vráťte, budú tu na Vás čakať nové články.',
	title: 'Fondee blog',
	text: 'Všetko, čo ste kedy chceli vedieť o\u00a0investovaní, ale báli ste sa opýtať.',
	menu: {
		title: 'Filtrácia článkov',
		search: {
			label: 'Hľadať článok',
			empty: 'Nenašli sme žiadny článok',
		},
		tags: {
			title: 'Filtrovať podľa tagov',
		},
		topRead: {
			title: 'Najčítanejšie články',
			button: 'Zobraziť najčítanejší',
		},
	},
};

const blogPost = {
	meta: {
		titleSuffix: ' - Fondee blog',
	},
	updated: 'Aktualizované',
	share: {
		title: 'Páčil sa vám článok?',
		description: 'Zdieľajte ho so svojimi priateľmi',
		button: 'Zdieľať na ',
	},
	list: {
		button: 'Zobraziť ďalšie články',
	},
	recommended: {
		title: 'Ďalšie nadväzujúce články',
	},
	references: {
		title: 'Zdroje:',
	},
	content: {
		title: 'Obsah článku',
	},
};

const campaign = {
	calculation: {
		fondee: {
			enumeration: 'zhodnotenie na Fondee\u00a0- miera inflácie\u00a0= reálne zhodnotenie',
			enumarationResult: '6\u00a0%\u00a0- 3,5\u00a0%\u00a0= 2,5\u00a0%',
			comparation: 'Za rovnakú dobu by sa Váš vklad na sporiacom účte kvôli nízkemu úroku zmenšil až o\u00a0460 EUR.',
			info: 'Každý rok tak v\u00a0skutočnosti zhodnotíte svoje úspory o\u00a02,5\u00a0%. Pri vklade 4000\u00a0EUR to za 5\u00a0rokov priemerne robí: <0>+540 EUR</0>',
			link: 'Ukázať výpočet pre sporiaci účet',
		},
		account: {
			enumeration: 'úrok na sporiacom účte\u00a0- miera inflácie\u00a0= reálne zhodnotenie',
			enumarationResult: '1\u00a0%\u00a0- 3,5\u00a0%\u00a0= -2,5\u00a0%',
			comparation: 'Za rovnakú dobu by Váš vklad vo Fondee mohol vďaka vyššiemu úroku vzrásť priemerne o\u00a0525 EUR.',
			info: 'Každý rok tak v\u00a0skutočnosti prichádzate o\u00a02,5\u00a0% svojich úspor. Pri vklade 4000\u00a0EUR to za 5\u00a0rokov urobí až: <0>-460 EUR</0>',
			link: 'Ukázať výpočet pre Fondee',
		},
		title: 'Jednoduchá matematika',
		button: 'Chcem vyskúšať Fondee ZDARMA',
	},
	calculator: {
		button: 'Spočítat',
		fields: {
			deposit: {
				label: 'Vklad',
				error: 'Hodnota vkladu musí byť vyplnená.',
				errorRange: 'Hodnota vkladu musí být v\u00a0rozmedzí od 40\u00a0EUR do 400\u00a0000 EUR.',
			},
			inflationRate: {
				label: 'Miera inflácie',
				error: 'Hodnota miery inflácie musí byť vyplnená.',
				errorRangeMin: 'Nie je moc reálne, že by miera inflácie klesla pod 1\u00a0%.',
				errorRangeMax: 'Na túto hodnota snáď inflácia nestúpne. Dúfajme.',
			},
			interestRate: {
				fondee: {
					label: 'Zhodnotenie',
					error: 'Hodnota zhodnotenie je nemenná a\u00a0rovná 8\u00a0%.',
				},
				account: {
					label: 'Úrok na sporiacom účte',
					error: 'Hodnota úroku na sporiacom účte musí byť vyplnená.',
					errorRange: 'Hodnota úroku na sporiacom účte musí byť v\u00a0rozmedzí 0 až 10\u00a0%.',
				},
			},
			years: {
				fondee: {
					label: 'Doba investovania (roky)',
					error: 'Hodnota doby investovania musí byť vyplnená.',
					errorRange: 'Hodnota doby investovania musí byť v\u00a0rozmedzí od 1\u00a0roku do 50\u00a0rokov.',
					errorFormat: 'Hodnota doby investovania musí byť celočíselná.',
				},
				account: {
					label: 'Doba sporenia (roky)',
					error: 'Hodnota doby sporenia musí byť vyplnená.',
					errorRange: 'Hodnota doby sporenia musí byť v\u00a0rozmedzí od 1\u00a0roku do 50\u00a0rokov.',
					errorFormat: 'Hodnota doby investovania musí byť celočíselná.',
				},
			},
		},
		how: 'Ako to?',
		result: {
			info: 'Reálna hodnota peňazí:',
			value: '{{ value }}',
			fondee: 'Hurá! Váš vklad vyrastie v\u00a0priereme o\u00a0<0>{{ value }}</0>.',
			fondeeComparation: 'Zatiaľčo na sporiacom účte vám až {{ value }} zmizne v\u00a0nenávratne.',
			account: 'Na <0>sporiacom účte</0> sa Váš vklad <0>znehodnotí</0> až o\u00a0<0>{{ value }}</0>.',
			accountComparation: 'S\u00a0<0>Fondee</0> by sa naopak zhodnotil zhruba o\u00a0<1>{{ value }}</1>, '
				+ 'a\u00a0to i\u00a0po započítaní vplyvu inflácie!',
		},
		subtitle: 'Ako sa Vaše uložené peniaze naozaj zhodnocujú?',
		title: 'Úprimná kalkulačka úspor',
		toggle: {
			fondee: 'Na Fondee',
			account: 'Na sporiacom účte',
		},
	},
	explanation: {
		account: {
			info: 'Dôvodom je nižšie úročenie na sporiacich účtoch ako je miera inflácie. Preto na Vašom sporiacom účte peniaze strácajú hodnotu.',
			title: 'Ako to, že na sporiacom účte o\u00a0svoje peniaze prichádzate?',
			vocabulary: {
				title: 'Jednoduchý slovníček',
				collapse: {
					1: {
						name: 'Úrok na sporiacom účte',
						description: 'O\u00a0koľko\u00a0% vzrastie Váš vklad na sporiacom účte za 1\u00a0rok',
					},
					2: {
						name: 'Miera inflácie',
						description: 'O\u00a0koľko\u00a0% sa zníži hodnota meny za 1\u00a0rok',
					},
				},
			},
		},
		fondee: {
			info: 'Dôvodom je vyššie zhodnocovanie investícií s\u00a0Fondee ako je miera inflácie. Preto vám s\u00a0Fondee peniaze deň čo deň pribúdajú.',
			title: 'Ako to, že na Fondee zhodnocujete svoje peniaze?',
			vocabulary: {
				title: 'Jednoduchý slovníček',
				collapse: {
					1: {
						name: 'Zhodnotenie na Fondee',
						description: 'Zhodnotenie 6\u00a0% za rok je príklad na základe historických dát približne zodpovedajúcim akciovým portfóliam. '
							+ 'Na konkrétne portfólia a\u00a0ich historické zhodnotenia sa môžete pozrieť <0>tu</0>.',
					},
					2: {
						name: 'Miera inflácie',
						description: 'O\u00a0koľko\u00a0% sa zníži hodnota meny za 1\u00a0rok',
					},
				},
			},
		},
	},
	hero: {
		button: 'Založte si Fondee',
		subtitle: 'Vaše úspory nemusia strácať hodnotu na\u00a0sporiacich\u00a0účtoch.',
		title: 'Nešetrite. Investujte!',
	},
	investWithFondee: {
		button: 'Chcem vyskúšať Fondee ZDARMA',
		list: {
			1: '<0>Historicky vyšší úrok</0> ako na sporiacich účtoch',
			2: '<0>Zvládne to úplne každý</0>, lebo do akcií a\u00a0dlhopisov investujeme za Vás',
			3: '<0>3\u00a0mesiace</0> bez poplatkov',
		},
		more: 'Viac o\u00a0Fondee',
		title: 'Investujte s\u00a0Fondee',
	},
};

const childAgeCheck = {
	modal: {
		title: 'Blíži sa plnoletosť Vášho dieťaťa',
		text: 'Dovŕšením plnoletosti dieťaťa dôjde k\u00a0prevedeniu jeho portfólia pod vlastnú správu. K\u00a0tomu '
			+ 'potrebujeme doplniť osobné údaje uvedené nižšie. Pokiaľ údaje nevyplníte, zostane portfólio pod vašou plnou správou.',
		checkbox: 'Potvrdzujem, že kontaktné údaje môjho dieťaťa sú správne a\u00a0aktuálne.',
		button: 'Potvrdiť údaje',
	},
};

const clientCheck = {
	description: '<0>Na tejto stránke okrem vlastných pravidelných kontrol nájdete aj kontroly Vašich detí, '
		+ 'ktoré je raz za čas potrebné vykonať.</0><0> Pokiaľ ostane kontrola nedokončená, obmedzíme Váš prístup '
		+ 'do klientskej zóny, rovnako ako prístup dieťaťa (pokiaľ ho má). V\u00a0prípade akýchkoľvek dotazov '
		+ 'nás neváhajte kontaktovať na\u00a0<0>info@fondee.cz</0>.</0>',
	childDescription: 'Pre obnovenie prístupu do klientskej zóny je potrebné dokončiť kontrolu detského účtu. Kontrolu musí '
		+ 'vykonať rodič vo svojej klientskej zóne na\u00a0stránke Pravidelné kontroly.',
	questionnaire: {
		aml: {
			title: 'Kontrola pôvodu peňazí',
			text: 'Aby sme mohli majetok Váš aj ostatných klientov naďalej chrániť, musíme raz za čas preveriť, že '
				+ 'všetky údaje, ktoré ste nám poskytli, sú aktuálne. Poďme na to, proces zaberie len pár minút.',
			start: 'Vyplniť dotazník',
			closeAccount: {
				title: 'Zrušenie účtu',
				text: 'Vyberte menu',
				button: 'Zrušiť účet',
			},
		},
		investment: {
			title: 'Kontrola investičného dotazníka',
			text: 'Z času na čas musíme preveriť, či sa u vás nezmenili investičné podmienky. V prípade, že áno, môže to mať vplyv na voľbu '
				+ 'Vášho ideálneho investičného profilu. Upozorňujeme, že na základe nových odpovedí Vám môže vyjsť iný profil, než máte teraz '
				+ 'zvolený. Odpovede môžete pred dokončením kedykoľvek zmeniť.',
			warning: 'Upozorňujeme, že pre úspešné dokončenie kontroly je potrebné potvrdiť zmeny. Pokiaľ zmeny '
				+ 'nepotvrdíte, bude Vám po 30\u00a0dňoch od spustenia kontroly zamknutá Klientská zóna. Pokiaľ ste v\u00a0dotazníku '
				+ 'urobili chybu, môžete sa k\u00a0odpovediam vrátiť.',
			start: 'Vyplniť dotazník',
			confirmStart: {
				title: 'Upozornenie',
				text: '<0><0>POZOR:</0> Odpovede <0>ovplyvnia investičný profil</0> Vášho existujúceho portfólia!</0>'
					+ '<0>Pokiaľ zvolíte konzervatívnejšie odpovede, než zodpovedajú Vašej aktuálnej rizikovosti, bude nutné zmeniť '
					+ 'investičný profil Vášho portfólia.</0>'
					+ '<0>Vyplňte, prosím, dotazník maximálne starostlivo.</0>',
				button: 'Rozumiem',
			},
			confirmProfile: {
				title: 'Potvrdenie investičného profilu',
				text: 'Chystáte sa <0>potvrdiť existujúci investičný profil</0> Vášho portfólia:',
				portfolio: 'Portfólio',
				more: 'Chcete pokračovať?',
				confirm: 'Pokračovať',
				back: 'Späť k dotazníku',
			},
			changeProfile: {
				title: 'Zmena investičného profilu',
				text: 'Chystáte sa <0>zmeniť investičný profil</0> v nasledujúcich portfóliách:',
				portfolio: 'Portfólio',
				change: 'Z {{ from }} na {{ to }}',
				secondaryText: 'Táto zmena spustí automatické rebalancovanie v\u00a0priebehu najbližšieho obchodného '
					+ 'dňa (môže byť aj dnes), čo zahŕňa predaj a\u00a0nákup ETF.',
				more: 'Chcete pokračovať?',
				confirm: 'Pokračovať',
				back: 'Späť k dotazníku',
				support: 'Kontaktovať podporu',
				success: 'Ďakujeme, e-mail bol odoslaný. Budeme vás kontaktovať čo najskôr.',
			},
		},
	},
	documents: {
		error: 'Ospravedlňujeme sa, pri nahrávaní dokladov došlo k chybe.',
		title: 'Nahranie platného dokladu',
		text: 'Nahrajte prosím svoj aktuálny osobný doklad. Po kliknutí na tlačidlo Vás presmerujeme k\u00a0našej'
			+ ' partnerskej službe, ktorá pre nás spracovanie dokladov zaisťuje. Proces prebieha šifrovane, aby sme '
			+ 'zaistili bezpečie osobných údajov.',
		textChild: 'Nahrajte prosím osobný doklad Vášho dieťaťa. Po kliknutí na tlačidlo Vás presmerujeme k\u00a0našej'
			+ ' partnerskej službe, ktorá pre nás spracovanie dokladov zaisťuje. Proces prebieha šifrovane, aby sme '
			+ 'zaistili bezpečie osobných údajov.',
	},
	personalInformation: {
		title: 'Kontrola osobných údajov',
	},
	checkAt: 'Uskutočnená kontrola: <0>{{ date }}</0>',
	checkNext: 'Plánovaná kontrola: <0>{{ date }}</0>',
	profileSelect: {
		prev: 'Predchádzajúci',
		next: 'Nasledujúci',
		myAccount: 'Môj účet',
	},
	disabledTooltip: 'Akcia sa odomkne po dokončení kontroly',
};

const clientHeader = {
	faqLink: 'Ako a\u00a0kedy prebieha investovanie',
	nextTradingDay: 'Najbližší obchodný deň',
	titles: {
		deposit: 'Vklad',
		depositAndWithdrawal: 'Vklad a\u00a0výber',
		trading: 'Obchodovanie',
		withdrawal: 'Výber',
	},
};

const contact = {
	title: 'Spýtajte sa',
	text: 'Prečítajte si najčastejšie otázky alebo nám napíšte pomocou formulára nižšie',
	contactFormAnchor: 'Prejsť k\u00a0formuláru',
	faqAnchor: 'Prejsť k\u00a0najčastejším otázkam',
	faqTitle: 'Najčastejšie otázky',
};

const contactForm = {
	button: 'Nechajte nám kontakt<0></0>a\u00a0my vám zavoláme',
	modal: {
		title: 'Chcete sa na niečo opýtať?',
		subtitle: 'Nechajte nám na seba číslo, ozveme sa\u00a0vám',
		submit: 'Zavolejte mi',
		success: 'Ďakujeme, čoskoro sa vám\u00a0ozveme!',
	},
};

const contactUs = {
	title: 'Nenašli ste odpoveď na svoje\u00a0otázky?',
	text: {
		label: 'Vaša otázka',
		error: 'Vaša otázka je nutná',
	},
	name: {
		label: 'Meno a\u00a0priezvisko',
		error: 'Prosím, zadajte Vaše meno.',
	},
	email: {
		label: 'E-mail',
		error: 'Prosím, zadajte Vašu e-mailovú adresu.',
	},
	conditions: {
		label: 'Súhlasím so <0>spracovaním osobných údajov</0> a\u00a0<1>pravidlami webu</1>',
		error: 'Bez tohto súhlasu to bohužiaľ nepôjde.',
	},
	submit: 'Odoslať otázku',
	success: 'Ďakujeme za Vašu otázku, čoskoro sa vám ozveme!',
};

const cookieConsent = {
	message: '<0>Fondee\u00a0a.s. používa súbory cookie, aby svoje internetové stránky lepšie prispôsobila'
		+ 'zákazníkom a\u00a0uľahčila ich používanie. Nevyhnutné cookies spracovávame vždy aj\u00a0bez Vášho '
		+ 'súhlasu, tým zaisťujeme správne fungovanie internetových stránok.</0><0>Pokiaľ kliknete na tlačidlo '
		+ '„Prijať všetko“, dáte tým Fondee\u00a0a.s. súhlas s\u00a0ukladaním analytických a\u00a0marketingových '
		+ 'súborov cookie. Pokiaľ kliknete na tlačítko „Prijať len potrebné“, dáte tým Fondee\u00a0a.s. '
		+ 'súhlas s\u00a0 ukladaním iba funkčných súborov cookie. Ak kliknete na možnosť „Detailné nastavenie“, '
		+ 'môžete zvoliť, ktoré účely spracovania cookie chcete povoliť.</0><0>Pomocou súborov cookie môžeme my '
		+ 'aj\u00a0tretie strany zhromažďovať informácie o\u00a0Vás a\u00a0sledovať tak '
		+ 'Vaše správanie pri prechádzaní našich aj\u00a0prípadne iných stránok. Na\u00a0základe týchto informácií '
		+ 'môžeme svoje stránky, svoju komunikáciu aj\u00a0svoje reklamy prispôsobiť podľa Vašich záujmov a\u00a0profilu. '
		+ 'Navyše Vám tieto súbory cookie umožňujú zdieľanie informácií na\u00a0sociálnych médiách.</0><0>'
		+ 'Viac informácií nájdete v\u00a0\u00a0našich <0>Informáciách o\u00a0spracovaní osobných údajov</0> '
		+ 'a\u00a0v\u00a0<1>Zásadách používania súborov cookie</1>.</0>',
	controls: {
		details: 'Detailné nastavenie',
		necessary: 'Prijať len potrebné',
		selected: 'Prijať len vybrané',
		all: 'Prijať všetko',
	},
	categories: {
		functionality: {
			title: 'Funkčné cookies',
			text: 'Používame predovšetkým preto, aby sme zaistili, že naše webové stránky budú riadne fungovať, '
				+ 'aby sme zistili, aké sú Vaše preferencie a\u00a0aby si webová stránka pamätala, že ste '
				+ 'sa prihlásil, a\u00a0ďalej na sledovanie užívateľov za účelom ich registrácie. Nevyhnutné cookies '
				+ 'sa ďalej používajú na\u00a0optimalizáciu našich webových stránok v\u00a0reálnom čase podľa toho, ako stránky '
				+ 'používate a\u00a0na\u00a0rovnomerné načítanie webových stránok. Bez týchto súborov nemôžu Internetové '
				+ 'stránky riadne fungovať.',
		},
		analytics: {
			title: 'Analytické cookies',
			text: 'Slúžia na\u00a0analýzu používania Internetových stránok, na\u00a0počítanie návštevníkov, na sledovanie doby '
				+ 'návštevnosti a\u00a0zisťovania, ako je možné Internetové stránky vylepšiť. Štatistiky používania Internetových stránok '
				+ 'a\u00a0ďalšie správy nespájame s\u00a0konkrétnymi ľuďmi.',
		},
		marketing: {
			title: 'Marketingové cookies',
			text: 'Slúžia na zhromažďovanie osobných údajov o\u00a0užívateľovi z\u00a0marketingového hľadiska. Napr. zhromažďujú '
				+ 'informácie za účelom prispôsobenia ponúkanej reklamy záujmom zákazníka, prepojenia so sociálnymi sieťami, odovzdávania '
				+ 'informácií medzi affiliate partnermi\u00a0atď.',
		},
	},
};

const cookies = {
	title: 'Zásady používania súborov cookie',
	intro: '<0>Toto sú zásady používania súborov cookie (ďalej len „<0>Zásady</0>“) na\u00a0internetových stránkach '
		+ 'spoločnosti <0>Fondee\u00a0a.s.</0>, so sídlom Rumunská\u00a022/28, Vinohrady, 120\u00a000 Praha\u00a02, '
		+ 'IČO:\u00a006691862, zapísané v\u00a0bchodnom registri vedenom Mestským súdom v\u00a0Prahe pod '
		+ 'sp.\u00a0zn.\u00a0B\u00a027322 (ďalej len „<0>Spoločnosť</0>“ alebo „<0>Správca</0>“) a\u00a0v\u00a0rámci aplikácie '
		+ 'Správca nazvanej „Fondee mobilná aplikácia“ (ďalej len „<0>Aplikácia</0>“) a\u00a0vzťahujú sa na '
		+ 'všetkých užívateľov internetových stránok prevádzkovaných Spoločnosťou (ďalej len „<0>Internetové stránky</0>“) '
		+ 'a\u00a0na Aplikáciu tam, kde je to relevantné.</0>'
		+ '<0>Účelom týchto Zásad je náležite informovať užívateľov Internetových stránok a/alebo Aplikácie o\u00a0používaní súborov cookie '
		+ 'a\u00a0o\u00a0 možnosti ich používania zakázať, ako aj\u00a0ich účelom poskytnutia informácií, akým '
		+ 'spôsobom sú spracovávané a\u00a0oužívané údaje užívateľov Internetových stránok a/alebo Aplikácie a\u00a0ako pomocou súborov '
		+ 'cookie Spoločnosť spracováva informácie o\u00a0užívateľoch Internetových stránok.</0>'
		+ '<0>Spoločnosť nepoužíva súbory cookie, aby zistila osobné údaje užívateľov, ale osobné údaje, ktoré Spoločnosť '
		+ ' ich prostredníctvom získa, môže prípadne spojiť s \u00a0nými osobnými údajmi užívateľov, ktoré používatelia '
		+ 'Spoločnosti poskytli alebo ich Spoločnosť získala iným spôsobom. Spoločnosť spracováva osobné údaje užívateľov '
		+ 'vrátane osobných údajov získaných vďaka používaniu súborov cookie v\u00a0súlade s\u00a0Nariadením Európskeho parlamentu '
		+ 'a\u00a0Rady\u00a0(EÚ) č.\u00a02016/679 z 27.\u00a0apríla\u00a02016, o\u00a0ochrane fyzických osôb '
		+ 'v\u00a0súvislosti so spracovaním osobných údajov a\u00a0o\u00a0voľnom pohybe týchto údajov, a\u00a0súvisiacimi '
		+ 'právnymi predpismi na\u00a0ochranu osobných údajov a\u00a0používanie súborov\u00a0cookie.</0>',
	about: {
		title: 'Čo sú súbory cookie',
		text: '<0>Cookies predstavujú krátke textové súbory, ktoré obvykle obsahujú jedinečný identifikátor '
			+ 'a\u00a0ktoré sú zaslané do prehliadača Vášho koncového zariadenia a\u00a0sú umiestnené v\u00a0úložisku Vášho '
			+ 'koncového zariadenia. Uvedený súbor obsahuje niektoré anonymné informácie ako je identifikátor, názov webovej '
			+ 'stránky, prípadne dobu trvania (okamih expirácie). Daný súbor môže byť dočasný a\u00a0existuje v\u00a0dobe Vášho '
			+ 'prihlásenia na našu webovú stránku alebo môže mať trvalú povahu a\u00a0zostáva v\u00a0úložisku Vášho koncového '
			+ 'zariadenia po dobu stanovenej expirácie alebo pokiaľ nie je odstránený užívateľom.</0>',
		app: {
			title: 'Využitie cookies v\u00a0Aplikácii',
			text: '<0>Aplikácia používa funkčné cookies. Funkčné cookies sú nevyhnutné na to, aby Aplikácia správne fungovala '
				+ 'a\u00a0zároveň aby si pamätala, že ste sa prihlásili. Práve vďaka funkčným cookies môžeme monitorovať bezchybný '
				+ 'chod aplikácie a\u00a0pripravovať nové verzie aplikácie a\u00a0pod. Údaje získané vďaka funkčným cookies '
				+ 'spracovávame vždy a\u00a0iba v\u00a0agregovanej podobe, teda bez identifikácie konkrétneho užívateľa. Údaje '
				+ 'spracovávané vďaka funkčným cookies nebudú využité na marketingové cielenie.</0>'
				+ '<0>V\u00a0Aplikácii používame aj základné analytické nástroje. Do Vášho koncového zariadenia však nie sú ukladané '
				+ 'žiadne informácie, ako je to pri analytických cookies na Internetových stránkach. Tieto informácie sa týkajú iba '
				+ 'zobrazenia jednotlivých stránok v\u00a0Aplikácii a\u00a0sú plne anonymizované a\u00a0agregované. Žiadne informácie '
				+ 'o\u00a0konkrétnom užívateľovi nie sú zbierané ani ukladané.</0>'
				+ '<0>Inštaláciou a\u00a0využívaním Aplikácie súhlasíte so spracovaním funkčných cookies v\u00a0rozsahu popísanom vyššie.</0>',
		},
	},
	types: {
		title: 'Rozdelenie súborov cookie',
		texts: [
			{
				text: '<0>Podľa zdroja a\u00a0kontroly rozlišujeme nasledujúce súbory cookie:</0>',
				list: [
					'<0>Cookies prvej strany</0>, ktoré sú vytvárané Internetovými stránkami. Pri návšteve Internetových stránok '
					+ 'sú tieto súbory cookie pod kontrolou a\u00a0správou Spoločnosti.',
					'<0>Cookies tretích strán</0>, ktoré sú vytvárané ostatnými webmi, ktoré na\u00a0 Internetových stránkach vlastnia '
					+ 'určitý obsah a\u00a0tieto súbory cookie nie sú pod kontrolou Spoločnosti.',
				],
			},
			{
				text: '<0>Podľa doby uloženia na Vašom koncovom zariadení sa delia súbory cookie\u00a0na:</0>',
				list: [
					'<0>Dočasné</0>, ktoré sú po skončení Vašej návštevy Internetových stránok zmazané.',
					'<0>Trvalé</0>, ktoré zostávajú uložené aj\u00a0po ukončení Vašej návštevy Internetových stránok.',
				],
			},
			{
				text: '<0>V\u00a0závislosti od\u00a0funkcie a\u00a0účelu, za ktorými súbory cookie používame, ich delíme '
					+ 'na\u00a0nasledujúce:</0>',
				list: [
					'<0>Funkčné cookies</0>, ktoré používame predovšetkým preto, aby sme zaistili, že naše Internetové stránky a\u00a0'
					+ 'Aplikácie budú riadne fungovať, aby sme zistili, aké sú Vaše preferencie a\u00a0aby si Internetová stránka '
					+ 'a\u00a0Aplikácie pamätali, že ste sa prihlásili, a\u00a0ďalej na sledovanie užívateľov za účelom ich registrácia. '
					+ 'Nevyhnutné cookies sa ďalej používajú na optimalizáciu našich Internetových stránok a\u00a0Aplikácie v\u00a0reálnom '
					+ 'čase podľa toho, ako stránky používate a\u00a0na rovnomerné načítanie Internetových stránok a\u00a0Aplikácie. '
					+ 'Bez týchto súborov nemôžu Internetové stránky a\u00a0Aplikácie riadne fungovať.',
					'<0>Analytické cookies</0>, ktoré slúžia na\u00a0 analýzu používania Internetových stránok, na\u00a0 počítanie '
					+ 'návštevníkov, na\u00a0sledovanie doby návštevnosti a\u00a0zisťovania, ako je možné Internetové stránky vylepšiť. '
					+ 'Štatistiky používania Internetových stránok a\u00a0ďalšie správy nespájame s\u00a0konkrétnymi ľuďmi.',
					'<0>Marketingové cookies</0>, ktoré slúžia na zhromažďovanie osobných údajov o\u00a0užívateľovi z\u00a0marketingového '
					+ 'hľadiska. Napr. zhromažďujú informácie za účelom prispôsobenia ponúkanej reklamy záujmom zákazníka, prepojenia '
					+ 'so sociálnymi sieťami, odovzdávania informácií medzi affiliate partnermi\u00a0atď.',
				],
			},
		],
	},
	table: {
		title: 'Prehľad konkrétnych cookies používaných na\u00a0Internetových stránkach',
		head: [
			'Cookies',
			'Doména alebo\u00a0nástroj',
			'Prvé\u00a0strany /\u00a0Tretie\u00a0strany',
			'Účel',
			'Kategória',
			'Doba',
		],
		rows: [
			{
				name: '_ga',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Počíta a\u00a0ukladá počet zobrazení stránok.',
				category: 'Analytické',
				period: '2\u00a0roky',
			},
			{
				name: '_gid',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Počíta a\u00a0ukladá počet zobrazení stránok.',
				category: 'Analytické',
				period: '1\u00a0deň',
			},
			{
				name: '_gat_UA-*',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Obmedzuje objem dát, ktoré Google ukladá na stránkach s\u00a0vysokým objemom návštev.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: 'YSC',
				domain: 'youtube.com',
				party: 'Tretie strany',
				purpose: 'Monitoruje a\u00a0zbiera informácie o\u00a0akciách používateľa pri videách z\u00a0YouTube.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: 'PHPSESSID',
				domain: 'api.fondee.sk',
				party: 'Prvé strany',
				purpose: 'Zaisťuje základných funkčnosť webových stránok.',
				category: 'Funkčné',
				period: '14\u00a0dní',
			},
			{
				name: '_nss',
				domain: 'api.fondee.sk',
				party: 'Prvé strany',
				purpose: 'Kontroluje, čo spustilo načítanie stránky.',
				category: 'Funkčné',
				period: 'Session',
			},
			{
				name: '_nss',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Kontroluje, čo spustilo načítanie stránky.',
				category: 'Funkčné',
				period: 'Session',
			},
			{
				name: 'LaUserDetails',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Ukladá údaje na identifikáciu používateľov.',
				category: 'Funkčné',
				period: 'Session',
			},
			{
				name: 'LaSID',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Identifikátor session návštevníka.',
				category: 'Funkčné',
				period: 'Session',
			},
			{
				name: 'LaVisitorId_*',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Identifikátor návštevníka na každej stránke, kde je integrovaný chatovací widget.',
				category: 'Funkčné',
				period: 'Session',
			},
			{
				name: 'LaVisitorNew',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Informácie, či sa jedná o\u00a0nového alebo vracajúceho sa návštevníka.',
				category: 'Funkčné',
				period: '1\u00a0deň',
			},
			{
				name: COOKIE_CONSENT_NAME,
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Ukladá súhlas so spracovaním cookies.',
				category: 'Funkčné',
				period: '1\u00a0rok',
			},
			{
				name: 'PHPSESSID',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Zaisťuje základných funkčnosť webových stránok.',
				category: 'Funkčné',
				period: '30\u00a0dní',
			},
			{
				name: 'VISITOR_INFO1_LIVE',
				domain: 'youtube.com',
				party: 'Tretie strany',
				purpose: 'Umožňuje odhadnúť rýchlosť internetu používateľa pre prehranie Youtube videa.',
				category: 'Funkčné',
				period: '6\u00a0mesiacov',
			},
			{
				name: 'fondee_infobox',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Sledovanie, či používateľ odklikol oznámenia na Internetových stránkach.',
				category: 'Funkčné',
				period: '1\u00a0rok',
			},
			{
				name: 'seen_find_out_profile_popup',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Používa sa na sledovanie užívateľov za účelom registrácie.',
				category: 'Funkčné',
				period: 'Trvalé',
			},
			{
				name: '_fbp',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Sleduje a\u00a0ukladá návštevy naprieč stránkami.',
				category: 'Marketingové',
				period: '3\u00a0mesiace',
			},
			{
				name: '_gcl_au',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Sleduje a\u00a0ukladá konverzie užívateľov.',
				category: 'Marketingové',
				period: 'Trvalé',
			},
			{
				name: 'IDE',
				domain: 'doubleclick.net',
				party: 'Tretie strany',
				purpose: 'Sledovanie používateľa za účelom zobrazovania reklám.',
				category: 'Marketingové',
				period: '13\u00a0mesiacov',
			},
			{
				name: 'test_cookie',
				domain: 'doubleclick.net',
				party: 'Tretie strany',
				purpose: 'Sledovanie používateľa za účelom zobrazovania reklám.',
				category: 'Marketingové',
				period: '15\u00a0minút',
			},
			{
				name: 'sid',
				domain: 'seznam.cz',
				party: 'Tretie strany',
				purpose: 'Sledovanie používateľa za účelom zobrazovania reklám.',
				category: 'Marketingové',
				period: '30\u00a0dní',
			},
			{
				name: 'ehubSession_*',
				domain: 'ehub',
				party: 'Tretie strany',
				purpose: 'Sledovanie registrácií z\u00a0affiliate programov.',
				category: 'Marketingové',
				period: '30\u00a0dní',
			},
			{
				name: 'ehubVisitorId',
				domain: 'ehub',
				party: 'Tretie strany',
				purpose: 'Sledovanie registrácií z\u00a0affiliate programov.',
				category: 'Marketingové',
				period: '30\u00a0dní',
			},
			{
				name: '_hjSessionUser{site_id}',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Prideľuje jednoznačné ID každému návštevníkovi stránok.',
				category: 'Analytické',
				period: '365\u00a0dní',
			},
			{
				name: '_hjSession{site_id}',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Ukladá aktuálne dáta zo Session.',
				category: 'Analytické',
				period: '30\u00a0minút',
			},
			{
				name: '_hjClosedSurveyInvites',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Prideľuje sa vo chvíli, keď používateľ vykoná akciu s\u00a0oknom dotazníka.',
				category: 'Analytické',
				period: '365\u00a0dní',
			},
			{
				name: '_hjDonePolls',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Prideľuje sa vo chvíli, keď používateľ dokončí dotazník cez widget na Internetových stránkach.',
				category: 'Analytické',
				period: '365\u00a0dní',
			},
			{
				name: '_hjMinimizedPolls',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Prideľuje sa vo chvíli, keď používateľ minimalizuje widget na Internetových stránkach.',
				category: 'Analytické',
				period: '365\u00a0dní',
			},
			{
				name: '_hjShownFeedbackMessage',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Prideľuje sa vo chvíli, keď používateľ minimalizuje alebo dokončí spätnú väzbu.',
				category: 'Analytické',
				period: '365\u00a0dní',
			},
			{
				name: '_hjSessionTooLarge',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Aktivuje sa vo chvíli, keď je veľkosť dát v\u00a0Session príliš veľká.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: '_hjSessionRejected',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Prideľuje sa vo chvíli, keď externý server odmietol komunikáciu z\u00a0dôvodu preťaženosti.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: '_hjSessionResumed',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Prideľuje sa vo chvíli, keď bolo po výpadku obnovené spojenie so serverom.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: '_hjid',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Prideľuje jednoznačné ID každému návštevníkovi stránok.',
				category: 'Analytické',
				period: '365\u00a0dní',
			},
			{
				name: '_hjRecordingLastActivity',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Používa sa na sledovanie akcií užívateľov.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: '_hjTLDTest',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Používa sa v\u00a0prípade, že je cookies nutné zdieľať medzi subdoménami.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: '_hjUserAttributesHash',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Enkryptované atribúty návštevníkov posielané cez Hotjar API.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: '_hjCachedUserAttributes',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Uložené enkryptované atribúty návštevníkov.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: '_hjLocalStorageTest',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Slúži na určenie, či Hotjar Tracking Script môže použiť lokálne úložisko.',
				category: 'Analytické',
				period: 'Menej ako 100\u00a0ms',
			},
			{
				name: '_hjIncludedInPageviewSample',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Slúži na určenie, či je užívateľ súčasťou trackovacej vzorky podľa zobrazenia stránok.',
				category: 'Analytické',
				period: '30\u00a0minút',
			},
			{
				name: '_hjIncludedInSessionSample',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Slúži na určenie, či je užívateľ súčasťou trackovacej vzorky podľa obmedzenia dĺžky Session.',
				category: 'Analytické',
				period: '30\u00a0minút',
			},
			{
				name: '_hjAbsoluteSessionInProgress',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Identifikuje prvú Session daného užívateľa podľa zobrazenia stránok.',
				category: 'Analytické',
				period: '30\u00a0minút',
			},
			{
				name: '_hjFirstSeen',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Identifikuje prvú Session daného užívateľa.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: '_hjViewportId',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Ukladá informácie o\u00a0viewporte užívateľa, napr. rozlíšenie a\u00a0veľkosť.',
				category: 'Analytické',
				period: 'Session',
			},
			{
				name: '_hjRecordingEnabled',
				domain: 'fondee.sk',
				party: 'Prvé strany',
				purpose: 'Používa sa na kontrolu, tu je návštevník už súčasťou nejakého sledovania alebo nie.',
				category: 'Analytické',
				period: 'Session',
			},
		],
	},
	options: {
		title: 'Vaše možnosti pri správe súborov cookie',
		text: '<0>Pri návšteve Internetových stránok sa Vám zobrazí tzv.\u00a0cookie banner s\u00a0možnosťou nastavenia '
			+ 'voliteľných cookies a\u00a0s\u00a0možnosťou ich prijatia alebo odmietnutia. V\u00a0rámci cookie banneru máte '
			+ 'možnosť zvoliť si svoje preferencie a\u00a0potvrdiť ich výber, ktorý môžete kedykoľvek zmeniť nižšie na\u00a0tejto stránke.</0>'
			+ '<0>Cookies môžete taktiež zakázať zmenou nastavenia Vášho prehliadača, ktoré umožňuje odmietnuť používanie všetkých alebo '
			+ 'len vybraných cookies. Radi by sme Vás však upozornili, že pokiaľ nastavíte prehliadač tak, že vypnete všetky cookie '
			+ 'vrátane potrebných, nemusí sa Vám podariť získať prístup na\u00a0Internetové stránky a/alebo môže byť obmedzená ich funkčnosť.</0>'
			+ '<0>Zakázaním cookies sa cookies z\u00a0prehliadača neodstránia a\u00a0je potrebné ich odstrániť nezávisle v\u00a0prehliadači. '
			+ 'Rovnako tak môžete odstrániť doterajšie cookies, ktoré sú už umiestnené v\u00a0 úložisku Vášho koncového zariadenia.</0>'
			+ '<0>Prostredníctvom nastavenia Vášho prehliadača môžete povoliť všetky súbory cookie, blokovať všetky súbory cookie'
			+ ' alebo len súbory cookie tretích strán a\u00a0taktiež môžete požadovať upozornenia týkajúce sa ukladaných cookies. '
			+ 'Podrobnejšie informácie o\u00a0nastavení cookies sú k\u00a0 dispozícii priamo v\u00a0nastavení Vášho prehliadača, prípadne, '
			+ 'prosím nahliadnite do pomocníka Vášho prehliadača.</0>',
	},
	management: {
		title: 'Správa cookies tretích strán',
		text: '<0>Pre podrobnejšie informácie o\u00a0tom, ako sú spravované a\u00a0nastavené cookies tretích strán, '
			+ 'odporúčame prečítať príslušné zásady používania cookies:</0>',
		list: [
			{
				title: 'Youtube',
				link: 'https://www.youtube.com/account_privacy',
			},
			{
				title: 'Hotjar',
				link: 'https://www.hotjar.com/legal/policies/privacy/',
			},
			{
				title: 'Google Analytics',
				link: 'https://www.cookiepolicygenerator.com/cookies-policy-google-analytics/',
			},
			{
				title: 'Live Agent',
				link: 'https://support.liveagent.com/709382-Cookies-used-by-LiveAgent',
			},
			{
				title: 'Facebook',
				link: 'https://www.facebook.com/policy/cookies/',
			},
			{
				title: 'LinkedIn',
				link: 'https://cz.linkedin.com/legal/cookie-policy',
			},
			{
				title: 'Google',
				link: 'https://policies.google.com/technologies/cookies?hl=sk',
			},
			{
				title: 'Seznam',
				link: 'https://www.seznam.cz/reklama/cz/obsahovy-web/pravidla-reklamy/gdpr/#data-ktera-jsou-potrebna',
			},
		],
	},
	final: {
		title: 'Záverečné ustanovenia',
		text: '<0>Informácie o\u00a0spracovaní osobných údajov sú k\u00a0dispozícii <0>tu</0>.</0>'
			+ '<0>S\u00a0ohľadom na to, že svet informačných technológií sa stále vyvíja, vyhradzujeme si právo kedykoľvek tieto '
			+ 'Zásady zmeniť.</0>'
			+ '<0>Ak máte akýkoľvek dotaz v\u00a0súvislosti so súbormi cookie, obráťte sa na nás na\u00a0<1>info@fondee.sk</1>.</0>',
	},
	edit: 'Nastavenie cookies',
};

const dashboard = {
	filter: {
		title: 'Oceňovanie pre',
		options: {
			day: 'deň',
			week: 'týždeň',
			month: 'mesiac',
			threemonths: '3 mesiace',
			sixmonths: '6 mesiacov',
			year: 'rok',
			fromstart: 'od začiatku',
		},
	},
	chart: {
		loadmore: 'Načítať ďalšie',
		loadall: 'Načítať všetko',
		toggle: {
			value: 'Hodnota',
			evaluation: 'Hodnotenie',
		},
		tooltip: {
			value: 'Hodnota',
			evaluation: 'Pohyb hodnoty portfólia',
			nonGrowthPlus: 'Vklady/bonusy',
			nonGrowthMinus: 'Výbery/prevody medzi portfóliami',
			growthPlus: 'Dividendy/výnosy',
			growthMinus: 'Poplatky',
		},
	},
};

const documents = {
	title: 'Dokumenty',
	subtitle: 'Zmluvy a\u00a0iné dokumenty',
	secondTitle: 'Povinne uverejňované informácie',
	reportsTitle: 'Výročné správy',
	esgTitle: 'Informácie súvisiace s\u00a0udržateľnosťou',
	otherTitle: 'Iné dokumenty',
	contract: 'Podpísaná zmluva s\u00a0Fondee',
	conditions: 'Obchodné podmienky',
	terms: 'Pravidlá používania webových stránok',
	personalInfoAgreement: 'Ochrana osobných údajov',
	informationSheet: 'Informácie pre zákazníkov',
	agreementTemplate: {
		standard: 'Vzorová zmluva v\u00a0slovenčine',
		child: 'Vzorová zmluva v\u00a0slovenčine - neplnoletí',
		pension: 'Vzorová zmluva v\u00a0slovenčine',
	},
	agreementTemplateCzech: {
		standard: 'Vzorová zmluva v\u00a0češtine',
		child: 'Vzorová zmluva v\u00a0češtine - neplnoletí',
		pension: 'Vzorová zmluva v\u00a0češtine',
	},
	complaintsProcedure: 'Reklamačný poriadok',
	annualReport2021: '2021 Výročná správa',
	annualReportTemplates2021: '2021 Šablóny na uverejňovanie informácií OCP',
	annualReport2022: '2022 Výročná správa',
	annualReportTemplates2022: '2022 Šablóny na uverejňovanie informácií OCP',
	annualReport2023: '2023 Výročná správa',
	annualReportTemplates2023: '2023 Šablóny na uverejňovanie informácií OCP',
	esgInfolist: 'ESG infolist',
	esgSustainability: 'Vyhlásenie o\u00a0politikách udržateľnosti a\u00a0nezohľadňovanie nepriaznivých vplyvov investičných rozhodnutí',
	legalRepresentativeDeclaration: 'Vyhlásenie zákonného zástupcu',
	pensionConfirmation: 'Potvrdenie pre zamestnávateľov',
	tradingVenues: 'Zoznam prevodných miest',
	ordersExecution: 'Zhrnutie a\u00a0závery analýzy kvality vykonávanie pokynov za\u00a0rok 2023',
};

const documentUrls = {
	conditions: `${process.env.PUBLIC_URL}/files/sk/SK_Fondee_Obchodne_podmienky.pdf`,
	terms: `${process.env.PUBLIC_URL}/files/sk/SK_Fondee_Podmienky_použivania_webovych_stranok.pdf`,
	personalInfoAgreement: `${process.env.PUBLIC_URL}/files/sk/SK_Fondee_GDPR.pdf`,
	informationSheet: `${process.env.PUBLIC_URL}/files/sk/SK_Fondee_informacny_list.pdf`,
	agreementTemplate: {
		standard: `${process.env.PUBLIC_URL}/files/sk/SK_Fondee_Zmluva.pdf`,
		child: `${process.env.PUBLIC_URL}/files/sk/SK_Fondee_zmluva_neplnoleti.pdf`,
		pension: `${process.env.PUBLIC_URL}/files/sk/SK_Fondee_Zmluva.pdf`,
	},
	agreementTemplateCzech: {
		standard: `${process.env.PUBLIC_URL}/files/Fondee_Smlouva-vzor.pdf`,
		child: `${process.env.PUBLIC_URL}/files/Fondee_smlouva_nezletili.pdf`,
		pension: `${process.env.PUBLIC_URL}/files/Fondee_Smlouva-vzor.pdf`,
	},
	complaintsProcedure: `${process.env.PUBLIC_URL}/files/sk/SK_Fondee_Reklamacny_poriadok.pdf`,
	annualReport2021: `${process.env.PUBLIC_URL}/files/Fondee_vyrocni_zprava_2021.pdf`,
	annualReportTemplates2021: `${process.env.PUBLIC_URL}/files/Fondee_sablony_pro_uverejnovani_informaci_OCP_2021.xlsx`,
	annualReport2022: `${process.env.PUBLIC_URL}/files/Fondee_vyrocni_zprava_2022.pdf`,
	annualReportTemplates2022: `${process.env.PUBLIC_URL}/files/Fondee_sablony_pro_uverejnovani_informaci_OCP_2022.xlsx`,
	annualReport2023: `${process.env.PUBLIC_URL}/files/Fondee_vyrocni_zprava_2023.pdf`,
	annualReportTemplates2023: `${process.env.PUBLIC_URL}/files/Fondee_sablony_pro_uverejnovani_informaci_OCP_2023.xlsx`,
	esgInfolist: `${process.env.PUBLIC_URL}/files/sk/SK_Fondee_ESG_infolist.pdf`,
	esgSustainability: `${process.env.PUBLIC_URL}/files/sk/SK_Fondee_Vyhlasenie_o_nezohladnovani.pdf`,
	feesIllustratedUrl: `${process.env.PUBLIC_URL}/files/sk/Fondee_Ilustrativny_vplyv_poplatkov.pdf`,
	tradingVenues: `${process.env.PUBLIC_URL}/files/Seznam_prevodnich_mist.pdf`,
	ordersExecution: `${process.env.PUBLIC_URL}/files/Fondee_zavery_analyzy_provadeni_pokynu_2023.pdf`,
	priceList: `${process.env.PUBLIC_URL}/files/sk/SK_Fondee_cennik.pdf`,
};

const email = {
	address: 'info@fondee.sk',
};

const emailUs = {
	title: 'Píšte na',
};

const emailVerification = {
	default: {
		title: 'Potvrdenie e-mailovej adresy',
		text: 'Na e-mailovú adresu sme odoslali potvrdzovací e\u2011mail. Pokračujte, prosím, v\u00a0registrácii cez '
			+ 'odkaz uvedený v\u00a0maile. Je to aj pre Vašu bezpečnosť, aby sme si boli istí, že zadaná adresa je '
			+ 'skutočne Vaša.',
		listTitle: 'E-mail nedorazil, čo s tým?',
		list: '<0>pozrite do spamu</0>'
			+ '<0>nevlúdil sa preklep?</0>'
			+ '<0>skúste registráciu znova</0>'
			+ '<0>ani jedno nepomohlo? Napíšte nám na <0>info@fondee.sk</0> alebo na chatu.</0>',
	},
	response: {
		button: 'Pokračovať v\u00a0registrácii',
		title: {
			error: 'E-mailová adresa nebola overená.',
			success: 'E-mailová adresa bola úspešne overená.',
		},
		content: {
			error: 'Nepodarilo sa overiť e-mailovú adresu. Skúste aktualizovať stránku a\u00a0v\u00a0prípade pretrvávajúcich problémov nás kontaktujte na <0>$t(email.address)</0>.',
			success: 'Ďakujeme za overenie. Teraz môžete pokračovať v\u00a0registrácii.',
		},
	},
};

const error = {
	title: 'Niečo sa nepodarilo',
	subtitle: 'Ospravedlňujeme sa, na stránke došlo k chybe.',
	link: 'Späť na hlavnú stránku',
};

const error404 = {
	title: '404',
	subtitle: 'Ospravedlňujeme sa, ale požadovaná stránka sa nenašla.',
	link: 'Späť na hlavnú stránku',
};

const faq = {
	title: 'Ako a\u00a0kedy prebieha investovanie',
	search: 'Nájsť otázku…',
};

const feesChart = {
	comparisonAnchor: 'Porovnanie poplatkov',
	title: 'Ako sme na tom v\u00a0porovnaní<0></0>s\u00a0podielovým fondom?',
	subtitle: 'Fondee vám ušetrí desiatky stoviek',
	info: '<0>Príklad: Pri počiatočnej investícii 5\u00a0000 € na poplatkoch <0>za 15\u00a0rokov ušetríte cez 2\u00a0000\u00a0€!</0></0>'
		+ '<0>Počítame s\u00a0priemerným ročným poplatkom <0>1,09\u00a0%</0> vo Fondee a\u00a0<0>2,7\u00a0%</0> pri podielovom fonde.</0>'
		+ '<0>Predpokladáme priemerný ročný výnos akciového portfólia <0>7\u00a0%</0> u\u00a0oboch. Po 15\u00a0rokoch máte '
		+ 'vo\u00a0Fondee o\u00a02\u00a0600\u00a0€ viac než v\u00a0podielovom fonde!</0>'
		+ '<0>Pokiaľ vás zaujíma detailnejší vplyv poplatkov na\u00a0zhodnotenie, môžete sa pozrieť <1>sem</1>.</0>',
	chart: {
		currencySuffix: '\u00a0EUR',
		currencyThousandsSuffix: '\u00a0tis.\u00a0EUR',
		years_one: '{{ count }} rok',
		years_few: '{{ count }} roky',
		years_other: '{{ count }} rokov',
		curveLabel: 'podielové fondy',
	},
};

const feesComparison = {
	title: 'Porovnanie poplatkov v\u00a0akciovom portfóliu',
	footerNote: '',
	footerTextNote1: 'Porovnanie za január 2025',
	footerTextNote2: 'Hodnoty sme prevzali z Kľúčových informácii pre investorov dostupných na webových stránkach '
		+ 'jednotlivých fondov. Vstupné a\u00a0výstupné poplatky môžu byť nižšie alebo nulové, pokiaľ je aktuálny cenník '
		+ 'daného distribútora nižší než poplatky deklarované v\u00a0Kľúčových informáciách pre investorov.',
	footerTextNotePart2: 'Konkrétne sa priemerná ročná nákladovosť vypočíta nasledovne: celková ročná nákladovosť '
		+ 'za posledný rok podľa Kľúčových informácií pre investorov +\u00a0(vstupný +\u00a0výstupný poplatok '
		+ 'relevantný pre dané obdobie) /\u00a0počet rokov investovania.',
	footerTextNote3: 'Výpočet vychádza z hodnoty 4 000 € a\u00a0predpokladá, že klient má túto hodnotu portfólia po celú '
		+ 'dobu investície. V\u00a0reáli táto hodnota môže ísť hore alebo dole, a\u00a0podľa toho sa budú poplatky '
		+ 'relatívne zvyšovať alebo znižovať.',
	footerTextNotePart3: 'Konkrétne sa poplatky podielových fondov vypočítajú nasledovne: 4\u00a0000\u00a0€ *\u00a0Priemerná ročná '
		+ 'nákladovosť *\u00a0Počet rokov investovania.',
	footerTextNote4: 'Tiež sa vám zdá, že je tu absurdne veľa hviezdičiek? Pri podielových fondoch to tak býva. '
		+ 'Vždy si skontrolujte všetky podmienky služby!',
};

const feesHero = {
	title: 'Poplatky? Nižšie, ako by ste čakali…',
	description: '…a ak viete odinakiaľ. O\u00a0to viac peňazí pre vás! S Fondee presne viete, koľko platíte.',
	info: 'Ročné poplatky, ktoré si účtujú <0>ETFka</0>, sa líšia podľa zloženia portfólia. V\u00a0priemere sú 0,15\u00a0%.',
	anchor: 'Porovnanie s\u00a0podielovým fondom',
	period: 'ročne',
	list: [
		{
			title: 'Poplatky Fondee',
			value: '0,9\u00a0%',
		},
		{
			title: 'Poplatky za <0>ETF</0> v\u00a0priemere',
			value: '0,15\u00a0%',
		},
		{
			title: 'Celkové priemerné poplatky',
			value: '1,05\u00a0%',
		},
	],
	benefitsList: [
		'Žiadne vstupné poplatky',
		'Žiadne skryté poplatky',
		'Žiadne poplatky za bankára',
		'Žiadne provízie poradcom',
	],
};

const feesProducts = {
	title: 'Koľko stojí investovanie cez Fondee?',
	toggleOptions: [
		{
			label: 'Klasické',
			value: 'classic',
		},
		{
			label: 'ESG',
			value: 'esg',
		},
		{
			label: 'Detské',
			value: 'child',
		},
		{
			label: 'Dôchodkové',
			value: 'pension',
		},
		{
			label: 'Študentské',
			value: 'student',
		},
	],
	products: {
		child: {
			title: 'Detské účty',
			text: 'Ako zabezpečiť bohatšiu budúcnosť pre deti? Nenechajte úspory ležať v\u00a0banke, kde strácajú hodnotu. '
				+ 'Založte detský investičný účet <0>so zníženým poplatkom</0>.',
			button: 'Chcem detský účet',
			transparent: {
				fondee: '0,2\u00a0%',
				vat: '0,04\u00a0%',
				total: '0,24\u00a0%',
				description: '<0>Poplatok Fondee <0>0,2\u00a0%</0> ročne</0>'
					+ '<1>Náklady na investovanie pre deti sme znížili na minimum. Poplatok zahŕňa kompletnú správu detského portfólia.</1>'
					+ '<0>DPH 0,04\u00a0% ročne</0>'
					+ '<1>Podľa zákona naša činnosť spadá pod finančné služby platiace DPH.</1>'
					+ '<1>Poplatok je zaokrúhlený, presnú sadzbu nájdete v\u00a0<0>cenníku</0>.</1>',
			},
		},
		classic: {
			title: 'Na mieru',
			text: 'Investujte <0>do stoviek akcií a\u00a0dlhopisov z\u00a0celého sveta</0> cez ETF\u00a0– fondy obchodované na burze. '
				+ 'Jednoducho, automatizovane a\u00a0zrozumiteľne.',
			button: 'Chcem klasické portfólio',
			transparent: {
				fondee: '0,9\u00a0%',
				vat: '0,19\u00a0%',
				total: '1,09\u00a0%',
				description: '<0>Poplatok Fondee <0>0,9\u00a0%</0> ročne</0>'
					+ '<1>S Fondee vás nič neprekvapí. Účtujeme iba jeden poplatok, ktorý zahŕňa kompletnú správu vašich '
					+ 'investícii. Investujte lacnejšie než cez podielové fondy!</1>'
					+ '<0>DPH 0,19\u00a0% ročne</0>'
					+ '<1>Podľa zákona naša činnosť spadá pod finančné služby platiace DPH.</1>'
					+ '<1>Poplatok je zaokrúhlený, presnú sadzbu nájdete v\u00a0<0>cenníku</0>.</1>',
			},
		},
		esg: {
			title: 'ESG',
			text: 'Investujte udržateľne s\u00a0ESG portfóliami, ktoré v\u00a0posledných rokoch porážajú vo výnosnosti '
				+ 'klasické investície. Uvidíte, že <0>hodnoty a\u00a0výnosy si rozumejú</0>.',
			button: 'Chcem ESG portfólio',
			transparent: {
				fondee: '0,9\u00a0%',
				vat: '0,19\u00a0%',
				total: '1,09\u00a0%',
				description: '<0>Poplatok Fondee <0>0,9\u00a0%</0> ročne</0>'
					+ '<1>S Fondee vás nič neprekvapí. Účtujeme iba jeden poplatok, ktorý zahŕňa kompletnú správu '
					+ 'vašich investícii. Investujte lacnejšie než cez podielové fondy!</1>'
					+ '<0>DPH 0,19\u00a0% ročne</0>'
					+ '<1>Podľa zákona naša činnosť spadá pod finančné služby platiace DPH.</1>'
					+ '<1>Poplatok je zaokrúhlený, presnú sadzbu nájdete v\u00a0<0>cenníku</0>.</1>',
			},
		},
		pension: {
			title: 'Penzijné účty',
			text: 'Vezmite budúcnosť do vlastných rúk a\u00a0dajte zbohom drahým penzijným sporeniam s\u00a0mizernými výnosmi. Otvorte si penzijné portfólio <0>so zvýhodneným poplatkom</0>.',
			button: 'Chcem penzijné portfólio',
			transparent: {
				fondee: '0,5\u00a0%',
				vat: '0,11\u00a0%',
				total: '0,61\u00a0%',
				description: '<0>Poplatok Fondee 0,5\u00a0% ročne</0>'
					+ '<1>Investícia na dôchodok lacnejšia než v\u00a0banke? Máte ju mať! Zvýhodnený poplatok zahŕňa kompletnú správu penzijného portfólia.</1>'
					+ '<0>DPH 0,11\u00a0% ročne</0>'
					+ '<1>Podľa zákona naša činnosť spadá pod finančné služby platiace DPH.</1>'
					+ '<1>Poplatok je zaokrúhlený, presnú sadzbu nájdete v\u00a0<0>cenníku</0>.</1>',
			},
		},
		student: {
			title: 'Študentské účty',
			text: 'Využite čas vo svoj prospech! Investujte už pri škole cez Fondee účet pre študentov '
				+ '<0>so zníženým poplatkom</0>. Stačí potvrdenie o\u00a0štúdiu alebo ISIC a\u00a0je váš až do 26\u00a0rokov.',
			button: 'Chcem študentský účet',
			transparent: {
				fondee: '0,5\u00a0%',
				vat: '0,11\u00a0%',
				total: '0,61\u00a0%',
				description: '<0>Poplatok Fondee 0,5\u00a0% ročne</0>'
					+ '<1>Jedno potvrdenie o\u00a0štúdiu, alebo kartička ISIC a\u00a0až do 26.\u00a0narodenín investujete s\u00a0poplatkom '
					+ 'o\u00a0pol percenta nižším než v\u00a0„dospeláckych“ portfóliách.</1>'
					+ '<0>DPH 0,11\u00a0% ročne</0>'
					+ '<1>Podľa zákona naša činnosť spadá pod finančné služby platiace DPH.</1>'
					+ '<1>Poplatok je zaokrúhlený, presnú sadzbu nájdete v\u00a0<0>cenníku</0>.</1>',
			},
		},
	},
	transparent: {
		title: 'Poplatky transparentne:',
		fondee: 'Poplatok Fondee',
		vat: 'DPH',
		total: 'Celkový poplatok',
	},
};

const findOutInfoBox = {
	text: 'Zistite, ktorý investičný profil zodpovedá Vášmu prístupu k\u00a0riziku. '
		+ 'Vyhodnotenie je v\u00a0investičnom dotazníku hneď v\u00a0prvom kroku registrácie.',
	button: 'Chcem vyskúšať',
};

const footer = {
	primary: {
		nav: {
			title: 'Informácie',
			homepage: 'Úvod',
			howItWorks: 'Ako to funguje',
			ourApproach: 'Náš prístup',
			fees: 'Poplatky',
			faq: 'Časté otázky',
			contact: 'Spýtajte sa',
			documents: 'Dokumenty',
			cookies: 'Cookies',
			blog: 'Blog',
			privacyPolicy: 'Ochrana osobných údajov',
		},
		products: {
			title: 'Produkty',
			classic: 'Klasické portfóliá',
			esg: 'ESG portfóliá',
			children: 'Detské účty',
			pension: 'Penzijné portfóliá',
			student: 'Študentské účty',
		},
		contact: {
			title: 'Chcete sa opýtať?',
		},
		app: 'Stiahnuť mobilnú aplikáciu',
		googlePlay: 'Teraz na\u00a0Google Play',
		appStore: 'Stiahnuť v\u00a0App Store',
		socialUrl: {
			facebook: 'https://www.facebook.com/FondeeSK',
			instagram: 'https://www.instagram.com/fondee_sk/',
			linkedin: 'https://www.linkedin.com/company/fondeecz/',
			twitter: 'https://twitter.com/fondee_cz',
		},
	},
	secondary: {
		title: 'Fondee\u00a0a.s.',
		info: 'Hodnota investície môže v\u00a0priebehu času kolísať, návratnosť investovanej čiastky nie je zaručená. S '
			+ 'investíciami sú spojené riziká. V\u00a0prípade investícii s Fondee sa investor vystavuje predovšetkým trhovým '
			+ 'rizikám spočívajúcich v\u00a0možnosti nepriaznivého vývoja cien aktív (akcií, dlhopisov, atď.), úverovým rizikám, '
			+ 'riziku inflácie a\u00a0rizikám nepriaznivého vývoja menových kurzov.',
		address: 'IČ\u00a006691862<0></0>'
			+ 'Rumunská\u00a022/28,<0></0>'
			+ 'Vinohrady\u00a0-\u00a0Praha\u00a02, 120\u00a000',
		legal: 'Fondee\u00a0a.s. je zapísaná v\u00a0Obchodnom registri vedenom na Mestskom súde '
			+ 'v\u00a0Prahe, spisová značka B\u00a027322',
		love: 'Made with\u00a0<0></0>\u00a0Fondee',
	},
};

const footerCta = {
	title: 'Budúcnosť? Urobte si vlastnú!',
	text: 'Nechajte svoje peniaze pracovať pre vás.',
	button: 'Chcem investovať ',
};

const forgotPassword = {
	title: 'Obnoviť zabudnuté heslo',
	form: {
		text: 'Po zadaní e-mailovej adresy vám zašleme odkaz na obnovu hesla.',
		button: 'Zaslať odkaz',
		success: 'Na Vašu e-mailovú adresu bol práve odoslaný odkaz na resetovanie starého hesla.',
	},
	reset: 'Zmeniť heslo',
};

const forms = {
	error: 'Niečo sa nepodarilo. Skúste to prosím znovu.',
	success: 'Uložené',
	save: 'Uložiť',
	fields: {
		email: {
			label: 'E-mail',
			helper: 'Sľubujeme, že nebudeme posielať nezmyselný spam',
			error: 'Zabudli ste vyplniť svoju e-mailovú adresu.',
			invalid: 'Zadaná e-mailová adresa je neplatná. Skontrolujte si, či ste v\u00a0e-maile neurobili preklep, či nechýba znak @ apod.',
			unique: 'Tento e-mail je už v\u00a0našej databázi zaregistrovaný. Prosíme, použite inú e-mailovú adresu.',
			closed: 'Váš účet bol zrušený. Pokiaľ chcete účet obnoviť, obrátťe sa prosím na <0>$t(email.address)</0>.',
		},
		esgPreferenceDeclaration: {
			error: 'Prosím zaškrtnite toto políčko',
		},
		password: {
			label: 'Heslo',
			helper: 'Minimálne 8 znakov, jedno veľké a\u00a0malé písmeno a\u00a0číslo',
			error: 'Zabudli ste vyplniť svoje heslo.',
			incorrect: 'Bohužiaľ, toto heslo nie je správne.',
			invalid: 'Zadané heslo nespĺňa podmienky.',
		},
		passwordConfirm: {
			label: 'Potvrdenie hesla',
			error: 'Zabudli ste vyplniť potvrdenie hesla.',
			invalid: 'Zadané heslá sa nezhodujú.',
		},
		oldPassword: {
			label: 'Pôvodné heslo',
			error: 'Zabudli ste vyplniť pôvodné heslo.',
		},
		newPassword: {
			label: 'Nové heslo',
			error: 'Zabudli ste vyplniť nové heslo.',
		},
		firstName: {
			label: 'Meno',
			error: 'Prosím, zadajte Vaše krstné meno.',
			warning: 'Uistite sa, že zadané meno je správne. Pokiaľ áno, pokračujte ďalej.',
		},
		lastName: {
			label: 'Priezvisko',
			error: 'Prosím, zadajte Vaše priezvisko.',
			warning: 'Uistite sa, že zadané priezvisko je správne. Pokiaľ áno, pokračujte ďalej.',
		},
		fullName: {
			label: 'Meno',
			error: 'Prosím, zadajte Vaše meno.',
		},
		phone: {
			label: 'Telefón',
			error: 'Prosím, nezabudnite vyplniť Váš telefón.',
			invalid: 'Zadané telefónne číslo mý chybný formát.',
		},
		phoneNumber: {
			label: 'Telefón',
			helper: 'Zadajte bez medzier',
			error: 'Zabudli ste vyplniť telefónne číslo.',
			invalid: 'Zadané telefónne číslo je neplatné. Skontrolujte si prosím, či ste ho napísali správne a\u00a0bez medzier.',
			warning: 'Uistite sa prosím, že zadané číslo je správne.',
		},
		hasReferenceCode: {
			label: 'Mám referenčný kód',
		},
		referenceCode: {
			label: 'Referenčný kód',
			error: 'Zabudli ste vyplniť Váš referenčný kód.',
			invalid: 'Tento referenčný kód sme v\u00a0našej databáze nenašli. Prosíme, skontrolujte si, že ste napísali správne referenčný kód.',
		},
		personalInfoAgreed: {
			label: 'Súhlasím so spracovaním <0>osobných údajov</0>',
			error: 'Pre pokračovanie je nutné zaškrtnúť, že súhlasite so spracovaním osobných údajov.',
		},
		conditionsAgreed: {
			label: 'Súhlasím s\u00a0<0>obchodnými podmienkami</0> Fondee',
			error: 'Pre pokračovanie je nutné zaškrtnúť, že ste boli oboznámený s\u00a0podmienkami podnikania Fondee.',
		},
		streetName: {
			label: 'Ulica',
			error: 'Zabudli ste vyplniť ulicu',
			warning: 'Skontrolujte si prosím, že ulica je správne.',
		},
		streetNumber: {
			label: 'Č.\u00a0p./č.\u00a0o.',
			error: 'Zabudli ste vyplniť číslo domu.',
			warning: 'Skontrolujte si, že číslo domu je správne.',
		},
		city: {
			label: 'Mesto',
			error: 'Zabudli ste vyplniť mesto.',
		},
		postalCode: {
			label: 'PSČ',
			nonNumeric: 'Nesprávny formát. Uveďte iba číslice.',
		},
		country: {
			label: 'Krajina',
			error: 'Zabudli ste vyplniť krajinu.',
		},
		personalIdentificationNumber: {
			label: 'Rodné číslo',
			error: 'Nesprávny formát. Uveďte iba číslice a\u00a0bez lomítka.',
			warning: 'Skontrolujte si prosím, že zadané rodné číslo je správne.',
		},
		birthDate: {
			label: 'Dátum narodenia',
			nonNumeric: 'Nesprávny formát. Uveďte iba číslice.',
			minority: 'Dieťaťu už bolo 18, je potrebné vytvoriť samostatnú registráciu',
			majority: 'Ešte vám nebolo 18, registráciu za Vás musí dokončiť zákonný zástupca',
			day: {
				label: 'Deň',
				invalid: 'Uveďte správne číslo dňa.',
			},
			month: {
				label: 'Mesiac',
				invalid: 'Uveďte správne číslo mesiaca.',
			},
			year: {
				label: 'Rok',
				invalid: 'Uveďte správne číslo roka.',
			},
		},
		placeOfBirth: {
			label: 'Miesto narodenia',
			error: 'Nesprávny formát. Uveďte iba písmená alebo číslice.',
			warning: 'Skontrolujte si prosím, že zadané miesto narodenia je správne.',
		},
		bankAccount: {
			label: 'Bankový účet',
			labelPrefix: 'Predčíslie',
			labelAccount: 'Číslo účtu',
			labelCode: 'Kód banky',
			errorAccount: 'Zabudli ste vyplniť číslo účtu.',
			errorCode: 'Zabudli ste vyplniť kód banky.',
			invalidCode: 'Neplatný kód banky.',
			length: 'Skontrolujte si prosím, že číslo účtu má 26\u00a0znakov.',
			nonNumeric: 'Nesprávny formát. Uveďte iba číslice.',
		},
		bankAccountIBAN: {
			label: 'IBAN',
			error: 'Zabudli ste vyplniť číslo IBAN účtu.',
			invalid: 'Číslo IBAN účtu smie obsahovať iba číslice a\u00a0písmená. Nemalo by prekročiť dĺžku 34\u00a0znakov.',
			warning: 'Skontrolujte si prosím, že číslo IBAN účtu má 24\u00a0znakov.',
		},
		statementPeriodicity: {
			monthly: 'Mesačne',
			quarterly: 'Štvrťročne',
			yearly: 'Ročne',
			none: 'Nikdy',
		},
		survey: {
			label: 'Zobrazovať dotazníky v\u00a0klientskej zóne',
		},
		marketingEmailsUnsubscribe: {
			label: 'Odhlásiť sa z\u00a0odberu marketingových e-mailov',
		},
		nationality: {
			label: 'Štátna príslušnosť',
			error: 'Zabudli ste vyplniť štátnu príslušnosť.',
		},
		rebalancingActive: {
			label: 'Zapnúť možnosť automatického rebalansovania portfólia',
		},
		toggle: {
			local: 'Lokálne č. účtu',
			iban: 'IBAN',
		},
		address: {
			change: 'Adresa trvalého bydliska',
		},
		contactAddress: {
			label: 'Kontaktná adresa',
		},
		addressChange: {
			change: 'Chcem zmeniť adresu trvalého pobytu',
			confirm: 'Vyhlasujem, že mnou uvedená adresa je správna',
		},
		businessId: {
			warning: 'Zadané IČO je neplatné.',
		},
		incomeExpense: {
			warning: 'Skontrolujte si prosím, že zadaná čiastka je správne. Čiastka musí byť uvedená celým číslom v\u00a0EUR (nie napr. v\u00a0tisícoch).',
		},
		numberISIC: {
			format: 'Nesprávny formát.',
		},
		child: {
			firstName: {
				label: 'Meno dieťaťa',
				error: 'Prosím, vyplňte meno dieťaťa.',
				warning: 'Skontrolujte si prosím, že zadané meno dieťaťa je správne.',
			},
			lastName: {
				label: 'Priezvisko dieťaťa',
				error: 'Prosím, vyplňte priezvisko dieťaťa.',
				warning: 'Skontrolujte si prosím, že zadané priezvisko dieťaťa je správne.',
			},
			email: {
				label: 'E-mail (nepovinné)',
				unique: 'Zadaný e-mail už v našej databáze evidujeme u iného užívateľa. Pokiaľ dieťa vlastný e-mail nemá, môžete pole nechať prázdne.',
				warning: 'Skontrolujte si prosím, že zadaný e-mail je správne.',
			},
			phone: {
				label: 'Telefón (nepovinné)',
				warning: 'Skontrolujte si prosím, že zadané telefónne číslo je správne.',
			},
			referenceCode: {
				label: 'Referenčný kód (nepovinné)',
				invalid: 'Tento referenčný kód sme v\u00a0našej databáze nenašli. Prosíme, skontrolujte si, že ste napísali správne referenčný kód.',
				tooltip: 'Upozorňujeme, že je možné zadať iba kódy na jednorazový peňažný bonus. Bonusy na odpustenie poplatkov sa riadia vždy podľa účtu rodiča a nie je možné ich tu zadávať.',
			},
		},
		file: {
			deleteAttachment: 'Zmazať prílohu',
			sizeError: 'Maximálna veľkosť súboru je {{ value }} MB.',
		},
	},
	twoFactorAuthentication: {
		title: 'Dvojfázové overenie',
		text: 'Zadajte overovací kód z\u00a0Vašej aplikácie',
		button: 'Zadať kód',
		error: 'Chybný kód',
	},
};

const general = {
	copied: 'Skopírované',
	esgWarningModal: {
		title: 'Upozornenie',
		text: 'Ponúkané ESG portfólia nemusia zodpovedať Vašim preferenciám.',
		button: 'Beriem na vedomie',
	},
};

const header = {
	blog: 'Blog',
	clientCheck: 'Pravidelné kontroly',
	contact: 'Spýtajte sa',
	dashboard: 'Prehľad',
	fees: 'Poplatky',
	history: 'História',
	howItWorks: 'Ako to funguje',
	login: 'Prihlásiť sa',
	logout: 'Odhlásiť sa',
	menu: 'Menu',
	myAccount: 'Môj účet',
	offers: 'Niečo naviac',
	onboarding: 'Dokončenie registrácie',
	ourApproach: 'O nás',
	ownPortfolio: 'Moje portfólio',
	portfolio: 'Moje portfólio',
	products: 'Produkty',
	productsList: {
		classicProduct: {
			title: 'Investícia na mieru',
			subTitle: 'Vhodná pre každého investora',
		},
		esgProduct: {
			title: 'Udržateľné investície',
			subTitle: 'Príroda je na prvom mieste',
		},
		childProduct: {
			title: 'Detské investičné účty',
			subTitle: 'Myslím na budúcnosť svojich detí',
		},
		pensionProduct: {
			title: 'Investícia na dôchodok',
			subTitle: 'Zabezpečte si starobu po svojom',
		},
		studentProduct: {
			title: 'Investície pre študentov',
			subTitle: 'Začnite už počas štúdia',
		},
		button: 'Späť',
	},
	referrals: 'Odporúčanie',
	register: 'Chcem investovať',
	signAgreement: 'Podpis zmluvy',
	signNewAgreement: 'Podpis zmluvy',
	taxes: 'Dane 2024',
};

const historyChart = {
	loading: 'Sťahujem dáta pre túto stránku',
	legend: {
		title: 'Legenda',
		evaluation: 'Kladné zhodnotenie',
		devaluation: 'Záporné zhodnotenie',
		value: 'Celková hodnota portfólia',
		increase: 'Vklad / Bonus / Dividenda',
		decrease: 'Výber / Poplatok',
	},
	tooltip: {
		value: 'Hodnota',
		evaluation: 'Pohyb hodnoty portfólia',
		nonGrowthPlus: 'Vklady/bonusy',
		nonGrowthMinus: 'Výbery/presuny medzi portfóliami',
		growthPlus: 'Dividendy/vratky',
		growthMinus: 'Poplatky',
	},
	toggle: {
		value: 'Hodnota',
		evaluation: 'Zhodnotenie',
	},
};

const historyFilter = {
	aggregation: 'Po',
	fromDate: 'Od',
	toDate: 'Do',
	last: 'Posledných ',
	days: 'dní',
	beginning: 'Počiatok',
	collapse: 'Zmeniť rozsah dát',
};

const historyOverview = {
	deposits: {
		title: 'Vklady',
		tooltip: 'Súčet všetkých vkladov a\u00a0prípadných odmien za vybrané\u00a0obdobie.',
	},
	withdrawals: {
		title: 'Výbery',
		tooltip: 'Súčet všetkých výberov za vybrané\u00a0obdobie.',
	},
	appreciation: {
		title: 'Zhodnotenie',
		tooltip: 'Vývoj hodnoty portfólia vo vybranom období očistené o\u00a0vklady, výbery a\u00a0odmeny.',
	},
};

const historyProgress = {
	title: 'Vývoj hodnoty portfólia',
	downloadPdf: 'Stiahnuť v\u00a0PDF',
	noResult: 'Nič na\u00a0zobrazenie',
	table: {
		columns: {
			date: 'Dátum',
			externalTransactionTotal: 'Vklad/výber/odmena',
			internalTransactionTotal: 'Bonus/poplatok/dividenda',
			value: 'Hodnota portfólia',
			valueTooltip: 'Hodnota portfólia predstavuje súhrnnú hodnotu držaných produktov a\u00a0hotovosti. '
				+ 'Hodnota ETF sa vypočítava '
				+ 'podľa cien ETF platných pre koniec daného obchodného dňa, prípadne najbližšej dostupnej ceny.',
			valueProgress: 'Pohyb hodnoty portfólia od predchádzajúceho dňa',
			valueProgressTooltip: 'Pohyb hodnoty portfólia vyjadruje zmenu hodnoty nakúpených produktov. '
				+ 'Pohyb hodnoty portfólia sa vypočítava ako rozdiel hodnoty '
				+ 'portfólia od predchádzajúceho dňa bez započítania vkladov, výberov, bonusov a\u00a0poplatkov.',
		},
		rows: {
			cashOver: 'Pomer hotovosti upravený na',
			for: 'za',
			investorProfile: 'Investičný profil:',
			skipRebalancing: 'Vypnutý rebalansing',
			transfer: 'Presun',
			fromPortfolio: 'z portfólia',
			toPortfolio: 'do portfólia',
			rate: 'Kurz:',
		},
		loadMore: 'Zobraziť ďalšie pohyby',
	},
};

const historyTransactions = {
	title: 'Prehľad transakcií',
	downloadPdf: 'Stiahnuť v\u00a0PDF',
	text: 'Podrobnosti o\u00a0spôsobe zobrazenia transakcií',
	textTooltip: 'Dátum uskutočnenia transakcie zodpovedá dátumu zaknihovania u\u00a0brokera.',
	noResult: 'Žiadne transakcie na\u00a0zobrazenie',
	filter: {
		placeHolder: 'Filter transakcií',
		noOption: 'Žiadne filtre',
	},
	table: {
		columns: {
			date: 'Dátum',
			type: 'Typ zmeny',
			change: 'Zmena',
		},
		rows: {
			more: 'Viac',
			hide: 'Skryť',
			trading: 'Obchodovanie',
			for: 'za',
			fromPortfolio: 'z portfólia',
			toPortfolio: 'do portfólia',
			rate: 'Kurz:',
		},
		loadMore: 'Zobraziť ďalšie pohyby',
	},
};

const homepageAbout = {
	title: 'Čo je to Fondee?',
	text: 'Chcete zhodnocovať svoje voľné peniaze v\u00a0akciách a\u00a0dlhopisoch? '
		+ 'Môžete si vybrať zo 7\u00a0modelových portfólií, ktoré združujú akcie a\u00a0dlhopisy rôzneho zamerania. '
		+ 'Potom stačí len poslať vklad, ktorý si želáte zainvestovať, a\u00a0my sa postaráme o\u00a0všetky príkazy '
		+ 'a\u00a0udržovanie portfólia podľa vami zvoleného modelového portfólia.',
	button: 'Chcem sa dozvedieť viac',
};

const homepageHero = {
	title: 'Na budúcnosť nenasporíte.',
	subtitle: 'Investujte!',
	text: 'Vyplňte investičný dotazník a\u00a0Fondee vám navrhne portfólio na mieru. Investujte automatizovane <0>už od 40 €</0>. '
		+ 'S nízkymi poplatkami a\u00a0kompletne online.',
	aboutButton: 'Zistiť viac',
	productsButton: 'Naše produkty',
	future: {
		title: 'Budúcnosť nepozná nikto. zaistiť sa môže každý.',
		subtitle: 'Na budúcnosť nestačí šetriť',
		text: 'Peniaze je potrebné zhodnocovať. S\u00a0Fondee môžete ľahko a\u00a0prehľadne zhodnocovať svoje nasporené peniaze.',
	},
};

const homepageJT = {
	text: '<0>Stojí za nami J&T Banka. Finančne podporila Fondee a\u00a0odovzdala nám cenné skúsenosti z odboru. Vďaka za dôveru!</0>'
		+ '<0>So silnou finančnou skupinou po boku môžeme investičnú platformu stále zlepšovať a\u00a0pracovať na nových '
		+ 'funkciách pre našich investorov.</0>',
};

const homepageProducts = {
	title: 'Ako zhodnotíte svoje peniaze?',
	button: 'Viac informácií',
	list: [
		{
			title: 'Investícia na mieru',
			text: '<0>Na budúcnosť nestačí sporiť. Avšak stačí jeden bankový prevod na Fondee a\u00a0investujete <1>do stoviek akcií '
				+ 'a dlhopisov z celého sveta</1> – jednorázovo alebo pravidelne a\u00a0už od 40 €.</0>'
				+ '<0>Žiadne zbytočné poplatky ani zložité podmienky. Podľa vašich cieľov a\u00a0postojov k riziku vám navrhneme '
				+ 'portfólio zložené z ETF. Vklady <1>automaticky zainvestujeme</1>, a\u00a0vy sa tak nemusíte o\u00a0nič starať.</0>',
			button: 'Chcem klasické portfólio',
		},
		{
			title: 'Udržateľné investície',
			text: '<0>Investujte zodpovedne a\u00a0založte si udržateľné portfólio poskladané z ETF, <1>ktoré vynikajú v\u00a0'
				+ 'ESG ratingu</1>. Ten hodnotí vplyv investície na životné prostredie (E), spoločnosť (S) a\u00a0kvalitu vedenia (G).</0>'
				+ '<0>Vyberte si zo <1>7\u00a0ESG portfólií</1>, ktorých zhodnotenie v\u00a0posledných rokoch poráža klasické investície. '
				+ 'Uvidíte, že <1>hodnoty a\u00a0výnosy si rozumejú</1>.</0>',
			button: 'Chcem ESG portfólio',
		},
		{
			title: 'Investície pre deti',
			text: '<0>Ako finančne zabezpečiť tých najmenších? Dobre, že sa pýtate, <1>v budúcnosti vám poďakujú</1>! Pár '
				+ 'desiatok eur do investícii pre deti teraz = pekný balík v\u00a0dospelosti.</0>'
				+ '<0>Založte svojim deťom investičný účet so <1>zníženým poplatkom</1>.</0>',
			button: 'Chcem detský účet',
		},
		{
			title: 'Penzijný investičný účet',
			text: '<0>Vezmite budúcnosť <1>do vlastných rúk</1>! Nespoliehajte sa na štát a\u00a0vymeňte drahé dôchodkové '
				+ 'sporenie v\u00a0DDS s\u00a0mizernými výnosmi za penzijný účet od Fondee.</0>'
				+ '<0>Založte si penzijné portfólio a\u00a0odkladajte si na dôchodok jednoducho, <1>so\u00a0zvýhodneným poplatkom</1> '
				+ 'a\u00a0rozumnejším zhodnotením oproti klasickým bankovým produktom.</0>',
			button: 'Chcem penzijné portfólio',
		},
		{
			title: 'Investície pre študentov',
			text: '<0>Možnosť príspevkov od blízkych, <1>zľava na poplatok</1>, žiadny minimálny vklad a\u00a04\u00a0€ bonus '
				+ 'za ISIC –\u00a0to je študentský účet od Fondee. Raz doložíte, že chodíte do školy, a\u00a0jeho výhody '
				+ 'sú vaše <1>až do 26\u00a0rokov</1>.</0>'
				+ '<0>Na investovanie nie je nikdy príliš skoro a\u00a0každé euro sa počíta. Začnite už popri škole '
				+ 'a\u00a0<1>zhodnoťte svoje peniaze v ETF portfóliách</1>.</0>',
			button: 'Chcem študentský účet',
		},
	],
};

const homepageServices = {
	list: [
		{
			title: 'Jednoducho',
			text: 'Účet si založíte <0>online na pár kliknutí</0>, takže už za niekoľko minút začnete zhodnocovať svoje '
				+ 'peniaze. Na webe alebo v\u00a0appke sledujete, ako sa investícii darí.',
		},
		{
			title: 'Múdro',
			text: 'Krátky dotazník vám napovie, aký ste investor – u nás si prídu na svoje <0>odvážni aj rozvážni</0>. Máte viac '
				+ 'finančných cieľov? Otvorte si naraz až 5 portfólií!',
		},
		{
			title: 'Bezpečne',
			text: 'Kým sa staráme o\u00a0vaše investície, dohliada na nás <0>Česká národná banka</0> a\u00a0vaše peniaze chráni Garančný '
				+ 'fond obchodníkov s cennými papiermi.',
		},
		{
			title: 'Bez hviezdičiek*',
			text: 'Investujte <0>toľko, koľko chcete</0>. Kedykoľvek sa s nami môžete rozlúčiť – zariadíte to online a\u00a0do\u00a0týždňa '
				+ 'máte peniaze na účte. Hviezdičky ani výnimky nevedieme.'
				+ '*Naozaj!',
		},
	],
	button: 'Chcem vedieť, ako to funguje',
};

const howItWorksHero = {
	title: 'Ako to funguje',
	description: 'Všetko, čo o\u00a0investovaní s nami potrebujete vedieť',
	list: [
		{
			name: 'Vyplňte dotazník',
			description: 'Poznávačka na úvod. My sme Fondee, a\u00a0vy? Vďaka <0>krátkemu dotazníku</0> spoznáme vaše investičné ciele a\u00a0postoj k riziku.',
		},
		{
			name: 'Zvoľte portóflio',
			description: 'Bez obáv, nebudete na to sami. Navrhneme vám <0>vhodný typ stratégie</0> podľa odpovedí v\u00a0úvodnom dotazíku.',
		},
		{
			name: 'Pošlite vklad',
			description: 'Pre dokončenie registrácie ostáva odoslať prvú platbu. Investujte už <0>od 40\u00a0€</0> jednorázovo alebo pravidelne.',
		},
		{
			name: 'Investujte bez starostí',
			description: 'Fondee za vaše vklady <0>nakupuje <0>ETFka</0></0> a\u00a0stráži, aby portfólio stále zodpovedalo rozloženiu, '
				+ 'ktoré ste si zvolili.',
		},
		{
			name: 'Vyberte svoje peniaze',
			description: 'Investíciu môžete kedykoľvek predať a\u00a0<0>do\u00a0týždňa máte peniaze na\u00a0účte</0>. Výstupné poplatky? To nepoznáme.',
		},
	],
	anchor: 'Pozrieť sa na portfólia',
};

const howItWorksInvestment = {
	title: 'Investovanie s\u00a0pokojom v\u00a0duši',
	list: [
		{
			title: 'Bezpečne',
			description: 'Investície sú uložené u brokera J&T banky na tzv. <0>zákaznickom účte</0>, ktorý nie je súčasťou '
				+ 'insolvenčnej podstaty. V\u00a0prípade úpadku sa k nim <0>ľahko dostanete</0>, takže sú v\u00a0bezpečí, aj keby sme '
				+ 'my alebo broker skrachovali. ',
		},
		{
			title: 'Kontrolovane',
			description: 'Fondee je regulované <0>Českou národnou bankou</0>. Navyše Vaše investície spadajú zo zákona pod Garančný '
				+ 'fond obchodníkov s cennými papiermi, ktorý ich chráni až <0>do 90\u00a0% hodnoty</0> (maximálne do výšky 20\u00a0000\u00a0eur).',
		},
		{
			title: 'Profesionálne',
			description: 'O portfóliá sa stará <1>tím expertov</1> s dlhoročnými skúsenosťami, ktoré nazbierali štúdiom '
				+ 'ekonomických univerzít aj praxou vo finančných inštitúciách po celom svete. Ste skrátka <0>v\u00a0dobrých rukách</0>.',
		},
	],
};

const howItWorksPrinciples = {
	title: '4 zásady múdreho investora',
	list: [
		{
			title: 'Investície sú ako horská dráha',
			text: 'Raz hore, raz dole. Investovanie prináša riziká a\u00a0nie je možné zaručiť návratnosť. Fondee nie je vhodné pre '
				+ 'krátkodobé špekulácie. Investujeme s cieľom zhodnotenia a\u00a0veríme, že <0>hodnota investície dlhodobo porastie</0>. '
				+ 'Môže ale aj klesnúť kvôli prepadu na trhoch akcií a\u00a0dlhopisov. Investujte iba vtedy, ak ste '
				+ '<0>ochotní niesť riziko straty</0>.',
		},
		{
			title: 'Čo bolo včera, nie je dnes',
			text: 'A historické výnosy nie sú záruka budúcich výnosov. Naše investičné portfóliá v\u00a0minulosti dosiahli určité '
				+ 'zhodnotenie, ale <0>nie je možné garantovať, že sa im v\u00a0bude dariť rovnako v\u00a0budúcnosti</0>. Výnos môže byť '
				+ 'vyšší aj nižší. Vešteckú guľu nemáme my ani ostatní – a\u00a0keď vám niekto sľubuje istý zisk, vodí vás za nos.',
		},
		{
			title: 'Čím nižšie poplatky, tým viac peňazí pre vás',
			text: 'Nezabudnite si vždy skontrolovať poplatky, <0>požierajú totiž hodnotu investície</0>. Aj malý rozdiel v\u00a0'
				+ 'dlhodobom horizonte znamená veľa. Fondee funguje automatizovane online bez pobočiek a\u00a0poradcov alebo bankérov. '
				+ 'Šetríme náklady a\u00a0vďaka tomu vám ponúkame <0>jedny z najnižších poplatkov na trhu</0>.',
		},
		{
			title: 'Kurz zaijíma cestovateľov aj investorov',
			text: 'Na zhodnotenie vašej investície má vplyv tiež <0>vývoj mien</0>. Väčšina ETFiek z našich portfólií sa '
				+ 'obchoduje v\u00a0eurách, niektoré navyše obsahujú dolárové položky. Menové kurzy <0>sa neustále menia a\u00a0s nimi '
				+ 'tiež hodnota vášho portfólia</0> – preto investícia vo Fondee môže vplyvom mien ako rásť, tak poklesnúť.',
		},
	],
};

const howItWorksRebalancing = {
	title: 'Čo je rebalansovanie portfólia?',
	description: '<0>Každé Fondee portfólio má svoje percentuálne zastúpenie akcií a\u00a0dlhopisov. Ceny akcií a\u00a0dlhopisov '
		+ 'sa ale každý deň menia a\u00a0preto sa časom môže ich pomer <0>líšiť od toho, ktorý ste si na začiatku vybrali</0>. '
		+ 'Už vám napríklad nemusí vyhovovať, že investície obsahujú viac akcií, než chcete.</0>'
		+ '<0>Rebalansovanie zabezpečuje, že sa portfólio každý mesiac <0>automaticky upraví, aby jeho rozloženie stále '
		+ 'zodpovedalo zvolenému pomeru</0>. Službu rebalansingu si môžete vypnúť – potom sa za nové vklady nakupujú ETF '
		+ 'v takom pomere, aby cieľové rozdelenie portfólia dorovnali.</0>',
	chart: {
		portfolioValueLabel: 'Hodnota portfólia',
		portfolioToday: 'Dnes',
		portfolioAfterMonth: 'Po mesiaci',
		portfolioAfterRebalancing: 'Po Rebalansovaní',
		portfolioValueToday: '5000 EUR',
		portfolioValueAfterMonth: '5500 EUR',
		portfolioValueAfterRebalancing: '5500 EUR',
		bond: 'Dlhopisy',
		share: 'Akcie',
		etf: 'ETF',
		why: 'Prečo?',
		how: 'Ako?',
		tooltipWhy: 'Ceny akcií a\u00a0dlhopisov sa menia každý deň. V\u00a0tomto prípade ceny akciového ETF vzrástli o\u00a020\u00a0% a\u00a0ceny dlhopisového ETF sa nezmenili.',
		tooltipHow: 'Predaj 5\u00a0% portfólia držaného v\u00a0akciovom ETF a\u00a0nákup dlhopisového ETF.',
	},
};

const howItWorksSchema = {
	title: 'Ako to presne funguje?',
	chart: {
		broker: 'Broker',
		client: 'Klient',
		money: {
			title: 'Peniaze',
			tooltip: 'Peniaze na investície posielate zo svojho bežného účtu na zberný účet '
				+ 'u\u00a0brokera. Výber peňazí potom ide taktiež priamo z\u00a0účtu u\u00a0brokera späť '
				+ 'k\u00a0vám.',
		},
		portfolio: {
			title: 'Výber portfólia',
			tooltip: 'Vyberiete si jedno z\u00a0modelových portfólií. Fondee sa o\u00a0toto portfólio '
				+ 'stará a\u00a0dáva príkazy na nákup alebo predaj ETF-iek brokerovi.',
		},
		trading: {
			title: 'Príkazy na obchodovanie',
			tooltip: 'Fondee dáva brokerovi príkazy na obchodovanie. Peniaze sú zainvestované '
				+ 'vždy na druhý pracovný deň po prijatí.',
		},
	},
	broker: {
		title: 'Aká je rola brokera?',
		text: 'Fondee je licencované Českou národnou bankou na činnosť obchodníka '
			+ 's\u00a0cennými papiermi, ale samo sa nepohybuje na burze cenných papierov, kde '
			+ 'obchodovanie prebieha. Preto sme sa spojili s\u00a0brokerom, cez ktorého nakupujeme '
			+ 'a\u00a0predávame cenné papiere pre našich klientov.',
	},
};

const investorProfileAvatar = {
	modal: {
		title: 'Zmeniť obrázok portfólia',
		submit: 'Zmeniť obrázok',
		cancel: 'Ponechať existujúci obrázok',
	},
};

const investorProfileChoice = {
	title: 'Aký investičný profil mi zodpovedá?',
	text: 'Daný investičný profil Vám vyšiel na základe výsledkov investičného dotazníka. Svoj investičný profil si môžete '
		+ 'kedykoľvek v\u00a0budúcnosti zmeniť vo svojej klientskej zóne.',
	riskTitle: 'Vaša tolerancia rizika',
	submit: 'Potvrdiť výber',
	redo: 'Prajem si skontrolovať odpovede v\u00a0investičnom dotazníku',
	tooltip: {
		classic: 'Ide o\u00a0našu tradičnú ponuku, ktorá nezohľadňuje ESG kritériá',
		esg: 'ESG investície zohľadňujú dopady na životné prostredie, sociálne aspekty a\u00a0zodpovedné riadenie firmy.',
	},
	category: {
		classic: 'Jeden vklad = stovky akcií a dlhopisov z celého sveta. Investujte do ETF online, jednoducho a zrozumiteľne.',
		esg: 'Hodnoty a výnosy si rozumejú. Investujte udržateľne do portfólií, ktoré v posledných rokoch porážajú klasické investície.',
	},
	littleExperience: {
		title: 'Investovanie s\u00a0Fondee pre Vás nie je vhodné, pretože máte málo znalostí a\u00a0skúseností '
			+ 's\u00a0investovaním.',
		message: 'Pokiaľ si chcete prečítať viac o\u00a0tom, čo je ETF a\u00a0ako funguje Fondee, '
			+ 'pozrite sa <0>sem</0>.',
		reset: 'Chcem zmeniť niektoré odpovede',
	},
	notSuitable: {
		title: 'Upozornenie',
		message: 'Je nám to ľúto, ale na základe vašich odpovedí Vám v\u00a0súčasnosti nemôžeme ponúknuť naše služby.',
		text: 'Investovanie s\u00a0Fondee nie je vhodné na krátkodobú špekuláciu alebo iné krátkodobé ciele. Každé '
			+ 'investovanie so sebou nesie riziko straty časti alebo celej investície, pričom produkty určené na krátkodobé '
			+ 'investovanie bývajú pre zabezpečenie požadovaného výnosu oveľa rizikovejšie. Naše služby sú určené na '
			+ 'strednodobé až dlhodobé investovanie do diverzifikovaných produktov.',
		back: 'Späť do dotazníka',
	},
	notRecommended: {
		title: 'Upozornenie',
		message: '<0>Tento investičný profil pre Vás nie je podľa vyhodnotenia investičného dotazníka vhodný. '
				+ 'S\u00a0investovaním na kapitálových trhoch je spojené riziko, ktoré, ako vyplýva z\u00a0Vašich odpovedí, '
				+ 'zrejme nemôžete alebo nechcete podstúpiť.</0><0>Ak ste pri vyplňovaní investičného dotazníka uviedli '
				+ 'informáciu, ktorá nezodpovedá skutočnosti, alebo ste omylom označili nesprávnu odpoveď, môžete vyplniť '
				+ 'investičný dotazník znova.</0>',
		clientMessage: '<0>Tento investičný profil pre Vás nie je podľa vyhodnotenia investičného dotazníka vhodný. '
				+ 'S\u00a0investovaním na kapitálových trhoch je spojené riziko, ktoré, ako vyplýva z\u00a0Vašich odpovedí, '
				+ 'zrejme nemôžete alebo nechcete podstúpiť.</0>',
		close: 'Chcem vyplniť dotazník znova',
		clientClose: 'Vrátiť späť',
		redo: 'Chcem vyplniť dotazník znova',
	},
	notConsistent: {
		title: 'Upozornenie',
		text: 'Ľutujeme, ale Vaše odpovede týkajúce sa dĺžky investičného horizontu sú v\u00a0rozpore. Ide '
			+ 'o\u00a0otázky “Ako dlho plánujete investovať?” a "Ako plánujete využiť svoje peniaze z\u00a0investícií?". '
			+ 'Kliknutím na\u00a0tlačidlo sa môžete vrátiť späť do dotazníka a\u00a0svoje odpovede opraviť.',
	},
};

const investorProfileDetail = {
	title: 'Nastavte si svoju ideálnu investíciu',
	subtitle1: 'Pre seba, alebo pre deti? Viac akcií, dlhopisov, alebo pol na pol? Klasická, alebo „zelená“? '
		+ '<0>Záleží len na vás!</0> Vyberte si produkt, typ investície a\u00a0mieru rizika – podľa toho vám navrhneme vhodné portfólio.',
	investorPostfix: 'investor',
	showProducts: 'Zobraziť približné rozloženie ETF v\u00a0portfóliu',
	productsTitle: 'Rozloženie titulov ETF v\u00a0portfóliu',
	productToggle: 'Chcem investičný účet:',
	portfolioToggle: 'Typ portfólia:',
	selectionTitle: 'Posuvníkom nastavte mieru rizika',
	ranger: {
		info: 'Všeobecne platí, že ochota niesť vyššie riziko a\u00a0dlhší časový investičný '
			+ 'horizont, ktorý preklenie krátkodobé turbulencie, tiež vedie k\u00a0vyšším výnosom',
		less1: 'Nižšie\u00a0riziko',
		less2: 'Nižší\u00a0výnos',
		more1: 'Vyššie\u00a0riziko',
		more2: 'Vyšší\u00a0výnos',
		recommend: 'Profily v\u00a0<0>zelenom</0> intervale sme na základe vami vyplneného '
			+ 'investičného dotazníka vyhodnotili ako vhodné pre Vás.',
		recommendWarning: 'Profily v\u00a0šedom intervale pre Vás nie sú podľa vyhodnotenia investičného '
			+ 'dotazníka vhodné',
	},
	profileAccountOptions: [
		{
			label: 'Pre seba nebo pre deti',
			value: 'both',
		},
	],
	profileTypeOptions: [
		{
			label: 'Klasická',
			value: 'classic',
		},
		{
			label: 'ESG',
			value: 'esg',
		},
	],
};

const investorProfilePreview = {
	toggleLabel: 'Vyberte typ portfólia:',
	classicPortfolios: 'Klasické',
	esgPortfolios: 'ESG',
	walletTitle: 'Vaše úspory',
	sliderTitle: 'investor',
	chartOverlayTitle: 'Historický výnos',
	chartOverlayPeriod: 'za rok',
	chartTooltip: 'Priemerné ročné zhodnotenie portfólia za obdobie 2014-2024 v\u00a0eurách. Výpočet vychádza zo '
		+ 'simulovaného historického vývoja, založeného na cenách jednotlivých ETF, alebo ich podkladových indexov. '
		+ 'Ide o\u00a0hrubé výnosy pred započítaním poplatkov.',
	disclaimer: {
		text: 'Historický výnos počítame ako priemerné ročné zhodnotenie portfólia za obdobie 2014-2024 v\u00a0eurách '
		+ 'a\u00a0výpočet vychádza zo simulovaného historického vývoja na\u00a0základe cien jednotlivých ETF alebo ich '
		+ 'podkladových indexov. Uvedené výnosy našich portfólií sú vypočítané pred započítaním poplatkov Fondee '
		+ '(1.09\u00a0% ročne).',
	},
	aboutButton: 'Zistiť viac o\u00a0tomto produkte',
};

const login = {
	title: 'Prihlásenie',
	text: 'Tadiaľto do klientskej zóny',
	button: 'Prihlásiť sa',
	forgotPassword: 'Zabudnuté heslo',
	social: {
		twoFactorTitle: 'Prihlásenie cez sociálne siete',
		or: 'alebo',
		title: 'Prihláste sa cez:',
	},
};

const media = {
	title: 'Fondee v\u00a0médiách',
};

const meta = {
	title: 'Fondee',
	titleExtended: 'Fondee | Robíme z peňazí viac peňazí',
	titleSuffix: ' - Fondee',
	description: 'Pridajte sa k investorom novej generácie. Nenechajte ležať peniaze na účtoch, kde '
		+ 'strácajú hodnotu. Zainvestujte ich, nech sa zhodnocujú samy.',
	og: {
		title: 'Fondee',
		description: 'Nenechajte ležať peniaze na účtoch, kde strácajú hodnotu. Zainvestujte ich, nech sa zhodnocujú samy.',
		referralTitle: 'Fondee - s bonus kódom',
		referralDescription: 'Začnite investovať vďaka bonusu od kamaráta.',
	},
	titles: {
		account: 'Môj účet',
		childProduct: 'Investície pre deti',
		classicProduct: 'Investícia na mieru',
		clientCheck: 'Pravidelné kontroly',
		contact: 'Spýtajte sa',
		cookies: 'Cookies',
		dashboard: 'Prehľad',
		documents: 'Dokumenty',
		emailAlias: 'Prepojenie účtov',
		emailVerification: 'Overenie e-mailu',
		esgProduct: 'ESG investície',
		faq: 'Časté otázky',
		fees: 'Poplatky',
		forgotPassword: 'Obnova hesla',
		history: 'História',
		howItWorks: 'Ako to funguje',
		login: 'Prihlásenie',
		offers: 'Nieco navyše',
		onboarding: 'Dokončenie registrácie',
		onboardingChild: 'Registrácia dieťaťa',
		onboardingParent: 'Registrácia rodiča',
		ourApproach: 'Náš prístup',
		pensionProduct: 'Investícia na dôchodok',
		portfolio: 'Portfólio',
		privacyPolicy: 'Ochrana osobných údajov',
		referrals: 'Odporúčanie',
		registration: 'Registrácia',
		resetPassword: 'Zmena hesla',
		signAgreement: 'Podpis zmluvy',
		signNewAgreement: 'Podpis zmluvy',
		studentProduct: 'Zvýhodnené investície pre študentov | Ľahko & online',
		taxes: 'Dane 2024',
		unsubscribe: 'Odhlášenie z odberu',
	},
	descriptions: {
		studentProduct: 'Investície pre študentov s nízkym poplatkom a možnosťou príspevkov od rodiny a blízkych. Investujte do ETF portfólií online v aplikácii Fondee.',
	},
};

const offers = {
	title: 'Čo by sa vám tiež mohlo hodiť?',
	text: 'Dali sme to dokopy s\u00a0niekoľkými českými startupmi nielen z\u00a0odboru financií a\u00a0prinášame vám tipy na ich služby.',
	code: 'Referenčný kód',
};

const onboarding = {
	title: 'Vitajte vo Fondee!',
	text: 'Čaká Vás nudný, ale nutný registračný proces.\nO\u00a0to jednoduchšia potom bude správa Vašich investícií.',
	info: 'Pripravte si svoj občiansky preukaz, vodičský preukaz alebo cestovný pas.\nBude nutné urobiť fotografiu jedného '
		+ 'vybraného dokladu, preto budete potrebovať aj chytrý telefón alebo počítač s\u00a0webkamerou a\u00a0trochu trpezlivosti.',
	steps: {
		product: {
			title: 'Voľba produktu',
			childInfo: 'Než vytvoríme nový účet pre Vaše dieťa, musíme najskôr založiť účet pre Vás. Chápeme, že je to trochu '
				+ 'otravné, ale musíme si byť istí, že je všetko v\u00a0poriadku.',
		},
		profileType: {
			title: 'Typ portfólia',
		},
		questionnaires: {
			title: 'Dotazníky',
			childTitle: 'Dotazníky dieťaťa',
			AML: 'AML dotazník',
			ESG: 'ESG dotazník',
			investment: 'Investičný dotazník',
		},
		amlQuestionnaire: {
			title: 'Dotazník AML',
		},
		questionnaire: {
			title: 'Dotazník',
			active: 'Investičný dotazník obsahuje niekoľko jednoduchých otázok, ktorými zisťujeme Vaše investičné '
				+ 'skúsenosti a\u00a0znalosti a\u00a0taktiež Váš prístup k\u00a0riziku. Následne všetko porovnáme s\u00a0výberom '
				+ 'investičného profilu, ktorý môžete kedykoľvek neskôr zmeniť. Investičný dotazník je '
				+ 'povinná súčasť registrácie a\u00a0jeho výsledky sú prílohou k\u00a0zmluve.',
			activeChild: 'Investičný dotazník vypĺňate za dieťa. Tam, kde je to možné, vyplňte odpovede podľa situácie vášho '
				+ 'dieťaťa. Môže sa jednať napr. o\u00a0investičné ciele alebo finančné zázemie. Naopak, skúsenosti a\u00a0znalosti '
				+ 'vyplňte podľa seba.',
			signAgreementActive: 'Investičný dotazník je trochu rozsiahlejší a\u00a0obsahuje niektoré nové otázky. Nie je to '
				+ 'preto, že by sme od Vás chceli viac informácií, ale preto, že nám to ukladá legislatíva a\u00a0rozšírené '
				+ 'bezpečnostné predpisy, ktoré teraz musíme spĺňať. Všetky odpovede sú, samozrejme, dôverné a\u00a0spadajú '
				+ 'pod ochranu osobných údajov.',
			done: 'Investičný dotazník máte úspešne vyplnený! Svoje odpovede nájdete v\u00a0prílohe zmluvy.',
			continue: 'Pokračovať na ďalší krok',
			select: 'Vyberte z\u00a0možností',
			agree: 'Rozumiem',
			amlTitle: 'A\u00a0ešte nás čaká pár otázok týkajúcich sa pôvodu investovaných peňazí',
			years_one: '{{ count }} rok a\u00a0menej',
			years_few: '{{ count }} roky',
			years_other: '{{ count }} rokov a\u00a0viac',
			ratio_min: 'Menej ako 10\u00a0%',
			currency: 'EUR',
			cancelModal: {
				title: 'Vaše rozhodnutie rešpektujeme.',
				text: 'Ak by ste sa chceli k\u00a0investovaniu s\u00a0Fondee vrátiť, neváhajte sa nám ozvať na '
					+ '<0>$t(email.address)</0> alebo pomocou chatu na týchto webových stránkach.',
				return: 'Zrušiť príkaz na zrušenie účtu',
				logout: 'Odhlásiť',
			},
			americanModal: {
				title: 'Upozornenie',
				text: 'Je nám ľúto, ale v\u00a0súčasnej dobe Vám nemôžeme ponúknuť naše služby. Ak sa v\u00a0budúcnosti táto '
					+ 'situácia zmení, dáme Vám vedieť. Ak si neželáte byť kontaktovaní, môžete svoju registráciu zrušiť cez odkaz nižšie. '
					+ 'V\u00a0prípade akýchkoľvek otázok nás neváhajte kontaktovať na info@fondee.sk.',
				link: 'Zrušiť registráciu',
				button: 'Dajte mi vedieť',
				thanks: 'Ďakujeme za Váš záujem o\u00a0Fondee!',
			},
			esgRatioModal: {
				title: 'Upozornenie',
				text: '<0>Vo Fondee máme teraz v\u00a0ponuke udržateľného investovania tzv. „A8\u00a0produkt“ (podiel v\u00a0portfóliu 100\u00a0%).</0>'
					+ '<0>Čo to znamená? A8 obsahuje fondy podporujúce životné prostredie, sociálne aspekty a\u00a0správne riadenie spoločnosti.</0>'
					+ '<0>Pokiaľ máte záujem o\u00a0iný typ udržateľnej investície ako A8, bohužiaľ Vám teraz nemôžeme nič ponúknuť.</0>'
					+ '<1>Je to trochu zvláštne, ale aj napriek tomu, že teraz ponúkame len jednu kategóriu ESG investície (A8), '
					+ 'máme povinnosť sa Vás opýtať na\u00a0Vaše preferencie. Viac o\u00a0regulácii udržateľných investícií a\u00a0zákone '
					+ 'RTS si môžete prečítať v\u00a0článku <0>"Všetko o\u00a0európskej ESG regulácii"</0></1>'
					+ '<0>Svoje odpovede môžete kedykoľvek zmeniť.</0>',
				button: 'Späť',
				continue: 'Rozumiem a\u00a0chcem pokračovať',
			},
			esgFinalQuestion: {
				title: 'Upozornenie',
				text: '<0>Vaše preferencie ohľadom ESG investície nezodpovedajú portfóliám, ktoré Fondee momentálne ponúka '
					+ '(A8 produkt so zastúpením 100\u00a0%).</0>'
					+ '<0>Fondee ESG portfólia podporujú udržateľnosť v\u00a0environmentálnych a\u00a0sociálnych aspektoch za predpokladu '
					+ 'správneho fungovania firmy.</0>'
					+ '<0>Pokiaľ preferujete iný typ ESG investície ako A8 produkt, nemôžeme Vám ponúknuť žiadny investičný produkt. '
					+ 'Služieb Fondee môžete využiť v\u00a0prípade, že upravíte svoje preferencie ohľadom udržateľnosti.</0>'
					+ '<0>Pokiaľ sa tak rozhodnete, uveďte prosím dôvod, ktorý Vás k\u00a0tomuto rozhodnutiu viedol. Ak si prajete '
					+ 'vrátiť sa do dotazníka a\u00a0zmeniť svoje odpovede, pokračujte cez tlačidlo nižšie.</0>',
				button: {
					continue: 'Chcem zmeniť svoje ESG preferencie tak, že mojou voľbou je A8 produkt so zastúpením 100%, a\u00a0investovať '
						+ 's\u00a0Fondee do ESG produktov.',
					change: 'Chcem sa vrátiť do ESG dotazníka a\u00a0zmeniť svoje odpovede',
				},
				declaration: '<0>Týmto vyhlasujem, že som bol spoločnosťou Fondee informovaný, že mi v\u00a0súčasnej dobe nemôže '
					+ 'poskytnúť investičnú službu spôsobom, ktorý by vyhovoval mojim preferenciám týkajúcim sa udržateľných investícií.</0>'
					+ '<0>Na základe tejto informácie som sa rozhodol upraviť svoje preferencie a\u00a0vybrať si z\u00a0produktov Fondee.</0>',
			},
			logout: 'Ďakujeme za Váš záujem o\u00a0Fondee!',
		},
		documents: {
			title: 'Overenie vašej totožnosti',
			childTitle: 'Overenie totožnosti dieťaťa',
			error: 'Ospravedlňujeme sa, pri overovanie totožnosti došlo k chybe.',
			redirect: {
				title: 'Overiť cez Veriff',
				text: 'Veriff je náš partner, cez ktorého môžete kontrolu dokončiť behom pár minút. Odporúčame proces '
					+ 'vykonať na mobilnom telefóne a\u00a0ďalšie tipy nájdete v\u00a0otázniku nižšie.',
				listTitle: 'Je možné nahrať nasledujúce typy dokladov:',
				list: '<0>Občiansky preukaz</0>'
					+ '<0>Cestovný pas</0>'
					+ '<0>Vodičský preukaz</0>',
				listChild: '<0>Občiansky preukaz</0>'
					+ '<0>Cestovný pas</0>',
			},
			upload: {
				title: 'Manuálne overenie',
				frontSide: 'Predná strana',
				backSide: 'Zadná strana',
				list: {
					formats: '<0>Podporované formáty:</0>'
						+ '<1>PNG, JPG</1>',
					size: '<0> Veľkosť:</0>'
						+ '<1> Maximálna veľkosť 4MB</1>',
				},
				fileTypes: {
					birthCertificate: 'Rodný list',
					drivingLicense: 'Vodičský preukaz',
					identification: 'Občiansky preukaz',
					passport: 'Cestovný pas',
				},
				submit: 'Potvrdiť',
			},
			tooltip: '<0>Čo môže pomôcť pri úspešnom dokončení overenia totožnosti? Je nutné mať povolené použitie kamery '
				+ 'v\u00a0prehliadači vášho zariadenia! Ďalej dobré svetelné podmienky, dostatočne kvalitnú kameru a\u00a0zaostrenie.</0>'
				+ '<0>Čomu sa vyhnúť? Odrazy svetla na doklade, rozmazané či nedostatočne zaostrené fotografie, fotenie v\u00a0tmavej '
				+ 'miestnosti bez dostatku osvetlenia.</0>'
				+ '<0>Pokiaľ ste vyskúšali všetky vyššie uvedené tipy a\u00a0stále sa Vám nedarí overenie totožnosti, dajte nám vedieť '
				+ 'cez chat alebo e-mailom na info@fondee.sk. Radi Vám pomôžeme.</0>',
		},
		childData: {
			title: 'Registrácia dieťaťa',
			form: {
				personalIdentificationNumber: {
					title: 'Rodné číslo dieťaťa',
					text: 'Zadajte prosím rodné číslo dieťaťa bez\u00a0lomítka.',
				},
				bankAccount: {
					title: 'Číslo účtu dieťaťa (nepovinné)',
				},
			},
		},
		childEmailVerification: {
			title: 'Overenie e-mailu dieťaťa',
			text: 'Na uvedenú e-mailovú adresu sme vám odoslali inštrukcie, ako vykonať jej overenie. Zaberie to len chvíľu.',
			form: {
				code: {
					label: 'Overovací kód',
					cancel: 'Vymazať detský e-mail',
					confirm: 'Potvrdiť',
					skip: 'Preskočiť overenie, vymazať detský e-mail a pokračovať v registrácii',
					invalid: 'Neplatný overovací kód',
				},
				email: {
					label: 'E-mailová adresa',
					change: 'Zmeniť e-mail',
					success: 'Odoslané na nový e-mail',
				},
			},
		},
		personalData: {
			title: 'Kontrola osobných údajov',
			active: 'Tu si môžete skontrolovať a\u00a0doplniť svoje osobné údaje.',
			student: {
				confirm: 'Mám ISIC/ITIC kartu',
				error: 'Pre pokračovanie je nutné zaškrtnúť.',
				modal: {
					title: 'Upozornenie',
					text: 'Máte viac ako 26\u00a0rokov a\u00a0bohužiaľ teda nespĺňate podmienky pre založenie študentského účtu. '
						+ 'Registráciu môžete dokončiť, ale otvoríme Vám štandardný účet, '
						+ 'ktorý má rovnaké možnosti investovania, iba bez zníženého poplatku.',
					confirm: 'Pokračovať bez ISIC',
				},
			},
			form: {
				name: {
					title: 'Meno',
					text: 'Registrácia bude vykonaná na nasledujúce meno:',
				},
				address: {
					title: 'Adresa trvalého bydliska',
				},
				contactAddress: {
					add: 'Chcem uviesť inú kontaktnú adresu',
					title: 'Kontaktná adresa',
					confirm: 'Vyhlasujem, že mnou uvedená adresa je správna',
				},
				birthDate: {
					title: 'Dátum narodenia',
				},
				placeOfBirth: {
					title: 'Miesto narodenia',
				},
				nationality: {
					title: 'Štátna príslušnosť',
				},
				personalIdentificationNumber: {
					title: 'Rodné číslo',
					text: 'Zadajte, prosím, svoje rodné číslo bez lomky.',
				},
				bankAccount: {
					title: 'Číslo účtu',
					text: 'Na spárovanie platby s\u00a0Vaším investičným účtom potrebujeme poznať číslo účtu, '
						+ 'z\u00a0ktorého budete peniaze posielať.',
					warning: 'Upozorňujeme, že účet musí byť vedený na vlastné meno '
						+ 'a\u00a0z\u00a0tohto dôvodu neakceptujeme platby platobných služieb tretích strán, ako sú '
						+ 'napr. Wise, Revolut či Monese.',
					childWarning: 'Pokiaľ má Vaše dieťa vlastný bankový účet, môžete ho vložiť tu. V\u00a0takom prípade by mal '
						+ 'byť účet vedený priamo na meno dieťaťa. Z\u00a0tohto dôvodu nie je možné využiť služby ako napr. Wise, Revolut '
						+ 'či\u00a0Monesa.',
					ibanText: 'Ak si prajete pridať zahraničný účet, je nutné ho vložiť cez IBAN.',
				},
				confirm: {
					label: 'Potvrdzujem, že vyššie mnou uvedené údaje sú pravdivé a\u00a0aktuálne platné',
					error: 'Na pokračovanie je nutné začiarknuť.',
				},
				submit: 'Potvrdiť',
			},
		},
		agreement: {
			title: 'Podpis zmluvy',
			active: 'Teraz už len podpísať zmluvu o\u00a0investičnom zastupovaní, ktorá nám umožní investovať '
				+ 'Vaše peniaze podľa Vami zvolenej investičnej stratégie (nič viac nám to neumožní, nebojte sa).',
			signAgreementActive: 'Kvôli prechodu k\u00a0novému brokerovi sa v\u00a0zmluve mení pár legislatívnych '
				+ 'termínov a\u00a0drobností. Fakticky sa však pre Vás nič nemení, Fondee bude ďalej investovať Vaše '
				+ 'peniaze podľa zvoleného profilu. Nič viac.',
			signNewAgreementActive: 'Zmluva upravuje náležitosti týkajúce sa investovania s\u00a0Fondee. V\u00a0prílohách nájdete '
				+ 'obchodné podmienky, cenník a\u00a0reklamačný poriadok. Stiahnite si ju, starostlivo prečítajte '
				+ 'a\u00a0odsúhlaste nižšie. Na\u00a0overenie identity Vám zašleme SMS\u00a0kód.',
			download: 'Stiahnuť zmluvu v\u00a0PDF',
			timeOutText: 'Upozorňujeme, že Vás po 20\u00a0minútach z\u00a0bezpečnostných dôvodov odhlásime. Pokiaľ chcete '
				+ 'venovať štúdiu zmluvy viac času, samozrejme môžete. Kedykoľvek sa môžete opäť prihlásiť a\u00a0pokračovať '
				+ 'v\u00a0registrácii od miesta, kde ste skončili.',
			formAgreement: {
				infoText: 'Pripravili sme pre Vás zmluvu. Stiahnite si ju, pozorne prečítajte a\u00a0odsúhlaste '
					+ 'nižšie. Na overenie identity Vám zašleme SMS\u00a0kód.',
				readAgreement: {
					label: 'Zmluvu som si prečítal/-a',
					blocked: 'Najprv si, prosím, prečítajte zmluvu.',
					required: 'Treba odsúhlasiť, že ste zmluvu prečítali.',
				},
				smsSend: 'Odoslať kód',
				smsBlocked: 'Overovací kód sme odoslali. Pokiaľ potrebujete nový, počkajte prosím minútu.',
				smsText: 'Na číslo <0>{{ phoneNumber }}</0> Vám odošleme overovací kód, ktorým je potrebné zmluvu podpísať.',
				phoneChange: {
					text: 'Ak ste zadali nesprávne telefónne číslo, môžete si ho zmeniť <0>tu</0>. Po uložení bude potrebné znovu odoslať '
						+ 'kód na podpis zmluvy. Nový kód je možné odoslať po uplynutí jednej minúty.',
					modal: {
						title: 'Zmena telefónneho čísla',
						placeholder: 'Nové telefónne číslo',
						button: 'Uložiť',
					},
				},
				legalRepresentative: {
					label: 'Som zákonný zástupca',
					download: 'Stiahnuť vyhlásenie zákonného zástupcu',
				},
			},
			formCode: {
				code: {
					label: 'Vložiť overovací kód',
					invalid: 'Overovací kód má 6\u00a0znakov.',
					error: 'Zabudli ste vyplniť overovací kód.',
				},
				error: 'Zadaný kód je neplatný. Skontrolujte si ho, prosím, alebo si vyžiadajte nový. Ak máte problémy s\u00a0podpisom, obráťte sa na <0>$t(email.address)</0>.',
				submit: 'Podpísať zmluvu',
			},
		},
		payment: {
			title: 'Odoslanie prvej platby',
			active: 'Na dokončenie registrácie je teraz potrebné potvrdiť Váš bankový účet. Ako potvrdenie môžete poslať akúkoľvek '
				+ 'sumu, na ideálne rozloženie portfólia však odporúčame vložiť aspoň 400\u00a0EUR.',
			sentMoney: {
				label: 'Potvrdzujem, že som úvodnú platbu odoslal/-a podľa vyššie uvedených pokynov',
				error: 'Aby bolo možné pokračovať, je nutné odoslať platbu.',
			},
			submit: 'Dokončiť registráciu',
		},
	},
	final: {
		title: 'Registrácia dokončená!',
		payment: {
			title: 'Čakáme na spracovanie platby',
			text: 'Teraz čakáme, kým bude Vaša prvá platba pripísaná na investičný účet u\u00a0brokera. '
				+ 'To bude pravdepodobne nasledujúci pracovný deň. Hneď potom peniaze zainvestujeme podľa Vami zvoleného investičného profilu. '
				+ 'Pred zainvestovaním ešte skontrolujeme všetky náležitosti ohľadom prania špinavých peňazí, ktoré nám ukladá zákon. '
				+ 'Pokiaľ budeme potrebovať viac informácií, ozveme sa Vám.',
		},
		studentConfirmation: {
			title: 'Čakáme na\u00a0overenie potvrdenia o\u00a0štúdiu',
			text: 'Overujeme platnosť Vášho potvrdenia o\u00a0štúdiu, čo môže trvať pár pracovných dní. '
				+ 'V\u00a0prípade akýchkoľvek otázok nás neváhajte kontaktovať na\u00a0chate alebo na\u00a0<0>info@fondee.sk</0>.',
		},
		profile: {
			title: 'Chcete zmeniť investičný profil?',
			text: 'Ak chcete zmeniť svoj investičný profil ešte pred prvým zainvestovaním, môžete tak urobiť po aktivácii '
				+ 'svojho účtu v\u00a0sekcii „Môj účet“. Ďalšie zmeny profilu sa prejavia vždy pri najbližšom rebalancovaní portfólia.',
		},
		topUp: {
			title: 'Nie ste si istí, či ste správne odoslali prvú platbu?',
			text: 'Ak ste dokončili všetky kroky registrácie pred viac než dvoma pracovnými dňami, skontrolujte, prosím, či '
				+ 'v\u00a0poriadku odišla prvá platba z\u00a0účtu {{ account }}. Ak nie, odošlite platbu znovu podľa pokynov <0>tu</0>.',
			newAccount: 'Ak ste náhodou odoslali overovaciu platbu z\u00a0iného účtu, môžete si ho pridať cez tlačidlo nižšie. '
				+ 'Pre všetky ďalšie účty platia rovnaké podmienky, musia byť teda vedené na Vaše meno v\u00a0bankovej inštitúcii so '
				+ 'sídlom v\u00a0EÚ. Odoslanú platbu potom dohľadáme a\u00a0spárujeme s\u00a0Vaším novým účtom.',
		},
		agreement: {
			title: 'Zabudli ste, kam ste si stiahli zmluvu?',
			text: 'Zmluvu máte pripravenú na stiahnutie v\u00a0sekcii <0>Dokumenty</0>.',
		},
		contact: {
			title: 'Chcete vedieť čokoľvek ďalšie?',
			text: 'Píšte nám do chatu vpravo dole alebo pošlite mail na adresu <0>$t(email.address)</0>.',
		},
		feedback: {
			title: 'Budeme radi za spätnú väzbu',
			done: 'Ďakujeme za spätnú väzbu!',
			form: {
				intro: 'Dajte nám vedieť, ako ste boli spokojní s\u00a0registračným procesom:',
				statusGood: 'Úplne spokojný/-á',
				statusBad: 'Úplne nespokojný/-á ',
				message: 'A\u00a0môžete nám niečo odkázať aj slovne:',
				submit: 'Odoslať spätnú väzbu',
			},
		},
	},
	profilePreview: {
		text: 'Zvolený investičný profil:',
		info: 'Investičný profil je možné kedykoľvek v\u00a0budúcnosti zmeniť po dokončení registrácie.',
	},
};

const ourApproachAccount = {
	title: 'Eva a\u00a0Ján tiež investujú s Fondee',
	text: 'Takto vyzerá ich reálne portfólio',
};

const ourApproachHero = {
	title: 'O nás',
	text: 'Zistite, kto stojí za Fondee',
	list: [
		{
			title: '17\u00a0000+ klientov, 3\u00a0krajiny',
			text: '17\u00a0000+ klientov z\u00a03\u00a0krajín s\u00a0nami aktívne investuje. Zverili svoje portfólio '
				+ 'Fondee a\u00a0zhodnocujú peniaze jednoducho, transparentne a\u00a0s\u00a0nízkymi poplatkami. Pridáte sa?',
		},
		{
			title: 'Dohľad Českej národnej banky',
			text: 'Rovnako ako na ostatné české finančné inštitúcie, tak aj na nás dohliada ČNB. Spolu s\u00a0Garančným '
				+ 'fondom obchodníkom s\u00a0cennými papiermi zabezpečuje, že sú u\u00a0nás vaše peniaze v\u00a0bezpečí.',
		},
		{
			title: 'Spolupráca s J&T Bankou',
			text: 'J&T Banka v\u00a0nás vidí potenciál. Podporuje Fondee finančne aj zdieľaním skúšeností z odboru '
				+ 'a\u00a0prispieva tak k\u00a0rýchlejšiemu rozvoju modernej investičnej aplikácie, ktorá tu chýbala.',
		},
	],
	anchor: 'Koho by ste si vybrali',
};

const ourApproachTeam = {
	title: 'Koho by ste si vybrali, aby sa staral o\u00a0Vaše financie?',
	team: {
		eva: {
			name: 'Eva Hlavsová',
			text: '<0>Čo tak niekoho, kto vyštudoval <1>ekonómie na Cambridge</1> a\u00a0na IES v\u00a0Prahe a\u00a0má za sebou roky praxe '
				+ 'ako <1>viceprezident analýzy investičných rizík</1> v\u00a0Morgan Stanley? Alebo niekoho, kto pracoval na '
				+ 'Ministerstve financií a\u00a0v\u00a0Európskej banke pre obnovu a\u00a0rozvoj?</0>'
				+ '<0>Toto všetko má za sebou Eva, zakladateľka Fondee.</0>',
			link: 'Evin profil na LinkedIn',
		},
		honza: {
			name: 'Ján Hlavsa',
			text: '<0>Alebo niekoho, kto vyštudoval <1>ekonómiu na LSE v\u00a0Londýne</1> a\u00a0na IES v\u00a0Prahe, niekoľko rokov '
				+ 'pracoval vo financiách v\u00a0londýnskej City a\u00a0staral sa o\u00a0portfólia <1>v miliardách dolárov</1>?</0>'
				+ '<0>To sú skúsenosti Jána, zakladateľa Fondee.</0>',
			link: 'Janov profil na LinkedIn',
		},
		pavel: {
			name: 'Pavel Štěpánek',
			text: '<0>A čo tak Pavol? Pôsobil ako člen bankovej rady <1>Českej národnej banky</1>, riaditeľ <1>Českej bankovej '
				+ 'asociácie</1>, člen Rady riaditeľov Europskej banky pre obnovu a\u00a0rozvoj alebo ako námestok ministra financií. '
				+ 'Dnes okrem iného prednáša na VŠE.</0>'
				+ '<0>Do Fondee vniesol <1>viac ako 30 rokov skúseností</1> vo financiách a\u00a0pomáhá nám ísť správnym smerom.</0>',
			link: 'Pavlov profil na LinkedIn',
		},
	},
};

const portfolio = {
	overview: {
		valueTitle_one: 'Celková hodnota portfólia',
		valueTitle_few: 'Celková hodnota portfólií',
		valueTitle_other: 'Celková hodnota portfólií',
		netGrowthTitle: 'Pohyb hodnoty',
		netGrowthTitleTooltip: 'Pohyb hodnoty portfólia po odpočítaní vkladov, výberov a\u00a0poplatkov z\u00a0Fondee.',
		netGrowthRatioTitle: 'Pohyb hodnoty v\u00a0%',
		netGrowthRatioTitleTooltip: 'Percentuálny vývoj hodnoty portfólia sa vypočíta z\u00a0pomeru aktuálnej hodnoty '
			+ 'portfólia k\u00a0výške vkladu po odpočítaní výberov a\u00a0odmien. Ak počas obdobia došlo k\u00a0viacerým vkladom, '
			+ 'výberom alebo odmenám, vážená hodnota vkladov, výberov alebo odmien sa započíta podľa toho, kedy sa uskutočnili. '
			+ 'Ak ste napríklad pred šiestimi mesiacmi začali investovať 100\u00a0000\u00a0Kč, včera ste vložili ďalších '
			+ '100\u00a0000\u00a0Kč a\u00a0aktuálna hodnota vášho portfólia je 210\u00a0000\u00a0Kč, pohyb hodnoty bude približne '
			+ '10\u00a0%, pretože novo vložených 100\u00a0000\u00a0Kč sa ešte nezhodnotilo.',
		noActivePortfolio: 'Nemáte aktívne žiadne portfólio',
	},
	select: {
		title: 'Moje portfóliá',
		button: 'Vytvorenie portfólia',
		disabledTooltip: 'Užívateľ bude aktivovaný po kontrole údajov a\u00a0alokácii prvého vkladu.',
		back: 'Späť',
		archive: {
			button: 'Zobraziť archivované portfóliá',
			title: 'Zoznam archivovaných portfólií',
			name: 'Názov portfólia',
			select: 'Zobraziť',
			back: 'Späť na\u00a0portfólia',
		},
	},
	new: {
		modal: {
			title: 'Vyberte typ portfólia',
			subtitle: 'Môžete si vybrať vytvorenie portfólia dôchodkov.',
			input: 'Názov portfólia',
			nameTooltip: 'Názov portfólia slúži len na uľahčenie.',
			childInfo: 'Ak založenie detského portfólia nedokončíte, môžete sa k\u00a0nemu kedykoľvek vrátiť v\u00a0sekcii '
				+ 'Môj účet -\u00a0Moje deti.',
			add: 'Vytvorenie portfólia',
			close: 'Zavrieť',
			currency: 'Mena zobrazenia',
			currencyTooltip: 'Toto je mena, v\u00a0ktorej chcete vidieť hodnotu svojho portfólia na\u00a0stránke Portfólio '
				+ 'a\u00a0história. Zobrazovanú menu môžete kedykoľvek zmeniť a\u00a0nemá to vplyv na zloženie vášho portfólia.',
			additional: 'Ďalšie nastavenia',
			rebalancingActive: 'Povolenie možnosti automatického vyrovnávania',
			rebalancingTooltip: 'Začiarknite toto políčko, ak chcete, aby sme raz mesačne automaticky vyvážili vaše portfólio '
				+ 'podľa vami zvoleného investičného profilu. Túto možnosť môžete kedykoľvek zmeniť v\u00a0nastaveniach profilu. '
				+ 'Táto možnosť nemá vplyv na\u00a0investovanie vkladov ani na\u00a0spracovanie výberov.',
			newConsent: 'Súhlasím s novými <0>obchodnými podmienkami</0>.',
			newConsentPension: 'Súhlasím s novými <0>obchodnými podmienkami</0> pre založenie dôchodkového účtu.',
			errorClosure: 'Nie je možné založiť nové portfólio, pretože máte zadané zrušenie Vášho účtu. Zrušenie môžete '
				+ 'stornovať v\u00a0sekcii <0>Môj účet</0>.',
			warning: {
				limit: {
					title: 'Nové portfólio nie je možné vytvoriť',
					text: 'Je nám ľúto, ale maximálny počet portfólií na\u00a0jedno používateľské konto je\u00a05.',
				},
				limitChild: {
					title: 'Nové portfólio nemožno založiť',
					text: 'Ospravedlňujeme sa, ale maximálny počet portfólií na jeden detský účet je 3.',
				},
				limitPension: {
					title: 'Nové portfólio nemožno založiť',
					text: 'Aktuálne je možné mať iba jeden dôchodkový účet.',
				},
			},
			success: {
				title: 'Vytvorené nové portfólio',
				text: 'Máte nové portfólio! Ak ho chcete aktivovať, musíte naň poslať zálohu. Kliknite na\u00a0kartu Portfólio '
					+ 'v\u00a0ponuke a\u00a0vyberte ju. Na stránke Portfólio vyberte položku Chcem vložiť peniaze. Druhou možnosťou je '
					+ 'presunúť peniaze z\u00a0existujúceho portfólia. V\u00a0ponuke vyberte portfólio, z\u00a0ktorého chcete previesť '
					+ 'peniaze alebo časť ETF, a\u00a0na\u00a0stránke Portfólio vyberte položku Previesť do iného portfólia."',
			},
		},
		childList: {
			title: 'Meno dieťaťa',
			select: 'Zvoliť',
			addChild: 'Pozvať ďalšie dieťa',
		},
		form: {
			name: {
				empty: 'Zabudli ste vyplniť názov portfólia',
				error: 'Názov portfólia nemôže začínať medzerou',
				unique: 'Tento názov portfólia už používate.',
			},
		},
		create: 'Pridať portfólio',
	},
	settings: {
		title: 'Nastavenie portfólia',
		name: {
			label: 'Upraviť názov portfólia',
			button: 'Zmeniť názov',
		},
		currency: {
			title: 'Zobrazenie meny portfólia',
			success: 'Mena zobrazenia portfólia zmenená. Pokiaľ si prajete posielať vklady v\u00a0inej mene, pozrite sa na inštrukcie <0>sem</0>.',
		},
		additional: {
			title: 'Ďalšie nastavenia',
		},
		pensionConfirmation: {
			download: '',
		},
	},
};

const portfolioComposition = {
	title: 'Rozloženie portfólia',
	profile: 'Investičný profil:',
	plannedProfile: 'Plánovaný investičný profil:',
	profileTooltip: 'Investičný profil si môžete zmeniť v\u00a0<0>nastavení svojho účtu</0>. Zmena profilu sa '
		+ 'prejaví až po vyvážení portfólia (rebalansingu). Aktuálne informácie nájdete v\u00a0bloku Obchodovanie.',
	table: {
		columns: {
			name: 'Názov',
			isin: 'ISIN',
			amount: 'Kusy',
			pricePerVolume: 'Cena za kus',
			totalValue: 'Celková hodnota',
			information: 'Kľúčové informácie pro\u00a0investora',
		},
		rows: {
			cash: 'Hotovosť na účte',
			exchange: 'Výmenný kurz',
			total: 'Celková hodnota Vášho portfólia',
			totalTooltip: 'Kvôli zaokrúhleniu na riadkoch nemusí celková hodnota portfólia presne zodpovedať súčtu jednotlivých hodnôt.',
		},
	},
};

const portfolioFeesEtfs = {
	title: 'Ako sa počítajú ETF poplatky',
	text: 'Každý fond ETF má svoje vlastné poplatky, ktoré sa pohybujú od 0,05\u00a0% do 0,47\u00a0% z\u00a0objemu '
		+ 'zainvestovaných prostriedkov za rok. Fondee skladá investičné portfólia až zo 14\u00a0rôznych ETF. Celkové poplatky, '
		+ 'ktoré zaplatíte za ETF, teda záležia na poplatku jednotlivých ETF a\u00a0ich zastúpení vo Vašom portfóliu. '
		+ 'Zvyčajne sa poplatky pohybujú okolo 0,15\u00a0% z\u00a0celkového objemu investícií za\u00a0rok a\u00a0sú už '
		+ 'zahrnuté v\u00a0hodnote ETF, takže ich neuvidíte v\u00a0prehľade histórie zmien svojho investičného portfólia.',
	userFee: 'Váš aktuálny celkový poplatok za ETF je {{ fee }} ročne.',
	link: 'Zobraziť tabuľku s\u00a0kompletným prehľadom.',
	modal: {
		title: 'ETF poplatky',
		name: 'Názov',
		ter: 'Ročný poplatok (TER) za ETF',
	},
};

const portfolioFeesOverview = {
	title: 'Poplatky za správu portfólia',
	table: {
		columns: {
			year: 'Ročne',
			month: 'Tento mesiac *',
		},
		rows: {
			fondee: 'Poplatky Fondee',
			etf: 'Poplatky ETFiek',
			total: 'Celkové poplatky',
		},
		discount: 'zľava do {{ date }}',
	},
	note: '*mesačné poplatky sa vypočítavajú z\u00a0konečnej hodnoty portfólia na posledný obchodný deň '
		+ 'v\u00a0mesiaci a\u00a0sú strhnuté na\u00a0začiatku nasledujúceho mesiaca',
	text: 'Poplatky za ETF sú už zahrnuté v\u00a0ich hodnote, na\u00a0výpise investičného '
		+ 'účtu preto nie sú vidieť. Uvádzame ich tu pre Vašu predstavu o\u00a0celkových poplatkoch. Poplatky pre '
		+ 'každé ETF je možné dohľadať v\u00a0tzv. Kľúčových informáciach\u00a0investora.',
};

const portfolioTable = {
	title_one: 'Portfólio',
	title_few: 'Portfóliá',
	title_other: 'Portfóliá',
	select: {
		label: 'Zhodnotenie za:',
	},
	table: {
		columns: {
			name: 'Názov portfólia',
			value: 'Hodnota',
			appreciation: 'Zhodnotenie',
		},
		more: 'Prečítajte si viac',
		add: 'Pridať portfólio',
	},
};

const privacyPolicy = {
	title: 'Ochrana osobných údajov',
	intro: '<0>Spoločnosť Fondee a.s., so sídlom Rumunská 22/28, Vinohrady, 120\u00a000 Praha\u00a02, IČO: '
		+ '06691862, spisová značka B\u00a027322 vedená na Mestskom súde v\u00a0Prahe, ako správca '
		+ 'osobných údajov (ďalej len „<0>Správca</0>“), </0>'
		+ '<0>by týmto rada v\u00a0súlade s ustanovením čl. 13 Nariadenia Európskeho parlamentu a\u00a0Rady '
		+ '(EÚ) č. 2016/679 zo dňa 27. apríla 2016 o\u00a0ochrane fyzických osôb v\u00a0súvislosti so '
		+ 'spracovaním osobných údajov a\u00a0o voľnom pohybe týchto údajov a\u00a0o zrušení smernice '
		+ '95/46/ES (všeobecného nariadenia o\u00a0ochrane osobných údajov) (ďalej len „<0>Nariadenie '
		+ 'GDPR</0>“), </0>'
		+ '<0>informovala svojich zákazníkov a\u00a0potenciálnych zákazníkov (ďalej len „<0>Subjekty údajov</0>“, '
		+ 'jednotlivo „<0>Subjekt údajov</0>“) o\u00a0nasledujúcom: </0>',
	text: '<0>Správca je v\u00a0súlade s povolením Českej národnej banky oprávnený ponúkať a\u00a0'
		+ 'poskytovať svojim zákazníkom nasledujúce hlavné investičné služby: (i) prijímanie a\u00a0'
		+ 'odovzdávanie pokynov\u00a0týkajúcich sa investičných nástrojov v\u00a0zmysle §\u00a04\u00a0ods.\u00a02\u00a0'
		+ 'písm.\u00a0a) zákona č. 256/2004 Zb. o\u00a0podnikaní na kapitálovom trhu v\u00a0znení neskorších '
		+ 'predpisov (ďalej len „<0>ZPKT</0>“), (ii) obhospodarovanie majetku zákazníka, ktorého '
		+ 'súčasťou je investičný nástroj, na základe voľnej úvahy v\u00a0rámci zmluvného '
		+ 'dojednania v\u00a0zmysle §\u00a04\u00a0ods. 2 písm. d) ZPKT, a\u00a0(iii) investičné poradenstvo týkajúce '
		+ 'sa investičných nástrojov v\u00a0zmysle §\u00a04\u00a0ods.\u00a02\u00a0písm.\u00a0e) ZPKT, a\u00a0to vo vzťahu k '
		+ 'cenným papierom kolektívneho investovania ak investičným nástrojom podľa §\u00a03\u00a0'
		+ 'ods.\u00a01\u00a0písm.\u00a0d) ZPKT (všetky tieto investičné služby ďalej len „<0>Činnosť</0>“).</0>'
		+ '<0>Osobné údaje odovzdané Subjektom údajov, budú Správcom spracovávané iba v\u00a0rozsahu '
		+ 'potrebnom pre daný účel a\u00a0po dobu, ktorá je nevyhnutná pre splnenie tohto účelu. </0>'
		+ '<0>Správca spracováva osobné údaje Subjektov údajov za účelom </0>'
		+ '<1><0>jeho Činnosti, teda rokovania o\u00a0uzavretí alebo zmene zmluvy a\u00a0splnení zmluvy, a\u00a0'
		+ 'to vrátane vybavovania prípadných nárokov a\u00a0žiadostí Subjektu údajov;</0>'
		+ '<0>plnenie právnych povinností Správcu, napr. podľa právnych predpisov '
		+ 'upravujúcich činnosť Správcu, ďalej predovšetkým zákonné povinnosti v\u00a0'
		+ 'súvislosti s ochranou spotrebiteľa, archiváciou dokumentov, vedením účtovníctva '
		+ 'a správy daní;</0>'
		+ '<0>oprávneného záujmu Správcu, jedná sa najmä o\u00a0spracovanie osobných údajov '
		+ 'za účelom vedenia súdnych sporov, správnych či obdobných konaní, prípadne '
		+ 'vymáhania pohľadávok, avšak za predpokladu, že záujmy a\u00a0základné práva a\u00a0'
		+ 'slobody Subjektu údajov neprevažujú oprávnený záujem Správcu. Z dôvodu '
		+ 'oprávneného záujmu ďalej spracováva Správca osobné údaje pre vnútorné '
		+ 'administratívne účely (napr. interná evidencia, reporting atp.), pre riadenie kvality '
		+ 'poskytovaných služieb a\u00a0vzťahov so Subjektmi údajov a\u00a0pre riadenie rizík; alebo</0>'
		+ '<0>stanovenom v\u00a0súhlase so spracovaním osobných údajov udelenej Subjektom '
		+ 'údajov. Vo vzťahu k súborom cookie je možné súhlas udeliť pre všetky skupiny '
		+ 'súborov cookie alebo pre vybranú skupinu súborov cookie. Tento súhlas so '
		+ 'spracovaním osobných údajov je dobrovoľný a\u00a0Subjekt údajov ho môže '
		+ 'kedykoľvek odvolať. So súhlasom spracováva Správca osobitnej kategórie '
		+ 'osobných údajov (zdravotný stav) a\u00a0osobné údaje na marketingové účely.</0></1>'
		+ '<0>Po splnení pôvodného účelu (napríklad plnenia zmluvy) môže Správca osobné údaje '
		+ 'spracovávať na iné účely (napríklad na plnenie zákonnej archivačnej doby).</0>'
		+ '<0>Pre vyššie uvedené účely Správca spracováva najmä nasledujúce osobné údaje</0>'
		+ '<1><0>identifikačné a\u00a0adresné údaje: akademický titul, meno, priezvisko, dátum '
		+ 'narodenia, miesto narodenia, rodné číslo, údaje o\u00a0dokladoch totožnosti (vrátane '
		+ 'prípadného vyhotovenia fotokópie dokladu totožnosti pri vzniku obchodného '
		+ 'vzťahu alebo v\u00a0jeho priebehu v\u00a0súlade s právnymi predpismi), pohlavie, adresa '
		+ 'trvalého bydliska, doručovacia alebo iná kontaktná adresa; </0>'
		+ '<0>ďalšie údaje o\u00a0Subjekte údajov alebo so Subjektom údajov súvisiace, napr. '
		+ 'rodinný stav alebo referenčný kód pre registráciu u Správcu; </0>'
		+ '<0>elektronické kontaktné údaje: (mobilné) telefónne číslo, e-mailová adresa;</0>'
		+ '<0>iné elektronické údaje: IP adresa, lokačné údaje používaného zariadenia;</0>'
		+ '<0>ďalšie osobné údaje potrebné na plnenie zmluvy: číslo bankového účtu, výška '
		+ 'platieb a\u00a0ich história; </0>'
		+ '<0>informácie o\u00a0Subjekte údajov z dôvodu plnenia právnych povinností (napr. podľa '
		+ 'zákona č. 253/2008 Zb., o\u00a0niektorých opatreniach proti legalizácii výnosov z '
		+ 'trestnej činnosti a\u00a0financovania terorizmu, v\u00a0znení neskorších predpisov (ďalej len '
		+ '„AML zákon“)), a\u00a0to vrátane fotografie zhotovenej Subjektom údajov, Správcom '
		+ 'alebo jemu zmluvne zaviazanou treťou osobou za účelom overenia zhody '
		+ 'podoby s vyobrazením v\u00a0doklade totožnosti v\u00a0zmysle AML zákona, informácie z '
		+ 'verejne dostupných aj neverejných registrov a\u00a0evidencií;</0>'
		+ '<0>ďalšie osobné údaje poskytnuté Subjektom údajov v\u00a0zmluve alebo v\u00a0iných '
		+ 'dokumentoch, pri telefonickej či e-mailovej komunikácii a</0>'
		+ '<0>záznamy telefonických hovorov a\u00a0informácie z týchto záznamov. </0></1>'
		+ '<0>Osobné údaje Správca získava priamo od Subjektov údajov (najmä v\u00a0prípade rokovaní o\u00a0'
		+ 'zmluve a\u00a0pri inej komunikácii so Subjektom údajov), od tretích osôb (najmä od orgánov '
		+ 'štátnej správy pri plnení zákonných povinností alebo na základe osobitných právnych '
		+ 'predpisov), z verejne dostupných zdrojov ( najmä verejných registrov), alebo z vlastnej '
		+ 'činnosti (najmä vyhodnotením a\u00a0analýzou osobných údajov získaných z iných, skôr '
		+ 'uvedených, zdrojov). Správca získava osobné údaje v\u00a0obmedzenom rozsahu automaticky aj '
		+ 'používaním súborov cookie na webových stránkach Správcu. </0>'
		+ '<0>Osobné údaje Subjektu údajov budú spracované korektne a\u00a0zákonným a\u00a0transparentným '
		+ 'spôsobom. Budú spracované iba také osobné údaje, ktoré sú primerané, relevantné a\u00a0'
		+ 'obmedzené na nevyhnutný rozsah vo vzťahu k účelu, na ktorý sú spracovávané. </0>'
		+ '<0>Osobné údaje Subjektu údajov budú spracované spôsobom, ktorý zaistí ich náležité '
		+ 'zabezpečenie, vrátane ich ochrany pomocou vhodných technických alebo organizačných '
		+ 'opatrení, pred neoprávneným či protiprávnym spracovaním a\u00a0pred náhodnou stratou, '
		+ 'zničením alebo poškodením. </0>'
		+ '<0>Správca spracováva iba presné osobné údaje získané v\u00a0súlade s Nariadením GDPR a\u00a0má '
		+ 'povinnosť spracovávané osobné údaje aktualizovať. Za tým účelom sú Subjekty údajov, '
		+ 'ktorých osobné údaje sú spracovávané, povinní bezodkladne nahlásiť Správcovi akúkoľvek '
		+ 'ich zmenu a\u00a0predložiť Správcovi dokumenty zmenu preukazujúcu.</0>'
		+ '<0>Osobné údaje Subjektu údajov budú spracovávané po dobu platnosti zmluvy alebo jej '
		+ 'správy Správcom a\u00a0následne kým trvá oprávnený záujem (najmä počas behu premlčacej '
		+ 'lehoty na plnenie zo zmluvy (max. 10 rokov) od jej ukončenia alebo odo dňa ukončenia '
		+ 'správy av prípade začatia súdneho, rozhodcovského, exekučného alebo iného obdobného '
		+ 'konania počas tohto konania a\u00a0následne počas behu lehôt na podanie mimoriadnych '
		+ 'opravných prostriedkov a\u00a0počas konania o\u00a0podaných mimoriadnych opravných '
		+ 'prostriedkoch), ďalej po dobu udelenia súhlasu Subjektu údajov so spracovaním, pokiaľ bol '
		+ 'súhlas udelený, ďalej po dobu existencie právnej povinnosti, ktorá sa na Správcu vzťahuje '
		+ '(finančná správa, dozorný orgán, archivačné predpisy) či na účely oprávnených záujmov '
		+ 'Správcu (súdny spor). Správca spracováva osobné údaje výhradne na vymedzené účely v\u00a0'
		+ 'súlade s existujúcim právnym titulom na spracovanie. Ak nedôjde k uzavretiu zmluvy so '
		+ 'Subjektom údajov, spracováva Správca osobné údaje, ktoré v\u00a0súvislosti s uzatváraním '
		+ 'zmluvy získal, po dobu piatich rokov. Záznamy hovorov na zákaznícku linku zaznamenáva '
		+ 'Správca po dobu piatich rokov. </0>'
		+ '<0>Subjekt údajov má právo kedykoľvek odvolať udelený súhlas so spracovaním svojich '
		+ 'osobných údajov či výslovný súhlas so spracovaním zvláštnej kategórie osobných údajov '
		+ 'bez toho, aby tým bola dotknutá zákonnosť spracovania založená na súhlase udelenom '
		+ 'pred jeho odvolaním. Podrobnejšie informácie o\u00a0odvolaní súhlasu s použitím súborov cookie '
		+ 'sú uvedené v\u00a0<0>Zásadách používania súborov cookie.</0></0>'
		+ '<0>Osobné údaje Subjektu údajov môžu byť na účely Činnosti Správcu poskytnuté subjektom '
		+ 'prevádzkujúcim investičné služby a\u00a0ďalším finančným inštitúciám v\u00a0zmluvnom vzťahu so '
		+ 'Správcom, viazaným zástupcom Správcu, oprávneným zamestnancom a\u00a0pracovníkom '
		+ 'Správcu a\u00a0ďalej osobám, ktoré Správcovi poskytujú IT, programátorské, právne, účtovné a\u00a0'
		+ 'poradenské služby v\u00a0záujme zaistenia riadneho plnenia povinností stanovených všeobecne '
		+ 'záväznými právnymi predpismi a\u00a0zmluvnými záväzkami, prípadne ak ukladajú poskytnutie '
		+ 'osobných údajov právne predpisy alebo je to nutné pre ochranu oprávnených záujmov '
		+ 'Správca. Osobné údaje ďalej môžu byť poskytnuté podľa zákona orgánom činným v\u00a0'
		+ 'trestnom konaní, súdom, ČNB, správcovi dane, finančnej správe, exekútorom či '
		+ 'insolvenčným správcom, ČKP a\u00a0iným orgánom verejnej moci. </0>'
		+ '<0>Ak sú Správcovi poskytnuté pri plnení zmluvy Subjektom údajov aj osobné údaje ďalších '
		+ 'fyzických osôb, ku ktorých spracovaniu zo strany Správcu dochádza, nesie zodpovednosť '
		+ 'za splnenie povinností v\u00a0oblasti ochrany osobných údajov voči týmto fyzickým osobám '
		+ '(najmä ich informovanie o\u00a0spracovaní osobných údajov) daný Subjekt údajov. </0>'
		+ '<0>Správca nemá v\u00a0úmysle odovzdať osobné údaje subjektu údajov do tretej krajiny (tzn. mimo '
		+ 'Európskej únie alebo Európskeho hospodárskeho priestoru) ani medzinárodnej organizácii. '
		+ 'Ak však Subjekt údajov prijal alebo vybral cookie súbory, ktoré sú poskytované spoločnosťou '
		+ 'Google, Facebook, Twitter alebo YouTube, vyjadril tým svoj výslovný súhlas s prenosom '
		+ 'osobných údajov do tretích krajín (predovšetkým do USA). Správca však upozorňuje na '
		+ 'skutočnosť, že po odovzdaní osobných údajov do tretích krajín nemá Správca dostatočné '
		+ 'právne a\u00a0faktické nástroje na zaistenie bezpečnosti osobných údajov užívateľov. </0>'
		+ '<0>Správca nemenoval poverencov na ochranu osobných údajov, ale určil osobu, ktorá má túto '
		+ 'oblasť na starosti a\u00a0ktorá dohliada na riadne spracovanie osobných údajov Subjektu údajov. </0>'
		+ '<0>Subjekt údajov má právo požadovať od Správcu prístup k svojim osobným údajom a\u00a0na '
		+ 'obstaranie kópie osobných údajov, ktoré o\u00a0ňom Správca spracováva, ich opravu alebo '
		+ 'výmaz, poprípade obmedzenie spracovania a\u00a0vzniesť námietku proti spracovaniu, má právo '
		+ 'na prenos týchto údajov k inému správcovi, ako aj právo podať sťažnosť na Úrad pre '
		+ 'ochranu osobných údajov (Pplk. Sochora\u00a027, 170\u00a000 Praha\u00a07, webová stránka: '
		+ '<1>www.uoou.cz</1>), pokiaľ sa domnieva, že Správca pri spracovaní osobných údajov postupuje v\u00a0'
		+ 'rozpore s Nariadením GDPR, prípadne tiež môže uplatňovať svoje práva na príslušnom '
		+ 'súde. </0>'
		+ '<0>Kontaktné údaje Spravcu pre spracovanie osobných údajov sú:</0>',
	contact: '<0>Jan Hlavsa</0>'
		+ '<0>Email: <0>honza@fondee.cz</0></0>',
	consent: {
		title: 'Na odvolanie súhlasu so spracovaním osobných údajov odošlite následujúce údaje '
			+ 'na adresu <0>info@fondee.cz</0>:',
		text: '<0>Odvolanie súhlasu so spracovaním osobných údajov</0>'
			+ '<1>Meno:</1>'
			+ '<1>Priezvisko:</1>'
			+ '<1>E-mail:</1>'
			+ '<1>Telefón:</1>',
		email: 'info@fondee.cz',
	},
};

const products = {
	child: {
		title: 'Investície pre deti',
		text: 'Nenechajte úspory pre deti ležať v\u00a0banke, kde strácajú hodnotu. Založte im investičné portfólio online a\u00a0so '
			+ '<0>zníženým poplatkom</0>. Jeden trvalý príkaz a\u00a0máte vybavené!',
		button: 'Chcem investovať pre deti',
		profileDetail: {
			title: 'Preskúmajte portfóliá pre deti',
		},
		fees: {
			title: 'Poplatok',
			text: '<0>Výška poplatku sa počíta z hodnoty portfólia a\u00a0raz mesačne sa strhne 1/12 ročného poplatku. v\u00a0cene získavate:</0>',
			list: '<0>spracovanie vkladov a\u00a0výberov v\u00a0neobmedzenom množstve</0>'
				+ '<0>neobmedzený počet nákupov a\u00a0predajov ETF</0>'
				+ '<0><1>rebalansovanie</1> (pokiaľ si ho nechcete dobrovoľne vypnúť)</0>'
				+ '<0>spracovanie prichádzajúcich dividend (týka sa klasických portfólií)</0>'
				+ '<0>daňové podklady pre prípadné podanie daňového priznania</0>'
				+ '<0>zákaznickú podporu na chate, e-maile a\u00a0telefóne</0>',
		},
		terms: {
			title: 'Podmienky',
			text: '<0>Prístupy a\u00a0vlastníctvo investícií</0>'
				+ '<1>Detský investičný účet zakladá rodič (alebo iný zákonný zástupca). Dieťa má svoje prístupové údaje, aby mohlo '
				+ 'sledovať a\u00a0učiť sa, ako sa investície správajú. V\u00a0deň 18. narodenín sa z detského účtu stáva bežný investičný '
				+ 'účet s bežnou výškou poplatku a\u00a0prechádza pod plnú správu dieťaťa. To sa potom rozhodne, či si celú sumu vyberie, '
				+ 'prípadne časť peňazí alebo všetkyy nechá ďalej zhodnocovať.</1>'
				+ '<0>Portfóliá a\u00a0limity pre vklady</0>'
				+ '<1>Chcete dieťaťu investovať na niekoľko cieľov a\u00a0nestačí vám jedno portfólio? Môžete založiť až 3 portfóliá s '
				+ 'rôzně odvážnym investičným profilom. Prispievať do nich môžu nie len rodičia, ale aj ďaľší príbuzní! Do poznámky k '
				+ 'platbe len vložia číslo portfólia, aby sa platba správne pripísala. Zakladateľ detského účtu a\u00a0klienti Fondee s '
				+ 'overeným bankovým účtom posielajú vklady do detských portfólií bez obmedzení. Osoby, ktoré nie sú klientmi Fondee, '
				+ 'môžu každému dieťaťu poslať maximálne 900 € ročne z jedného bankového účtu.</1>'
				+ '<0>Výber peňazí pred 18. narodeninami</0>'
				+ '<1>Keby ste potrebovali v\u00a0krajnej situácii peniaze vybrať pred tým, než vaše dieťa sfúkne 18 sviečok na torte, '
				+ 'nie je to problém – poplatok sa iba dopočíta do našej bežnej výšky 1,09\u00a0% ročne a\u00a0peniaze máte do\u00a0týždňa '
				+ 'na účte.</1>',
		},
		benefits: {
			title: 'Prečo investovať pre deti s Fondee?',
			list: [
				'<0>Nižšie poplatky</0>'
				+ '<1>Radi vás podporíme v\u00a0dlhodobom investovaní pre deti, preto na detských účtoch ponúkame zvýhodnený poplatok '
				+ '0,24\u00a0% (o 0,85\u00a0% nižší než u ostatných Fondee investícii!).</1>',
				'<0>Zabezpečenie budúcnosti</0>'
				+ '<1>Odovzdajte deťom sumu, ktorá im v\u00a0budúcnosti skutočne pomôže. O\u00a0pár rokov si za dnešnú stovku nekúpia to, '
				+ 'čo teraz. Investície majú narozdiel od sporenia šancu poraziť <4>infláciu.</4></1>',
				'<0>Vzdelávanie detí</0>'
				+ '<1>Nie je to iba o\u00a0peniazoch na školu alebo prvé bývanie. Deti môžu mať svoj prístup do Fondee a\u00a0sledovať, ako '
				+ 'sa investícii darí. Učia sa tak o\u00a0financiách a\u00a0získávajú cenné skúsenosti.</1>',
			],
		},
	},
	classic: {
		title: 'Investícia na mieru',
		text: 'Investície tak jednoduché, že fungujú samé. Zhodnoťte svoje peniaze online, s nízkymi poplatkami a\u00a0<0>už '
			+ 'od 40\u00a0€</0>. Vyberte si zo 7\u00a0klasických portfólií a\u00a0investujete do stoviek akcií a\u00a0dlhopisov '
			+ 'z\u00a0celého sveta.',
		button: 'Chcem investovať',
		profileDetail: {
			title: 'Preskúmajte klasické portfóliá',
		},
		fees: {
			title: 'Poplatok',
			text: '<0>Výška poplatku sa počíta z hodnoty portfólia a\u00a0raz mesačne sa strhne 1/12 ročného poplatku. v\u00a0cene získavate:</0>',
			list: '<0>spracovanie vkladov a\u00a0výberov v\u00a0neobmedzenom množstve</0>'
				+ '<0>neobmedzený počet nákupov a\u00a0predajov ETF</0>'
				+ '<0><1>rebalansovanie</1> (pokiaľ si ho nechcete dobrovoľne vypnúť)</0>'
				+ '<0>spracovanie prichádzajúcich dividend (týka sa klasických portfólií)</0>'
				+ '<0>daňové podklady pre prípadné podanie daňového priznania</0>'
				+ '<0>zákaznickú podporu na chate, e-maile a\u00a0telefóne</0>',
		},
		benefits: {
			title: 'Prečo investovať s Fondee?',
			list: [
				'<0>Diverzifikácia</0>'
				+ '<1>Nevsádzajte na jednu kartu. Fondee portfóliá obsahujú stovky akcií a\u00a0dlhopisov <0>z rôznych krajín '
				+ 'a odborov</0>. Vďaka kvalitnej diverzifikácii hodnotu vašej investície neohrozí neúspech jednej firmy alebo odvetvia.</1>',
				'<0>Flexibilita</0>'
				+ '<1>Fondee sa vám prispôsobí. Investujte, koľko chcete. Investujte, kedy chcete. Založte si až '
				+ '<0>5 rôznych portfólií</0> zároveň. A\u00a0keď sa rozhodnete peniaze vybrať, <0>do\u00a0týždňa</0> ich máte na účte.</1>',
				'<0>Bezpečnosť</0>'
				+ '<1>Na nás dohliada <0>Česká národná banka</0> a\u00a0vaše údaje stráži šifrovaná komunikácia. Keby ste prišli o\u00a0'
				+ 'heslo, peniaze sa <0>nedajú poslať na cudzí účet</0> ani zmeniť osobné dáta bez autorizácie a\u00a0predložení dokladu.</1>',
			],
		},
	},
	esg: {
		title: 'Udržateľné investície',
		text: 'Výnosy, alebo zodpovednosť a\u00a0hodnoty? Získajte oboje! Investujte udržateľne do ESG portfólií a\u00a0zhodnoťte '
			+ 'svoje peniaze s dobrým pocitom, že prispievate k <0>lepšej budúcnosti</0> – svojej aj nás všetkých.',
		button: 'Chcem investovať udržateľne',
		profileDetail: {
			title: 'Preskúmajte ESG portfóliá',
		},
		fees: {
			title: 'Poplatok',
			text: '<0>Výška poplatku sa počíta z hodnoty portfólia a\u00a0raz mesačne sa strhne 1/12 ročného poplatku. v\u00a0cene získavate:</0>',
			list: '<0>spracovanie vkladov a\u00a0výberov v\u00a0neobmedzenom množstve</0>'
				+ '<0>neobmedzený počet nákupov a\u00a0predajov ETF</0>'
				+ '<0><1>rebalansovanie</1> (pokiaľ si ho nechcete dobrovoľne vypnúť)</0>'
				+ '<0>daňové podklady pre prípadné podanie daňového priznania</0>'
				+ '<0>zákaznickú podporu na chate, e-maile a\u00a0telefóne</0>',
		},
		benefits: {
			title: 'Prečo investovať udržateľne?',
			list: [
				'<0>Správne hodnoty</0>'
				+ '<1>Chcete investovať do spoločností, ktoré majú dobré finančné výsledky a\u00a0zároveň prispievajú k '
				+ '<0>udržitalnejšej budúcnosti</0>? Vyberte si zodpovedné portfóliá zostavené z ETF s najlepším <1>ESG ratingom</1>.</1>',
				'<0>Výnosy</0>'
				+ '<1>Udržiteľné ETF v\u00a0poslednej dobe <2>porážajú tie klasické</2>. ESG dlhodobo zlepšuje finančné výsledky firiem. '
				+ 'ETFká v\u00a0našich portfóliách nevyplácajú, ale <0>reinvestujú dividendy</0> – a\u00a0tým rastie ich hodnota.</1>',
				'<0>Nižšie riziká</0>'
				+ '<1>ESG zohľadňuje <0>riziká, ktoré bežná finančná analýza nerieši</0>, pritom môžu ovplyvniť výkonnosť celej '
				+ 'firmy aj hodnotu vášho portfólia. Tiež sa ukazuje, že zodpovedné investície <3>dlhodobo menej kolíšu</3>.</1>',
			],
		},
	},
	pension: {
		title: 'Penzijný investičný účet',
		text: 'Najlepšie sa o\u00a0seba postaráte vy sami. Nespoliehajte sa na štát a\u00a0dajte zbohom drahému III. pilieru s\u00a0mizernými výnosmi. Odkladajte si na dôchodok <0>jednoducho a\u00a0so\u00a0zníženým poplatkom</0> oproti našim bežným portfóliám.',
		button: 'Chcem bohatší dôchodok',
		profileDetail: {
			title: 'Preskúmajte penzijné portfóliá',
		},
		fees: {
			title: 'Poplatok',
			text: '<0>Výška poplatku sa počíta z hodnoty portfólia a\u00a0raz mesačne sa strhne 1/12 ročného poplatku. V\u00a0cene získavate:</0>',
			list: '<0>spracovanie <0>vkladov a\u00a0výberov</0> v neobmedzenom množstve</0>'
				+ '<0>neobmedzený počet <0>nákupov a predajov ETF</0></0>'
				+ '<0><1>rebalansovanie</1> (pokiaľ si ho nechcete dobrovoľne vypnúť)</0>'
				+ '<0>spracovanie <0>prichádzajúcich dividend</0> (týka sa klasických portfólií)</0>'
				+ '<0><0>daňové podklady</0> pre prípadné podanie daňového priznania</0>'
				+ '<0>zákaznícku podporu na chate, e-maile a\u00a0telefóne</0>',
		},
		terms: {
			title: 'Podmienky',
			text: '<0>Limity pre vek a\u00a0počet portfólií</0>'
				+ '<1>Už ste oslávili <0>18.\u00a0narodeniny</0>? Potom vám v\u00a0otvorení penzijného účtu od Fondee nič nebráni '
				+ 'a\u00a0smelo do toho! Môžete si založiť <0>jedno penzijné portfólio</0>, vďaka ktorému investujete na '
				+ 'svoju starobu s\u00a0<0>ešte výhodnejším poplatkom</0> oproti našim bežným portfóliám.</1>'
				+ '<0>Výber a\u00a0presun prostriedkov</0>'
				+ '<1>Penzijné portfólio slúži pre <0>dlhodobé investovanie</0>. Peniaze preto môžete bezplatne vybrať a\u00a0začať '
				+ 'si ich užívať <0>po vašich 60.\u00a0narodeninách</0> za predpokladu, že ubehlo aspoň <0>10 rokov (120\u00a0mesiacov) '
				+ 'od založenia dôchodkového účtu</0>. Je na vás, či si necháte rovno vyplatiť celú čiastku, alebo ju vyčerpáte '
				+ 'postupne a\u00a0časť necháte ďalej zhodnocovať. Presun peňazí medzi penzijným a\u00a0inými Fondee portfóliami však nie je možný.</1>'
				+ '<0>Predčasný výber a\u00a0zrušenie portfólia</0>'
				+ '<1>Keby ste potrebovali získať peniaze z\u00a0penzijného portfólia skôr než v\u00a060.\u00a0rokoch alebo '
				+ 'pred 10.\u00a0výročím zmluvy, môžete ho <0>predčasne zrušiť</0>. V\u00a0tom prípade však dôjde k\u00a0porušeniu '
				+ 'podmienok –\u00a0dopočítame preto poplatky do našej <0>bežnej výšky 1,09\u00a0%</0> a\u00a0odpočítame ich od vyplatenej čiastky.</1>',
		},
		benefits: {
			title: 'Prečo investovať na dôchodok s\u00a0Fondee?',
			list: [
				'<0>Zabezpečenie na starobu</0>'
				+ '<1>Tiež už neveríte rozprávkam o\u00a0štátnom dôchodku a viete, že <0>sporenie infláciu neporazí</0>? Je čas '
				+ 'chopiť budúcnosť do vlastných rúk. Zabezpečte si pokojný dôchodok a\u00a0začnite investovať –\u00a0<0>s\u00a0nami to zvládnete</0>.</1>',
				'<0>Nižšie poplatky</0>'
				+ '<1>Máme radosť, že si chcete dopriať bohatší dôchodok. Aby sme vás v\u00a0tom podporili, pri penzijných '
				+ 'portfóliách ponúkame <0>zvýhodnený poplatok 0,61\u00a0%</0> ročne (o\u00a00,5\u00a0% nižší než pri ostatných Fondee investíciách!).</1>',
				'<0>Dlhodobé výnosy</0>'
				+ '<1>Vymeňte drahé dôchodkové sporenie v\u00a0DDS s\u00a0mizernými výnosmi za penzijný účet od Fondee. Investujte '
				+ 'pasívne a\u00a0získajte <0>rozumné zhodnotenie bez komplikácií</0>. Stačí jeden trvalý príkaz a\u00a0máte vyriešené.</1>',
			],
		},
	},
	student: {
		title: 'Investície pre študentov',
		text: 'Investujte už popri škole cez Fondee účet pre študentov. Zhodnocujte svoje úspory múdro v\u00a0ETF portfóliách '
			+ '<0>so zníženým poplatkom</0>, možnosťou príspevkov od blízkych a\u00a0bonusom 4\u00a0€ za\u00a0ISIC.',
		button: 'Chcem začať investovať',
		profileDetail: {
			title: 'Preskúmajte študentské portfóliá',
		},
		fees: {
			title: 'Poplatok',
			text: '<0>Výška poplatku sa počíta z hodnoty portfólia a\u00a0raz mesačne sa strhne 1/12 ročného poplatku. V\u00a0cene získavate:</0>',
			list: '<0>spracovanie <0>vkladov a\u00a0výberov</0> v neobmedzenom množstve</0>'
				+ '<0>neobmedzený počet <0>nákupov a predajov ETF</0></0>'
				+ '<0><1>rebalansovanie</1> (pokiaľ si ho nechcete dobrovoľne vypnúť)</0>'
				+ '<0>spracovanie <0>prichádzajúcich dividend</0> (týka sa klasických portfólií)</0>'
				+ '<0><0>daňové podklady</0> pre prípadné podanie daňového priznania</0>'
				+ '<0>zákaznícku podporu na chate, e-maile a\u00a0telefóne</0>',
		},
		terms: {
			title: 'Podmienky',
			text: '<0>Postup založenia študentského účtu</0>'
				+ '<1>Máte <0>18 až 26\u00a0rokov</0>? Poďte s\u00a0nami cez <0><0>ETFká</0></0> investovať do akcií a\u00a0dlhopisov '
				+ 'z\u00a0celého sveta, online a\u00a0so zníženým poplatkom. Pripravte si <0>číslo svojej ISIC karty</0> alebo '
				+ '<0>potvrdenie o\u00a0štúdiu</0> s\u00a0pečiatkou školy a\u00a0nahrajte ho:</1>'
				+ '<1><2>'
				+ '<0><0>Do príslušnej kolónky v\u00a0klientskej zóne</0>, pokiaľ už <0>ste</0> klientom Fondee (máte '
				+ 'u\u00a0nás účet so štandardným poplatkom) –\u00a0zariadime prevod vášho účtu na\u00a0študentský.</0>'
				+ '<0><0>V\u00a0priebehu <0>registrácie</0></0>, pokiaľ ešte <0>nie ste</0> klientom Fondee (nemáte u nás účet).</0>'
				+ '</2></1>'
				+ '<1>Náš tím skontroluje zadané údaje a\u00a0<0>aktivuje vám výhody</0>. Štúdium preukážete iba raz a\u00a0študentský '
				+ 'účet potom môžete využívať až do svojich 26\u00a0rokov. Ak ste vyplnili číslo ISIC, získate navyše bonus 4\u00a0€ '
				+ 'do začiatku! A to je dobrý deal, no nie?</1>'
				+ '<0>Vklady a\u00a0príspevky od rodičov a príbuzných</0>'
				+ '<1>Vo Fondee máte na výber zo 7\u00a0portfólií od konzervatívnych až po odvážne '
				+ '<0>v\u00a0klasickej alebo <2>udržateľnej ESG variante</2></0> –\u00a0otvoriť si môžete až 5\u00a0rôznych portfólií '
				+ 'naraz. Potom posielate vklady <0>v\u00a0ľubovolnej frekvencii a\u00a0výške</0>. Najviac sa vypláca investovať '
				+ 'dlhodobo a\u00a0pravidelne, ale keď sa vám niekedy podarí odložiť viac a\u00a0inokedy menej, nič sa nedeje. '
				+ 'Každý podľa svojich možností, <0>najdôležitejšie je začať</0>.</1>'
				+ '<1>Do investovania v\u00a0rámci študentského účtu môžete <0>zapojiť aj rodičov a\u00a0príbuzných</0> (je '
				+ 'dosť možné, že o\u00a0tom budú počuť radšej než o\u00a0nákupe ďalších topánok alebo iPhonu). Môžu vám posielať '
				+ 'príspevky <0>priamo do Fondee</0>, len im dáte <0>platobné údaje</0> uvedené v\u00a0klientskej zóne a\u00a0informáciu, '
				+ 'nech do poznámky k\u00a0platbe vložia <0>číslo vášho portfólia</0>, aby sa peniaze správne pripísali.</1>'
				+ '<1><2>'
				+ '<0>Každý, kto <0>je klientom Fondee</0>, má možnosť vám z\u00a0bankového účtu prepojeného s\u00a0Fondee '
				+ 'posielať vklady <0>v\u00a0neobmedzenej výške</0>.</0>'
				+ '<0>Všetci ostatní vám môžu prispieť <0>až 1000\u00a0€ za rok</0> z\u00a0jedného čísla účtu.</0>'
				+ '</2></1>'
				+ '<0>Výbery peňazí a\u00a0prechod na štandardný účet</0>'
				+ '<1>Vďaka účtu pre študentov až do 26\u00a0rokov investujete so <0>zvýhodneným poplatkom</0> '
				+ '(0,61\u00a0% ročne) a\u00a0<0>neobmedzenými výbermi</0>. Chápeme, že v\u00a0mladosti nie je ľahké našetriť '
				+ 'dostatočnú <0><3>finančnú rezervu</3></0> a\u00a0v\u00a0priebehu štúdia sa môže čokoľvek prihodiť, preto '
				+ 'výbery peňazí nijako nelimitujeme a\u00a0neovplyvnia ani výšku poplatku. Len k\u00a0nim pristupujte '
				+ 's\u00a0rozvahou –\u00a0investíciám sa najlepšie darí, keď pre vás môžu <0>pracovať dlhodobo</0>.</1>'
				+ '<1>Akonáhle oslávite 26. narodeniny, prepne sa váš Fondee účet <0>zo študentského na\u00a0štandardný</0> '
				+ '(s\u00a0poplatkom 1,09\u00a0% ročne).</1>',
		},
		benefits: {
			title: 'Prečo investovať ako študent s\u00a0Fondee?',
			list: [
				'<0>Čas je na vašej strane</0>'
				+ '<1>Užívajte si štúdium, nechajte pracovať <7>zložené úročenie</7>. Vďaka nemu môžete mať už o\u00a0pár rokov '
				+ 'pekný balík (a\u00a0čo to bude o\u00a0desiatky rokov!). Navyše si od mladosti vybudujete '
				+ '<0>zdravé finančné návyky</0>. Ostáva len prvý krok –\u00a0začať.</1>',
				'<0>Babka k babce, budú kapce.</0>'
				+ '<1><8>Student life is tough enough</8>. Preto na\u00a0účtoch pre mladé ponúkame '
				+ '<0>znížený poplatok 0,61\u00a0% ročne</0> namiesto bežných 1,09\u00a0%. Určite nemusíte hneď odkladať stovky, '
				+ 'aj malé čiastky a\u00a0príspevky od blízkych sa počítajú.</1>',
				'<0>Nie je to žiadna veda</0>'
				+ '<1>Na investovanie nie je nikdy príliš skoro a\u00a0nepotrebujete ani titul z\u00a0ekonómie. Stačí mať mobil '
				+ 'a\u00a0<0>chuť to vyskúšať</0>. Fondee je intuitívna appka, komunikuje ľudsky a\u00a0keby niečo, sme tu pre '
				+ 'vás <0>na chate aj na sieťach</0>.</1>',
			],
		},
	},
	list: {
		title: 'Ostatné produkty',
		classic: {
			title: 'Investícia na mieru',
			text: 'Jeden vklad = stovky akcií a\u00a0dlhopisov z celého sveta. Investujte do ETF online, jednoducho a\u00a0zrozumiteľne.',
			button: 'Viac informácií',
		},
		esg: {
			title: 'Udržateľné investície',
			text: 'Hodnoty a\u00a0výnosy si rozumejú. Investujte udržateľne do portfólií, ktoré v\u00a0posledných rokoch porážajú klasické investície.',
			button: 'Viac informácií',
		},
		child: {
			title: 'Investície pre deti',
			text: 'Nenechajte úspory pre deti ležať v\u00a0banke, kde strácajú hodnotu. Založte investičné portfólio so zníženým poplatkom.',
			button: 'Viac informácií',
		},
		pension: {
			title: 'Penzijný investičný účet',
			text: 'Zabezpečte sa na starobu vďaka múdrym investíciám s\u00a0nízkym poplatkom. Nespoliehajte sa na štát a\u00a0dajte zbohom drahým fondom.',
			button: 'Viac informácií',
		},
		student: {
			title: 'Investície pre študentov',
			text: 'Na investovanie nie je nikdy príliš skoro! Začnite už popri škole –\u00a0otvorte si študentský účet s\u00a0zvýhodneným '
				+ 'poplatkom a\u00a0bonusom za ISIC.',
			button: 'Viac informácií',
		},
	},
};

const productAccount = {
	title: 'Historická výnosnosť',
	toggleOptions: [
		{
			label: 'Hodnota',
			value: 'value',
		},
		{
			label: 'Zhodnotenie',
			value: 'evaluation',
		},
		{
			label: 'Zhodnotenie v\u00a0%',
			value: 'evaluationPercent',
		},
	],
	note: 'Historický výnos vychádza z\u00a0priemerného zhodnotenia ETF zahrnutých v\u00a0portfóliu (alebo z\u00a0vývoja ich '
		+ 'podkladových indexov) za vybrané obdobie, v\u00a0eurách a\u00a0pred započítaním poplatkov. Nezabudnite, že historické '
		+ 'zhodnotenie nie je záruka budúcich výnosov. Ďalšie informácie o\u00a0rizikách investovania sa dozviete '
		+ 'na\u00a0stránke <0>Ako to funguje</0>.',
};

const productSelect = {
	standard: 'Klasický/ESG',
	child: 'Detský účet',
	pension: 'Penzijný',
	student: 'Študentský',
	studentBadge: 'Študent',
};

const profileAvatar = {
	faces: {
		esg: {
			0: 'Profilový obrázok 1',
			1: 'Profilový obrázok 2',
			2: 'Profilový obrázok 3',
			3: 'Profilový obrázok 4',
			4: 'Profilový obrázok 5',
			5: 'Profilový obrázok 6',
			6: 'Profilový obrázok 7',
			7: 'Profilový obrázok 8',
			8: 'Profilový obrázok 9',
			9: 'Profilový obrázok 10',
			10: 'Profilový obrázok 11',
			11: 'Profilový obrázok 12',
			12: 'Profilový obrázok 13',
			13: 'Profilový obrázok 14',
		},
		classic: {
			0: 'Profilový obrázok 1',
			1: 'Profilový obrázok 2',
			2: 'Profilový obrázok 3',
			3: 'Profilový obrázok 4',
			4: 'Profilový obrázok 5',
			5: 'Profilový obrázok 6',
			6: 'Profilový obrázok 7',
			7: 'Profilový obrázok 8',
			8: 'Profilový obrázok 9',
			9: 'Profilový obrázok 10',
			10: 'Profilový obrázok 11',
			11: 'Profilový obrázok 12',
			12: 'Profilový obrázok 13',
			13: 'Profilový obrázok 14',
		},
	},
};

const referrals = {
	title: 'Odporučte Fondee výmenou za dobrý pocit',
	text: 'Ak vám príde Fondee ako dobrá služba, budeme veľmi radi, ak nás odporúčite. '
		+ 'Nič vám za to nedáme. Ale keď niekto využije váš referenčný kód, tak ho odmeníme '
		+ '<0>3\u00a0mesiacmi bez poplatkov</0>, aby si mohol Fondee v\u00a0kľude vyskúšať.',
	code: 'Referenčný kód',
	copyLink: 'Skopírovať odkaz na web Fondee s\u00a0predvyplneným referenčným kódom',
	form: {
		email: {
			label: 'E-mail, kam odporúčanie pošleme',
			error: 'Zabudli ste vyplniť e-mailovú adresu.',
		},
		message: {
			label: 'Vaša správa',
			error: 'Zabudli ste vyplniť správu.',
		},
		messageText: 'Gratulujeme, máte tých najlepších kamarátov! Kto iný by Vám mohol odporučiť investovanie vo Fondee?\n'
			+ 'Navyše, keď zadáte referenčný kód {{ code }} pri registrácii na www.fondee.sk a\u00a0založíte si '
			+ 'investičný účet, odpustíme Vám poplatky za prvé 3\u00a0mesiace! A\u00a0to je predsa skvelá príležitosť, '
			+ 'ako si službu bez starostí vyskúšať, no nie?\n'
			+ 'Keby sme Vám mohli v\u00a0rámci investovania vo Fondee s\u00a0čímkoľvek pomôcť, určite sa ozvite Irys '
			+ 'a\u00a0Kasie na info@fondee.sk!',
		send: 'Odoslať',
		success: 'Ďakujeme, že zdieľate svoj referenčný kód. Pošlite ho aj ďalším kamarátom.',
	},
	emailStats: {
		title: 'E-mail s\u00a0referenčným kódom odoslaný na:',
		used: 'Kód využitý!',
	},
	socialShare: {
		facebook: 'Fondee\u00a0- s\u00a0bonus kódom. Začnite investovať vďaka bonusu od kamaráta.',
		twitter: 'Toto je môj referenčný kód vo Fondee: {{ code }}. Prečo ho zdieľam? Pretože keď si cezeň vytvoríte '
			+ 'investičný účet vo Fondee, dostanete bonus: 3\u00a0mesiace budete mať vo Fondee bez poplatkov. A\u00a0to sa vyplatí!',
		sendVia: 'Poslať cez',
	},
	stats: {
		title: 'Počet klientov, ktorí sa registrovali\ns\u00a0vašim referenčným kódom:',
	},
};

const register = {
	title: 'Chcem začať investovať',
	text: 'K investovaniu s Fondee vedie len pár jednoduchých krokov',
	button: 'Registrovať sa',
	info: 'Vyplňte vaše osobné údaje. Údaje dieťaťa v\u00a0prípade detského účtu sa vypĺňajú v\u00a0ďalšom kroku registrácie.',
	social: {
		or: 'alebo',
		title: 'Registrovať sa cez:',
	},
	emailAlias: {
		title: 'Prihláste sa a\u00a0prepojte svoj účet',
		text: 'Ak už máte existujúci účet, môžete ho prepojiť.',
		button: 'Prepojiť účet',
	},
	privateModal: {
		title: 'Pozor, prihlasujete sa cez anonymný Apple e-mail',
		text: 'Ste si istí, že chcete vytvoriť nový účet cez anonymný Apple e-mail?',
		button: 'Prepojiť účet',
		close: 'Nemám účet, chcem ho vytvoriť. <0>Zaregistrovať sa</0>.',
	},
};

const resetPassword = {
	title: 'Zmena hesla',
	form: {
		button: 'Zmeniť heslo',
	},
};

const scrollToTop = {
	title: 'Späť hore',
};

const signAgreement = {
	title: 'Podpis novej zmluvy a\u00a0doplnenie informácií',
	text: 'Pretože v\u00a0J&T\u00a0banke budeme obchodovať pod našou novou OCP\u00a0licenciou, chceli by sme Vás požiadať '
		+ 'o\u00a0vyplnenie upraveného dotazníku a\u00a0podpis novej zmluvy. Vieme, že je to otrava, ale zákon nám bohužiaľ '
		+ 'nedává na\u00a0výber.',
	info: 'Ak máte akékoľvek otázky, neváhajte napísať Karolovi a\u00a0Davidovi na\u00a0<0>$t(email.address)</0>',
	done: 'Ďakujeme za vyplnenie dotazníku a\u00a0podpis novej zmluvy.\nNič ďalšieho od Vás v\u00a0túto chvíľu '
		+ 'nepotrebujeme. Sme radi, že ste s\u00a0nami! Vaše\u00a0Fondee',
};

const signNewAgreement = {
	title: 'Podpis novej zmluvy',
	text: {
		standard: 'Pokiaľ máte záujem o\u00a0naše štandardné produkty, podpíšte, prosím, novú zmluvu. Zmluva a jej '
			+ 'prílohy upravujú základné náležitosti ako založenie účtu, priebeh investovania a poplatky za služby.',
		pension: 'Pokiaľ máte záujem o\u00a0naše štandardné produkty, podpíšte, prosím, novú zmluvu. Zmluva a jej '
			+ 'prílohy upravujú základné náležitosti ako založenie účtu, priebeh investovania a poplatky za služby.',
	},
	child: {
		title: 'Podpis detskej zmluvy',
		description: 'Na tejto stránke môžete podpísať novú zmluvu k detskému účtu v prípade zmeny v kontaktných alebo osobných údajoch.',
	},
	info: 'Pokiaľ máte akékoľvek otázky, neváhajte nám napísať na\u00a0<0>$t(email.address)</0>.',
	contractTypes: {
		standard: 'Moja zmluva',
		pension: 'Moja zmluva',
	},
};

const studentConfirmation = {
	account: {
		title: 'Zažiadať o\u00a0študentský účet',
		text: 'Študujete na\u00a0strednej alebo vysokej škole a\u00a0máte medzi 18 a\u00a026 rokmi? Máte možnosť '
			+ 'požiadať o\u00a0prevedenie na Študentský účet s\u00a0nižšími poplatkami. Stačí nahrať platnú ISIC kartu '
			+ 'alebo potvrdenie o\u00a0štúdiu. ISIC schválime hneď, potvrdenie o\u00a0štúdiu schválime do niekoľkých '
			+ 'pracovných dní. Viac informácií v\u00a0sekcii Časté otázky.',
		verification: {
			title: 'Čakáme na\u00a0overenie potvrdenia o\u00a0štúdiu',
			text: 'Overujeme platnosť Vášho potvrdenia o\u00a0štúdiu, čo môže trvať pár pracovných dní. '
				+ 'V\u00a0prípade akýchkoľvek otázok nás neváhajte kontaktovať na\u00a0chate alebo na\u00a0<0>info@fondee.sk</0>.',
		},
	},
	onboarding: {
		text: 'Tu nahrajte sken alebo fotku platného potvrdenia o\u00a0štúdiu.',
		subText: 'Musíme overiť potvrdenie o\u00a0štúdiu. Vy môžete zatiaľ v\u00a0pokoji dokončiť registráciu. '
			+ 'Akonáhle potvrdenie overíme, aktivujeme Vám výhody študentského účtu.',
	},
	success: 'Váš účet bol zmenený na študentský',
	toggle: {
		ISIC: 'ISIC',
		confirmation: 'Potvrdenia o\u00a0štúdiu',
	},
	upload: {
		file: 'Nahrať',
		confirm: 'Odoslať',
		list: {
			formats: '<0>Podporované formáty:</0>'
				+ '<1>PNG, JPG, PDF</1>',
			size: '<0> Veľkosť:</0>'
				+ '<1> Maximálna veľkosť 4MB</1>',
		},
		wrongFormat: 'Zlý formát potvrdenia o štúdiu',
	},
	manual: {
		code: 'ISIC',
		confirm: 'Odoslať',
	},
};

const survey = {
	close: 'Zavrieť',
	other: 'Niečo iné:',
	otherPlaceholder: 'Vaša odpoveď…',
	validateError: 'Vyplňte prosím aspoň jednu možnosť.',
	submit: 'Odoslať',
	thanks: 'Ďakujeme!',
};

const taxes = {
	intro: '<0>Otázka zdaňovania príjmov z\u00a0investícií  môže byť pomerne zložitá. <0>Fondee pre Vás '
		+ 'v\u00a0klientskej zóne založilo súkromnú daňovú sekciu</0>, aby Vám pomohlo sa v\u00a0tejto problematike '
		+ 'zorientovať.</0>'
		+ '<0><0>Nájdete tu Vaše dáta z\u00a0obchodovania za minulý rok</0> spracované tak, aby pre Vás boli čo '
		+ 'nejlepším podkladom pri príprave daňového priznania a\u00a0tiež aj odpovede na časté otázky.</0>',
	link: 'Fondee daňový výpis za rok\u00a02024',
	title: '<0>Aby sme Vám pomohli zistiť, či musíte alebo nemusíte podávať daňové priznanie, a\u00a0kam doň prípadne '
		+ 'zaradiť vaše príjmy z\u00a0investovania s\u00a0Fondee, pripravili sme pre Vás nasledujúci dotazník.</0>',
	text: '<0>O\u00a0zdanení príjmov z\u00a0investovania s\u00a0Fondee sa viac dozviete v\u00a0rozšírenej <1>sekcii '
		+ 'FAQ</1> nižšie - Najčastejšie otázky z\u00a0oblasti zdanenia investícií alebo v\u00a0<2>článku na '
		+ 'blogu.</2></0>',
	infoLink: '',
	modal: {
		title: '',
		text: '',
		list: '',
		listText: '',
		subText: '',
	},
};

const taxQuestionnaire = {
	start: 'Spustiť dotazník',
	redo: 'Vyplniť dotazník znova',
	introText: {
		1: {
			text: 'Ďakujeme za vyplnenie dotazníka! Na základe Vašich obchodných výsledkov pri investovaní s\u00a0Fondee v\u00a0minulom '
				+ 'roku máte povinnosť podať daňové priznanie. Prekročili ste hranicu oslobodenia pri príjmoch z\u00a0predaja cenných '
				+ 'papierov a\u00a0/\u00a0alebo hranicu oslobodenia pri príjmoch z\u00a0príležitostných činností, avšak máte možnosť '
				+ 'uplatniť si oslobodenie vo výške 500\u00a0EUR pre vymedzené druhy príjmov v\u00a0zmysle §\u00a09\u00a0zákona '
				+ 'o\u00a0dani z\u00a0príjmov. Vaše príjmy sú uvedené v\u00a0priloženom podklade na prípravu daňového priznania.',
			note: '',
		},
		2: {
			text: 'Ďakujeme za vyplnenie dotazníka! Na základe Vašich obchodných výsledkov pri investovaní s\u00a0Fondee v\u00a0minulom '
				+ 'roku máte povinnosť podať daňové priznanie. Prekročili ste hranicu zdaniteľných príjmov potrebnú na podanie daňového priznania.',
			note: 'Prosím, pokračujte v\u00a0dotazníku, aby sme pre Vás mohli pripraviť podklad pro prípravu daňového priznania.',
		},
		3: {
			text: 'Ďakujeme za vyplnenie dotazníka! Na základe Vašich obchodných výsledkov pri investovaní s\u00a0Fondee v\u00a0minulom '
				+ 'roku môžete mať povinnosť podať daňové priznanie. Ďalší priebeh dotazníku Vám dá konečnú odpoveď.',
			note: 'Prosím, pokračujte v\u00a0dotazníku, aby sme pre Vás mohli pripraviť podklad pro prípravu daňového priznania.',
		},
	},
	text: {
		nonresident: 'Týmto pre Vás dotazník končí, keďže pripravuje podklady na podanie daňového priznania v\u00a0SR. Ak ste '
			+ 'daňovým rezidentom v\u00a0inej krajine než je v\u00a0SR, budete svoje príjmy z\u00a0investovania s\u00a0Fondee '
			+ 'zdaňovať v\u00a0štáte svojej daňovej rezidencie podľa príslušných predpisov. Fondee daňový výpis za minulý rok Vám '
			+ 'k\u00a0tomu poskytne potrebné informácie.',
		commercial: 'Týmto pre Vás dotazník končí, pretože pripravuje podklady na podanie daňového priznania pre súkromných '
			+ 'investorov. Vzhľadom k\u00a0tomu, že ste zaradili svoje investičné portfólio vo Fondee do obchodného majetku, '
			+ 'zdaňujete tieto príjmy v\u00a0rámci svojej samostatnej zárobkovej činnosti (podľa §\u00a06\u00a0ZDP). Fondee daňový '
			+ 'výpis za minulý rok Vám v\u00a0sekcii transakčných výpisov poskytne prehľad o\u00a0realizovaných príjmoch '
			+ 'a\u00a0súvisiacich výdavkoch v\u00a0minulom roku.',
		info: 'Na vyplnenie Vášho daňového priznania Vám môžeme odporučiť <0>interaktívny elektronický vzor daňového priznania '
			+ 'dostupný na internetovej stránke finančnej správy</0>, ktorý za Vás doplní a\u00a0skontroluje všetky výpočty, je '
			+ 'vhodný na vytlačenie aj vygenerovanie xml súboru na elektronické podanie daňového priznania.',
		infoLink: 'https://pfseform.financnasprava.sk/Formulare/eFormVzor/DP/form.546.html',
	},
	resultText: {
		1: 'Ďakujeme za vyplnenie dotazníka! Na základe Vašich odpovedí sme Vám pripravili podklad na prípravu daňového priznania, '
			+ 'kde sú uvedené Vaše kapitálové príjmy, príjmy z\u00a0predaja cenných papierov a\u00a0ostatné príjmy z príležitostnej '
			+ 'činnosti, ak ste ich dosiahli. Máte možnosť uplatniť si oslobodenie vo výške 500\u00a0EUR pre vymedzené druhy príjmov '
			+ 'v\u00a0zmysle §\u00a09\u00a0zákona o\u00a0dani z\u00a0príjmov. Venujte, prosím, pozornosť súvisiacim poznámkam.',
		2: 'Ďakujeme za vyplnenie dotazníka! Na základe Vašich odpovedí sme Vám pripravili podklad na prípravu daňového priznania, '
			+ 'kde sú uvedené Vaše kapitálové príjmy (príjmy z\u00a0predaja cenných papierov a\u00a0ostatné príjmy z príležitostnej '
			+ 'činnosti sú v\u00a0limite na oslobodenie od dane vo výške 500\u00a0EUR, prípadne ste ich nedosiahli).',
		3: 'Ďakujeme za vyplnenie dotazníka! Na základe Vašich odpovedí sme Vám pripravili podklad na prípravu daňového priznania, '
			+ 'kde sú uvedené Vaše príjmy z\u00a0predaja cenných papierov a\u00a0ostatné príjmy z\u00a0príležitostnej činnosti, ak '
			+ 'ste ich dosiahli. Máte možnosť uplatniť si oslobodenie vo výške 500\u00a0EUR pre vymedzené druhy príjmov v\u00a0zmysle '
			+ '§\u00a09\u00a0zákona o\u00a0dani z\u00a0príjmov. Venujte, prosím, pozornosť súvisiacim poznámkam.',
		4: 'Ďakujeme za vyplnenie dotazníka! Na základe Vašich odpovedí je možné usúdiť, že Vaše príjmy z\u00a0investovania '
			+ 's\u00a0Fondee patria medzi príjmy oslobodené alebo neprekračujú limit na podanie daňového priznania.',
		5: 'Ďakujeme za vyplnenie dotazníka! Na základe Vašich odpovedí je možné usúdiť, že Vaše príjmy z\u00a0investovania '
			+ 's\u00a0Fondee patria medzi príjmy oslobodené a\u00a0do daňového priznania sa neuvádzajú.',
	},
	download: 'Stiahnuť formulár daňového priznania',
	continue: 'Pokračovať na ďalší krok',
};

const topUp = {
	currency: 'V\u00a0akej mene chcete vklad poslať?',
	portfolio: 'Do ktorého portfólia chcete vložiť peniaze?',
	modal: {
		title: 'Chcem vložiť peniaze',
		close: 'Zavrieť',
	},
	qr: 'Platba QR kódom',
	payment: {
		title: 'Platbu zašlite na tento účet',
		account: {
			title: 'Číslo účtu',
			info: 'Účet brokera J&T Bank',
			iban: 'IBAN',
			swift: 'BIC',
		},
		note: 'Vložte správu pre príjemcu',
		notePension: '',
		info: {
			owner: 'Meno majiteľa účtu: Fondee\u00a0a.\u00a0s.',
			address: 'Adresa: Rumunská\u00a022/28, 120\u00a000 Praha\u00a02',
		},
		warning: {
			account_one: 'Platbu je nutné vykonať z\u00a0účtu',
			account_few: 'Platbu je nutné vykonať z\u00a0jedného z\u00a0týchto účtov',
			account_other: 'Platbu je nutné vykonať z\u00a0jedného z\u00a0týchto účtov',
			accountChild: 'Platbu preveďte zo\u00a0svojho overeného účtu, <0>pokiaľ si neželáte mať limit vkladov</0>.',
			transfer: '',
			banks: 'Účet musí byť vedený na <0>vaše meno</0>, preto nemôžeme spracovať platby z\u00a0niektorých služieb tretích '
				+ 'strán, ako sú Wise, Revolut alebo Monese, ktoré na odosielanie platieb používajú zberné účty. Ak chcete tieto '
				+ 'služby využívať na investovanie s\u00a0Fondee, odporúčame vám obrátiť sa na <1>info@fondee.sk</1>.',
			note: 'Pokiaľ neuvediete poznámku pre príjemcu, bude platba do\u00a02\u00a0dní automaticky alokovaná k\u00a0primárnemu '
				+ 'portfóliu. Nastavenie je možné zmeniť v\u00a0sekcii Môj účet.',
			pension: '',
			employerNote: '',
			child: '<0>Na\u00a0detské portfóliá môžu neobmedzene vkladať peniaze rodičia, zákonní zástupcovia, ďalšie osoby '
				+ 'a\u00a0deti, ak majú overený bankový účet vo\u00a0Fondee. Vklady od ďalších osôb, ako sú príbuzní alebo známi, '
				+ 'sú limitované sumou 900\u00a0€ ročne na\u00a0jedno dieťa. Pripísanie vkladu prebehne na\u00a0základe uvedenia '
				+ 'unikátneho čísla portfólia dieťaťa v\u00a0poznámke k\u00a0platbe.</0>',
		},
	},
	triggerButton: 'Chcem vložiť peniaze',
	short: 'Vklad',
};

const tradingStates = {
	title: 'Obchodovanie',
	states: {
		initial: 'Čakáme na zainvestovanie úvodného vkladu',
		deposit: 'Vklad {{ sum }} je pripravený na zainvestovanie {{ scheduled }}.',
		withdrawalPart: 'Výber {{ sum }} je pripravený na spracovanie {{ scheduled }}. Najprv odpredáme ETF, pokiaľ to bude '
			+ 'potrebné na\u00a0výber a\u00a0potom odošleme na Váš účet.\n'
			+ 'Vybranú čiastku obdržíte na svoj bankový účet do\u00a0týždňa.\n'
			+ 'Výber môžete zrušiť <0>nižšie na tejto stránke</0>.',
		withdrawalAll: 'Príkaz na výber všetkých prostriedkov je pripravený na\u00a0spracovanie {{ scheduled }}. Najprv '
			+ 'odpredáme ETF a\u00a0potom odošleme na Váš účet.\n'
			+ 'Vybranú čiastku obdržíte na svoj bankový účet do\u00a0týždňa.\n'
			+ 'Výber môžete zrušiť <0>nižšie na tejto stránke</0>.',
		withdrawalCancel: 'Príkaz na výber všetkých prostriedkov je pripravený na\u00a0spracovanie {{ scheduled }}. Najprv '
			+ 'odpredáme ETF a\u00a0potom odošleme na Váš účet.\n'
			+ 'Vybranú čiastku obdržíte na svoj bankový účet do\u00a0týždňa. '
			+ 'Následne zrušíme Váš investičný účet.\n'
			+ 'Výber môžete zrušiť <0>nižšie na tejto stránke</0>.',
		withdrawalTransferPart: 'Vy ste začlenili do\u00a0zoznamu tohto portfólia do\u00a0iného portfólia. '
			+ 'Príkaz môže byť vyradený <0>na\u00a0tejto stránke</0>.',
		withdrawalTransferAll: 'Máte zadaný príkaz na\u00a0odpredaj ETF a\u00a0presun hotovosti do\u00a0iného vášho portfólia. '
			+ 'Príkaz je možné zrušiť nižšie <0>na\u00a0tejto stránke</0>.',
		withdrawalPartClosed: 'Prebieha spracovanie výberu {{ sum }}. Najprv odpredáme ETF, pokiaľ to bude potrebné '
			+ 'na\u00a0výber a\u00a0potom odošleme na Váš účet.\n'
			+ 'Vybranú čiastku obdržíte na svoj bankový účet do\u00a0týždňa.',
		withdrawalAllClosed: 'Prebieha výber všetkých prostriedkov. Najprv odpredáme ETF a\u00a0potom odošleme na Váš účet. '
			+ 'Vybranú čiastku obdžíte na svoj bankový účet do\u00a0týždňa.',
		withdrawalCancelClosed: 'Prebieha výber všetkých prostriedkov. Najprv odpredáme ETF a\u00a0potom odošleme na Váš účet.\n'
			+ 'Vybranú čiastku obdržíte na svoj bankový účet do\u00a0týždňa. Potom '
			+ 'zrušíme Váš investičný účet.',
		withdrawalTransferPartClosed: 'Prebieha spracovanie presunu časti portfólia do\u00a0iného vášho portfólia. '
			+ 'Viac informácií nájdete nižšie <0>na\u00a0tejto stránke</0>.',
		withdrawalTransferAllClosed: 'Prebieha odpredaj ETF a\u00a0presun hotovosti do\u00a0iného vášho portfólia. '
			+ 'Viac informácií nájdete nižšie <0>na\u00a0tejto stránke</0>.',
		depositAndWithdrawal: 'Vklady a\u00a0výbery plánované na rovnaký deň budú vybavené zároveň.',
		profileChange: 'Máte zadanú zmenu investičného profilu z\u00a0{{ profile }} na {{ planned }}. '
			+ 'V\u00a0najbližší obchodný deň urobíme vyváženie portfólia, aby zodpovedali novému profilu.',
		rebalancing: 'Budúci rebalansing (úprava rozloženia portfólia, aby zodpovedalo zvolenému investičnému profilu) '
			+ 'prebehne {{ date }}.',
		rebalancingSkip: 'Máte vypnutý rebalansing. To znamená, že vám budeme investovať prijaté vklady '
			+ 'a\u00a0odošleme výbery, ale nebudeme automaticky vyvažovať Vaše portfólio. Rebalansing si znovu môžete '
			+ 'zapnúť <0>nižšie na tejto stránke</0>.',
		cash: 'Máte zadanú zmenu pomeru držanej hotovosti z\u00a0{{ cash }} na {{ planned }}. V\u00a0najbližší '
			+ 'obchodný deň urobíme vyváženie portfólia, aby zodpovedalo novo nastavenému pomeru hotovosti.',
		cashOver: 'Máte upravený pomer držanej hotovosti na {{ cash }}. Pomer môžete opäť zmeniť <0>nižšie na tejto stránke</0>. '
			+ 'Zmena sa prejaví pri ďalšom rebalansingu.',
		allocatedDeposit: 'Obdržali sme váš vklad vo výške {{ sum }}, ktorý čaká na zainvestovanie. Stane sa tak v nasledujúci '
			+ 'obchodný deň, ktorý je {{ date }}',
	},
};

const transparentAccount = {
	pretitle: 'Hodnota skutočného portfólia Evy a\u00a0Jána',
	netGrowth: 'Pohyb hodnoty',
	netGrowthRatio: 'Pohyb hodnoty v\u00a0%',
	type: 'Typ investora',
	initialDeposit: 'Počiatočný vklad',
	monthlyDeposit: 'Mesačný vklad',
	note: 'Vklady sú posielané v\u00a0Kč, a\u00a0preto sa skutočné hodnoty v\u00a0EUR môžu drobne líšiť kvôli menovým '
		+ 'kurzom.',
};

const unsubscribe = {
	default: {
		title: 'Odhlásiť z\u00a0marketingových mailov',
		text: 'Naozaj sa chcete odhlásiť z\u00a0marketingových mailov? Znovu sa prihlásiť na odber '
			+ 'môžete v\u00a0<0>klientskej zóne</0>.',
		button: 'Odhlásiť',
	},
	success: {
		title: 'Odhlásené',
		text: 'Ak sa rozhodnete znovu odoberať marketingové maily, môžete si ich aktivovať '
			+ 'v\u00a0klientskej zóne v\u00a0časti <0>Môj\u00a0účet</0>.',
		button: 'Prihlásiť sa',
	},
};

export {
	account,
	ageCheckBlock,
	allPortfoliosCheck,
	anchors,
	appBanner,
	blog,
	blogPost,
	campaign,
	childAgeCheck,
	clientCheck,
	clientHeader,
	contact,
	contactForm,
	contactUs,
	cookieConsent,
	cookies,
	countryCheck,
	dashboard,
	documents,
	documentUrls,
	email,
	emailUs,
	emailVerification,
	error,
	error404,
	faq,
	feesChart,
	feesComparison,
	feesHero,
	feesProducts,
	findOutInfoBox,
	footer,
	footerCta,
	forgotPassword,
	forms,
	general,
	header,
	historyChart,
	historyFilter,
	historyOverview,
	historyProgress,
	historyTransactions,
	homepageAbout,
	homepageHero,
	homepageJT,
	homepageProducts,
	homepageServices,
	howItWorksHero,
	howItWorksInvestment,
	howItWorksPrinciples,
	howItWorksRebalancing,
	howItWorksSchema,
	investorProfileAvatar,
	investorProfileChoice,
	investorProfileDetail,
	investorProfilePreview,
	languageSwitcher,
	login,
	media,
	meta,
	offers,
	onboarding,
	ourApproachAccount,
	ourApproachHero,
	ourApproachTeam,
	portfolio,
	portfolioComposition,
	portfolioFeesEtfs,
	portfolioFeesOverview,
	portfolioTable,
	privacyPolicy,
	products,
	productAccount,
	productSelect,
	profileAvatar,
	referrals,
	register,
	resetPassword,
	scrollToTop,
	signAgreement,
	signNewAgreement,
	studentConfirmation,
	survey,
	taxes,
	taxQuestionnaire,
	topUp,
	tradingStates,
	transparentAccount,
	unsubscribe,
};
